import AppSidebar from './AppSidebar';
import { Divider } from './common/Catalyst/divider';
import { Heading, Subheading } from './common/Catalyst/heading';
import { Navbar } from './common/Catalyst/navbar';
import { SidebarLayout } from './common/Catalyst/sidebar-layout';
import HoaListComponent from './hoa/HoaListComponent';
import HOAList from './HOAList';

interface HOAPageProps {
  currentPage: string;
}

const HOAPage: React.FC<HOAPageProps> = ({ currentPage }) => {
  return (
    <SidebarLayout
      sidebar={<AppSidebar currentPage={currentPage} />}
      navbar={<Navbar>{/* Your navbar content */}</Navbar>}
    >
      {/* Your page content */}
      <Heading>Communities Under Management</Heading>
      <Subheading>More to come soon!</Subheading>

      <Divider className="mt-4 mb-6"></Divider>

      <HoaListComponent></HoaListComponent>
    </SidebarLayout>
  );
};

export default HOAPage;
