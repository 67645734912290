import React, { useState, useEffect, useMemo } from 'react';
import { hoaService, homeService } from '../clients/ApiClients';
import {
  HoaSchema,
  HomeCreateOrUpdateSchema,
  HomeSchema,
} from '../clients/hoa-api-client';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { Heading } from '../common/Catalyst/heading';
import { Button } from '../common/Catalyst/button';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import SearchBar from '../common/Custom/SearchBar';
import { Divider } from '../common/Catalyst/divider';
import HomeTable from './HomeTable';
import ShowAlert from '../ShowAlert';
import { UserType } from '../constants/UserTypes';
import { getUserTypeFromToken } from '../utils/TokenUtils';
import AddHomeDialog from './AddHomeDialog';
import AddHomeToMemberDialog from './AddHomeToMemberDialog';
import { MemberSchema } from '../clients/auth-api-client';

interface HomeListComponentProps {
  member?: MemberSchema | null;
  hoaId?: number | null;
}

interface AlertState {
  type: 'success' | 'error';
  title: string;
  message: string;
  show: boolean;
}

const HomeListComponent: React.FC<HomeListComponentProps> = ({
  member,
  hoaId,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [homes, setHomes] = useState<HomeSchema[]>([]);
  const [hoas, setHoas] = useState<HoaSchema[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAddToMemberDialogOpen, setIsAddToMemberDialogOpen] = useState(false);
  const [selectedHomes, setSelectedHomes] = useState<Set<number>>(new Set());
  const [alert, setAlert] = useState<AlertState>({
    type: 'success',
    title: '',
    message: '',
    show: false,
  });

  const userType = useMemo(() => getUserTypeFromToken(), []);

  const hideAlert = () => {
    setAlert((prev) => ({ ...prev, show: false }));
  };

  const showAlert = (
    type: 'success' | 'error',
    title: string,
    message: string
  ) => {
    setAlert({
      type,
      title,
      message,
      show: true,
    });
  };

  useEffect(() => {
    const fetchHomes = async () => {
      try {
        if (hoaId) {
          setLoading(true);
          const response = await homeService.getHomesByHoa({ hoaId });
          setHomes(response.data);
        } else if (member) {
          setLoading(true);
          const response = await homeService.getHomesByMember({
            memberId: member.id,
          });
          setHomes(response.data);
          const hoaResponse = await hoaService.getAllActiveHoas();
          setHoas(hoaResponse.data);
        } else {
          throw new Error('HOA id or Member id is required to load home list.');
        }
      } catch (err) {
        console.error('Error fetching homes:', err);
        const errorMessage =
          err instanceof Error
            ? err.message
            : 'An unexpected error occurred while fetching homes.';
        showAlert('error', 'Failed to Load Homes', errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchHomes();
  }, [hoaId, member]);

  const filteredHomes = useMemo(() => {
    if (!searchTerm) return homes;

    const searchLower = searchTerm.toLowerCase();
    return homes.filter((home) => {
      const addressString = [
        home.street,
        home.street2,
        home.city,
        home.state,
        home.zip,
      ]
        .filter(Boolean)
        .join(' ')
        .toLowerCase();

      return addressString.includes(searchLower);
    });
  }, [homes, searchTerm]);

  const handleRowClick = (home: HomeSchema) => {
    navigate(`/hoa/${home.hoa_id}/home/${home.id}`);
  };

  const handleSaveHome = async (newHome: HomeCreateOrUpdateSchema) => {
    try {
      const response = await homeService.createHome({
        homeCreateOrUpdateSchema: { ...newHome, hoa_id: hoaId ?? 0 },
      });
      setHomes((prevHomes) => [...prevHomes, response.data]);
      showAlert('success', 'Success', 'Home created successfully!');
      setIsDialogOpen(false);
    } catch (err) {
      console.error('Error creating home:', err);
      const errorMessage =
        err instanceof Error
          ? err.message
          : 'An unexpected error occurred. Please try again.';
      showAlert('error', 'Failed to Create Home', errorMessage);
    }
  };

  const handleAddHomeToMember = async (data: {
    homeId: number;
    memberId: number;
  }) => {
    try {
      await homeService.addMemberToHome({
        homeId: data.homeId,
        memberId: data.memberId,
      });
      showAlert('success', 'Success', 'Home added to member successfully!');
      if (member) {
        const response = await homeService.getHomesByMember({
          memberId: member.id,
        });
        setHomes(response.data);
      }
    } catch (err) {
      console.error('Error adding home to member:', err);
      const errorMessage =
        err instanceof Error
          ? err.message
          : 'An unexpected error occurred. Please try again.';
      showAlert('error', 'Failed to Add Home to Member', errorMessage);
    }
  };

  const handleRemoveHomes = async () => {
    if (selectedHomes.size === 0 || !member) return;

    try {
      const removalPromises = Array.from(selectedHomes).map((homeId) =>
        homeService.removeMemberFromHome({
          homeId: homeId,
          memberId: member.id,
        })
      );

      await Promise.all(removalPromises);
      const response = await homeService.getHomesByMember({
        memberId: member.id,
      });
      setHomes(response.data);
      setSelectedHomes(new Set());
      showAlert(
        'success',
        'Success',
        'Homes removed from member successfully!'
      );
    } catch (err) {
      console.error('Error removing homes from member:', err);
      const errorMessage =
        err instanceof Error
          ? err.message
          : 'An unexpected error occurred. Please try again.';
      showAlert('error', 'Failed to Remove Homes', errorMessage);
    }
  };

  if (loading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <>
      <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 mb-4">
        <Heading level={4}>
          {member ? `${member.first_name}'s Homes` : 'Home List'}
        </Heading>
        <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
          {userType === UserType.HOA_MANAGER_ADMIN && (
            <>
              {hoaId && (
                <Button color="sky" onClick={() => setIsDialogOpen(true)}>
                  <PlusIcon className="h-5 w-5 mr-2" />
                  Add Home
                </Button>
              )}
              {member && (
                <>
                  <Button
                    color="sky"
                    onClick={() => setIsAddToMemberDialogOpen(true)}
                  >
                    <PlusIcon className="h-5 w-5 mr-2" />
                    Add Home to Member
                  </Button>
                  <Button
                    disabled={selectedHomes.size < 1}
                    onClick={handleRemoveHomes}
                    color="red"
                  >
                    <TrashIcon className="h-5 w-5 mr-2" />
                    Remove Selected
                  </Button>
                </>
              )}
            </>
          )}
          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search Homes..."
          />
        </div>
      </div>

      <ShowAlert
        alertType={alert.type}
        alertTitle={alert.title}
        alertMessage={alert.message}
        isVisible={alert.show}
        onClose={hideAlert}
        timeout={5000}
        isInline={true}
      />

      {filteredHomes.length === 0 ? (
        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
          {searchTerm ? 'No Homes match your search' : 'No Homes found'}
          <Divider className="mt-4 mb-6"></Divider>
        </div>
      ) : (
        <HomeTable
          homes={filteredHomes}
          onRowClick={handleRowClick}
          selectionEnabled={!!member}
          selectedHomes={selectedHomes}
          onSelectionChange={setSelectedHomes}
        />
      )}

      {hoaId && (
        <AddHomeDialog
          isOpen={isDialogOpen}
          hoaId={hoaId ?? 0}
          hoas={hoas}
          onSave={handleSaveHome}
          onClose={() => setIsDialogOpen(false)}
        ></AddHomeDialog>
      )}

      {member && (
        <AddHomeToMemberDialog
          isOpen={isAddToMemberDialogOpen}
          member={member}
          onSave={handleAddHomeToMember}
          onClose={() => setIsAddToMemberDialogOpen(false)}
        />
      )}
    </>
  );
};

export default HomeListComponent;
