import { MemberSchema } from '../clients/auth-api-client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../common/Catalyst/table';
import { formatPhoneNumber } from '../utils/phoneUtils';
import { Checkbox } from '../common/Catalyst/checkbox';

interface MemberTableProps {
  members: MemberSchema[];
  onRowClick: (member: MemberSchema) => void;
  selectionEnabled?: boolean;
  selectedMembers?: Set<number>;
  onSelectionChange?: (selectedIds: Set<number>) => void;
}

export const MemberTable: React.FC<MemberTableProps> = ({
  members,
  onRowClick,
  selectionEnabled = false,
  selectedMembers = new Set(),
  onSelectionChange,
}) => {
  const handleCheckboxClick = (checked: boolean, memberId: number) => {
    if (!onSelectionChange) return;

    const newSelected = new Set(selectedMembers);
    if (!checked) {
      newSelected.delete(memberId);
    } else {
      newSelected.add(memberId);
    }
    onSelectionChange(newSelected);
  };

  const handleSelectAll = (checked: boolean) => {
    if (!onSelectionChange) return;

    if (checked) {
      const allIds = new Set(members.map((member) => member.id));
      onSelectionChange(allIds);
    } else {
      onSelectionChange(new Set());
    }
  };

  const allSelected =
    members.length > 0 &&
    members.every((member) => selectedMembers.has(member.id));
  const someSelected = members.some((member) => selectedMembers.has(member.id));

  return (
    <div className="bg-stone-100 dark:bg-gray-600 rounded-lg shadow transition-colors duration-200 overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div className="max-h-[500px] overflow-y-auto relative">
                <Table>
                  <TableHead className="sticky top-0 z-10 bg-stone-100 dark:bg-gray-600">
                    <TableRow>
                      {selectionEnabled && (
                        <TableHeader className="w-12">
                          <Checkbox
                            checked={allSelected}
                            indeterminate={!allSelected && someSelected}
                            onChange={handleSelectAll}
                            color="sky"
                          />
                        </TableHeader>
                      )}
                      <TableHeader className="text-base font-semibold">
                        Name
                      </TableHeader>
                      <TableHeader className="text-base font-semibold">
                        Email
                      </TableHeader>
                      <TableHeader className="text-base font-semibold">
                        Phone
                      </TableHeader>
                      <TableHeader className="text-base font-semibold">
                        Status
                      </TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {members.map((member) => (
                      <TableRow
                        key={member.id}
                        onClick={() => onRowClick(member)}
                        className="cursor-pointer hover:bg-stone-200 dark:hover:bg-zinc-700 transition-colors duration-150"
                      >
                        {selectionEnabled && (
                          <TableCell
                            className="w-12"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              checked={selectedMembers.has(member.id)}
                              onChange={(checked) =>
                                handleCheckboxClick(checked, member.id)
                              }
                              color="sky"
                            />
                          </TableCell>
                        )}
                        <TableCell>
                          {`${member.first_name} ${member.middle_name ? member.middle_name + ' ' : ''}${member.last_name}`}
                          {member.preferred_name &&
                            ` (${member.preferred_name})`}
                        </TableCell>
                        <TableCell>{member.email}</TableCell>
                        <TableCell>
                          {member.phone ? formatPhoneNumber(member.phone) : ''}
                        </TableCell>
                        <TableCell>
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              member.is_active
                                ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                                : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
                            }`}
                          >
                            {member.is_active ? 'Active' : 'Inactive'}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberTable;
