import React, { useState, useEffect } from 'react';
import { Heading } from './common/Catalyst/heading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './common/Catalyst/table';
import { Input, InputGroup } from './common/Catalyst/input';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ViolationCreate, ViolationRead } from './clients/vio-api-client';
import { violationService } from './clients/ApiClients';
import { Button } from './common/Catalyst/button';
import { Dialog, DialogBody, DialogTitle } from './common/Catalyst/dialog';
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from './common/Catalyst/fieldset';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from './common/Catalyst/dropdown';
import { Divider } from './common/Catalyst/divider';
import { Textarea } from './common/Catalyst/textarea';
import ShowAlert from './ShowAlert';
import LoadingSpinner from './LoadingSpinner';

interface FormErrors {
  [key: string]: string | undefined;
}

interface TouchedFields {
  [key: string]: boolean;
}

interface ViolationListProps {
  hoaId?: number | null;
  homeId?: number | null;
  memberId?: number | null;
  title?: string | null;
}

const initialViolationState: ViolationCreate = {
  home_id: 0,
  violation_type: 'Select Type',
  severity: 'Select Severity',
  description: '',
  message: '',
  date_of_violation: new Date().toISOString().split('T')[0],
  status: 'Pending',
  due_date: null,
  document_url: null,
  image_url: null,
  fine_amount: null,
};

const ViolationList: React.FC<ViolationListProps> = ({
  hoaId,
  homeId,
  memberId,
  title,
}) => {
  const navigate = useNavigate();
  const [violations, setViolations] = useState<ViolationRead[]>([]);
  const [filteredViolations, setFilteredViolations] = useState<ViolationRead[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [showCreateViolationError, setShowCreateViolationError] =
    useState(false);
  const [error, setError] = useState<string | null>(null);
  const [errorTitle, setErrorTitle] = useState<string | null>(null);
  const [fetchErrorTitle, setFetchErrorTitle] = useState<string>('');
  const [fetchError, setFetchError] = useState<string>('');
  const [showFetchError, setShowFetchError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [newViolation, setNewViolation] = useState<ViolationCreate>(
    initialViolationState
  );

  const severityOptions = ['Minor', 'Moderate', 'Major', 'Critical'];
  const typeOptions = [
    'Maintenance',
    'Noise',
    'Architectural',
    'Parking',
    'Landscaping',
    'Pets',
    'Trash',
    'Other',
  ];

  useEffect(() => {
    const fetchViolations = async () => {
      try {
        setLoading(true);
        let response;

        if (homeId && hoaId) {
          // Fetch violations for specific home
          response = await violationService.getViolationsByHome({
            hoaId: Number(hoaId),
            homeId: Number(homeId),
          });
          setViolations(response.data);
          setFilteredViolations(response.data);
        } else if (hoaId) {
          // Fetch all violations for HOA
          response = await violationService.getViolationsByHoa({
            hoaId: Number(hoaId),
          });
          setViolations(response.data.violations);
          setFilteredViolations(response.data.violations);
        } else if (memberId) {
          response = await violationService.getAllViolationsForMember({
            memberId: Number(memberId),
          });
          setViolations(response.data.violations);
          setFilteredViolations(response.data.violations);
        } else {
          response = await violationService.getAllViolations();
          setViolations(response.data.violations);
          setFilteredViolations(response.data.violations);
        }
        setError(null);
      } catch (err) {
        console.error('Error fetching violations:', err);

        let errorMessage =
          'An unexpected error occurred while fetching violations.';
        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof err === 'string') {
          errorMessage = err;
        }

        setFetchErrorTitle('Failed to Load Violations');
        setFetchError(errorMessage);
        setShowFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchViolations();
  }, [hoaId, homeId, memberId]);

  useEffect(() => {
    const filtered = violations.filter(
      (violation) =>
        violation.description
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        violation.violation_type
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredViolations(filtered);
  }, [searchTerm, violations]);

  const handleCreateViolation = () => {
    if (hoaId && homeId) {
      setNewViolation({ ...initialViolationState, home_id: homeId });
      setIsDialogOpen(true);
    }
  };

  const resetForm = () => {
    setNewViolation({ ...initialViolationState, home_id: homeId || 0 });
    setFormErrors({});
    setTouchedFields({});
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    resetForm();
  };

  const isValidUrl = (string: string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const validateField = (
    name: keyof ViolationCreate,
    value: any
  ): string | undefined => {
    switch (name) {
      case 'violation_type':
        return !typeOptions.includes(value)
          ? 'Please select a valid violation type'
          : '';
      case 'severity':
        return !severityOptions.includes(value)
          ? 'Please select a valid severity level'
          : '';
      case 'description':
        return !value
          ? 'Description is required'
          : value.length > 500
            ? 'Description must be less than 500 characters'
            : '';
      case 'message':
        return !value ? 'Message is required' : '';
      case 'fine_amount':
        if (!value) return '';
        if (isNaN(value) || Number(value) < 0)
          return 'Fine amount must be a positive number';
        if (value > 10000) return 'Fine amount cannot exceed $10,000.00';
        return '';
      case 'date_of_violation':
        return !value
          ? 'Date of violation is required'
          : !isValidDate(value)
            ? 'Please enter a valid date'
            : '';
      case 'due_date':
        return !isValidDate(value) ? 'Please enter a valid date' : '';
      case 'image_url':
      case 'document_url':
        if (!value) return '';
        return !isValidUrl(value)
          ? 'Please enter a valid URL (e.g., https://example.com)'
          : '';
      default:
        return '';
    }
  };

  const handleBlur = (
    e: React.FocusEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof ViolationCreate, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | { target: { name: string; value: string } }
  ) => {
    const { name, value } = e.target;

    if (name === 'fine_amount') {
      // Remove any non-digit characters except decimal point
      const numericValue = value.replace(/[^\d.]/g, '');
      // Ensure only valid decimal numbers
      if (numericValue === '' || /^\d*\.?\d{0,2}$/.test(numericValue)) {
        setNewViolation((prev) => ({
          ...prev,
          [name]: numericValue === '' ? null : parseFloat(numericValue),
        }));
      }
    } else {
      setNewViolation((prev) => ({ ...prev, [name]: value }));
    }

    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof ViolationCreate, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    (Object.keys(newViolation) as Array<keyof ViolationCreate>).forEach(
      (key) => {
        const error = validateField(key, newViolation[key]);
        if (error) {
          errors[key] = error;
        }
      }
    );
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSaveViolation = async () => {
    if (validateForm() && hoaId) {
      try {
        const response = await violationService.createViolation({
          hoaId,
          violationCreate: newViolation,
        });
        setViolations((prevViolations) => [...prevViolations, response.data]);
        handleDialogClose();
      } catch (err) {
        console.error('Error creating violation:', err);

        let errorMessage = 'An unexpected error occurred. Please try again.';

        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof error === 'string') {
          errorMessage = error;
        }

        setErrorTitle('Failed to create violation. Please review your data.');
        setError(errorMessage);
        setShowCreateViolationError(true);
      }
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (violation: ViolationRead) => {
    navigate(`/violations/${violation.id}`);
  };

  const isFormValid = () => {
    // Check for any existing errors
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    // Check required fields
    const requiredFields = [
      'violation_type',
      'severity',
      'description',
      'message',
      'date_of_violation',
    ];

    return requiredFields.every((field) => {
      const value = newViolation[field as keyof ViolationCreate];
      // Check if the field has a value and it's not the default placeholder
      if (!value) return false;
      if (field === 'violation_type' && value === 'Select Type') return false;
      if (field === 'severity' && value === 'Select Severity') return false;
      if (field === 'date_of_violation') {
        return typeof value === 'string' && isValidDate(value);
      }
      return true;
    });
  };

  const handleFileUpload = (type: 'image' | 'document') => {
    // For now, just set default URLs
    const newValue =
      type === 'image' ? 'https://imageurl.com' : 'https://documenturl.com';
    handleInputChange({
      target: {
        name: type === 'image' ? 'image_url' : 'document_url',
        value: newValue,
      },
    } as any);
  };

  const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString();
  };

  const isValidDate = (dateString: string | null | undefined): boolean => {
    if (!dateString) return true; // null/undefined is OK for optional dates
    // Check if it's a complete date string (YYYY-MM-DD)
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateString)) return false;

    // Check if it's a valid date (not like 2024-13-45)
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const getSeverityColor = (severity: string) => {
    switch (severity.toLowerCase()) {
      case 'high':
        return 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100';
      case 'low':
        return 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100';
      default:
        return 'bg-zinc-100 text-zinc-800 dark:bg-zinc-800 dark:text-zinc-100';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'open':
        return 'bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100';
      case 'resolved':
        return 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100';
      case 'in progress':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100';
      default:
        return 'bg-zinc-100 text-zinc-800 dark:bg-zinc-800 dark:text-zinc-100';
    }
  };

  if (loading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <>
      <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 mb-4">
        <Heading level={4}>
          {title || (homeId ? 'Home Violations' : 'Violations List')}
        </Heading>
        <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
          {hoaId && homeId && (
            <Button color="sky" onClick={handleCreateViolation}>
              <PlusIcon className="h-5 w-5 mr-2" />
              New Violation
            </Button>
          )}
          <div className="w-full sm:w-64 bg-stone-300 dark:bg-gray-800">
            <InputGroup className="[&>[data-slot=icon]]:text-zinc-500 dark:[&>[data-slot=icon]]:text-white">
              <MagnifyingGlassIcon data-slot="icon" />
              <Input
                type="search"
                placeholder="Search violations..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <ShowAlert
        alertType="error"
        alertTitle={fetchErrorTitle}
        alertMessage={fetchError}
        isVisible={showFetchError}
        onClose={() => setShowFetchError(false)}
        timeout={5000}
        isInline={true}
      />
      <div className="bg-stone-100 dark:bg-gray-600 rounded-lg shadow transition-colors duration-200 overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <div className="max-h-[500px] overflow-y-auto">
                  <Table>
                    <TableHead className="sticky top-0 z-10 bg-stone-100 dark:bg-gray-600">
                      <TableRow>
                        <TableHeader className="text-base font-semibold">
                          Type
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Description
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Severity
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Date
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Due Date
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Status
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Fine Amount
                        </TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredViolations.map((violation) => (
                        <TableRow
                          key={violation.id}
                          onClick={() => handleRowClick(violation)}
                          className="cursor-pointer hover:bg-stone-200 dark:hover:bg-zinc-700 transition-colors duration-150"
                        >
                          <TableCell>{violation.violation_type}</TableCell>
                          <TableCell>{violation.description}</TableCell>
                          <TableCell>
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getSeverityColor(
                                violation.severity
                              )}`}
                            >
                              {violation.severity}
                            </span>
                          </TableCell>
                          <TableCell>
                            {formatDate(violation.date_of_violation)}
                          </TableCell>
                          <TableCell>
                            {formatDate(violation.due_date)}
                          </TableCell>
                          <TableCell>
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(
                                violation.status
                              )}`}
                            >
                              {violation.status}
                            </span>
                          </TableCell>
                          <TableCell>
                            {violation.fine_amount
                              ? formatter.format(violation.fine_amount)
                              : '-'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        className="w-full max-w-2xl mx-auto"
      >
        <div className="relative flex flex-col h-full">
          <div className="p-4 md:p-6">
            <DialogTitle className="text-xl font-semibold">
              New Violation
            </DialogTitle>
            <button
              onClick={handleDialogClose}
              className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
              aria-label="Close dialog"
            >
              <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
            </button>

            <Divider className="my-4" />

            <div className="mb-4">
              <ShowAlert
                alertType="error"
                alertTitle={errorTitle ?? 'Something went wrong'}
                alertMessage={error ?? 'Please try again'}
                isVisible={showCreateViolationError}
                onClose={() => setShowCreateViolationError(false)}
                timeout={5000}
                isInline={true}
              />
            </div>

            <DialogBody>
              <Fieldset>
                <FieldGroup>
                  {/* Violation Type and Severity */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                    <Field>
                      <Label htmlFor="violation_type">Violation Type*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {newViolation?.violation_type || 'Select Type'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full">
                          {typeOptions.map((type) => (
                            <DropdownItem
                              key={type}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'violation_type',
                                    value: type,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {type}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.violation_type &&
                        formErrors.violation_type && (
                          <ErrorMessage>
                            {formErrors.violation_type}
                          </ErrorMessage>
                        )}
                    </Field>

                    <Field>
                      <Label htmlFor="severity">Severity*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {newViolation?.severity || 'Select Severity'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full">
                          {severityOptions.map((severity) => (
                            <DropdownItem
                              key={severity}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'severity',
                                    value: severity,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {severity}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.severity && formErrors.severity && (
                        <ErrorMessage>{formErrors.severity}</ErrorMessage>
                      )}
                    </Field>
                  </div>

                  {/* Fine Amount and Due Date */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Field>
                      <Label htmlFor="fine_amount">Fine Amount</Label>
                      <Input
                        id="fine_amount"
                        name="fine_amount"
                        type="number"
                        min="0"
                        step="0.01"
                        value={newViolation.fine_amount || ''}
                        onChange={handleInputChange}
                        placeholder="Enter fine amount (optional)"
                        className="w-full"
                      />
                      {touchedFields.fine_amount && formErrors.fine_amount && (
                        <ErrorMessage>{formErrors.fine_amount}</ErrorMessage>
                      )}
                    </Field>

                    <Field>
                      <Label htmlFor="due_date">Due Date</Label>
                      <Input
                        id="due_date"
                        name="due_date"
                        type="date"
                        value={newViolation.due_date?.split('T')[0] || ''}
                        onChange={handleInputChange}
                        className="w-full"
                      />
                    </Field>
                  </div>

                  {/* Description and Date of Violation */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Field>
                      <Label htmlFor="description">Description*</Label>
                      <Textarea
                        id="description"
                        name="description"
                        value={newViolation.description}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="300 character limit"
                        required
                        className="w-full"
                        data-invalid={
                          touchedFields.description && formErrors.description
                        }
                        rows={2}
                        maxLength={300}
                      />
                      {touchedFields.description && formErrors.description && (
                        <ErrorMessage>{formErrors.description}</ErrorMessage>
                      )}
                    </Field>

                    <Field>
                      <Label htmlFor="date_of_violation">
                        Date of Violation*
                      </Label>
                      <Input
                        id="date_of_violation"
                        name="date_of_violation"
                        type="date"
                        value={newViolation.date_of_violation}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                        className="w-full"
                        data-invalid={
                          touchedFields.date_of_violation &&
                          formErrors.date_of_violation
                        }
                      />
                      {touchedFields.date_of_violation &&
                        formErrors.date_of_violation && (
                          <ErrorMessage>
                            {formErrors.date_of_violation}
                          </ErrorMessage>
                        )}
                    </Field>
                  </div>

                  {/* Detailed Message */}
                  <Field>
                    <Label htmlFor="message">Detailed Message*</Label>
                    <Textarea
                      id="message"
                      name="message"
                      value={newViolation.message}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Detailed description of the violation"
                      required
                      className="w-full"
                      data-invalid={touchedFields.message && formErrors.message}
                      rows={4}
                    />
                    {touchedFields.message && formErrors.message && (
                      <ErrorMessage>{formErrors.message}</ErrorMessage>
                    )}
                  </Field>
                </FieldGroup>
              </Fieldset>
            </DialogBody>
          </div>

          <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
            <div className="flex flex-col space-y-3">
              <Button
                onClick={handleSaveViolation}
                color="sky"
                disabled={!isFormValid()}
                className="w-full disabled:opacity-50"
              >
                Save
              </Button>
              <Button
                onClick={handleDialogClose}
                color="zinc"
                className="w-full"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ViolationList;
