import React, { useEffect, useState } from 'react';
import {
  Sidebar,
  SidebarSection,
  SidebarItem,
  SidebarHeader,
  SidebarBody,
  SidebarFooter,
  SidebarSpacer,
} from './common/Catalyst/sidebar';
import {
  HomeIcon,
  ChartBarIcon,
  HomeModernIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  ArrowRightStartOnRectangleIcon,
} from '@heroicons/react/24/outline';
import logo from './assets/logo.png';
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from './common/Catalyst/dropdown';
import { Avatar } from './common/Catalyst/avatar';
import {
  clearAllTokens,
  clearRememberMe,
  clearSessionStorage,
  clearUserName,
  generateInitials,
  getUserEmailFromToken,
  getUserNameFromToken,
} from './utils/TokenUtils';
import { useNavigate } from 'react-router-dom';
import { DarkModeToggle } from './common/Custom/DarkModeToggle';

interface AppsidebarProps {
  currentPage: string;
}

const AppSidebar: React.FC<AppsidebarProps> = ({ currentPage }) => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [userInitials, setUserInitials] = useState<string>('');

  useEffect(() => {
    const email = getUserEmailFromToken();
    const name = getUserNameFromToken();
    setUserEmail(email);
    setUserName(name);
    if (name) {
      setUserInitials(generateInitials(name));
    }
  }, []);

  const handleLogout = () => {
    clearAllTokens();
    clearUserName();
    clearRememberMe();
    clearSessionStorage();
    navigate('/login');
  };

  return (
    <Sidebar className="bg-sky-800 dark:bg-gray-900 [&_*]:text-white [&_svg]:text-white">
      <SidebarHeader className="border-white/10">
        <div className="flex items-center space-x-3 mb-4">
          <img src={logo} alt="Dashboard Logo" className="h-8 w-auto" />
          <h2 className="text-xl font-bold text-white dark:text-white">
            Levittown
          </h2>
        </div>
      </SidebarHeader>
      <SidebarBody>
        <SidebarSection>
          <SidebarItem
            href="/home"
            current={currentPage === 'home'}
            className="!text-white hover:!bg-white/10 active:!bg-white/20 [&_svg]:!fill-white"
          >
            <HomeIcon className="h-5 w-5" />
            <span>Home</span>
          </SidebarItem>
          <SidebarItem
            href="/hoas"
            current={currentPage === 'hoas'}
            className="!text-white hover:!bg-white/10 active:!bg-white/20 [&_svg]:!fill-white"
          >
            <HomeModernIcon className="h-5 w-5" />
            <span>HOAs</span>
          </SidebarItem>
          <SidebarItem
            href="/violations"
            current={currentPage === 'violations'}
            className="!text-white hover:!bg-white/10 active:!bg-white/20 [&_svg]:!fill-white"
          >
            <ChartBarIcon className="h-5 w-5" />
            <span>Violations</span>
          </SidebarItem>
          <SidebarItem
            href="/settings"
            current={currentPage === 'settings'}
            className="!text-white hover:!bg-white/10 active:!bg-white/20 [&_svg]:!fill-white"
          >
            <Cog6ToothIcon className="h-5 w-5" />
            <span>Settings</span>
          </SidebarItem>
        </SidebarSection>

        <SidebarSpacer />

        <SidebarSection className="border-t border-white/10 pt-4">
          <div className="px-2 py-2">
            <DarkModeToggle />
          </div>
          <SidebarItem
            onClick={handleLogout}
            className="!text-white hover:!bg-white/10 active:!bg-white/20 [&_svg]:!fill-white"
          >
            <ArrowRightStartOnRectangleIcon className="h-5 w-5" />
            <span>Sign out</span>
          </SidebarItem>
        </SidebarSection>
      </SidebarBody>
      <SidebarFooter className="max-lg:hidden border-white/10">
        <Dropdown>
          <DropdownButton
            as={SidebarItem}
            className="!text-white hover:!bg-white/10 [&_svg]:!fill-white"
          >
            <span className="flex min-w-0 items-center gap-3">
              <Avatar
                // TODO: add profile pic
                // src="/profile-photo.jpg"
                initials={userInitials}
                className="size-10"
                square
                alt=""
              />
              <span className="min-w-0">
                <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                  {userName || 'User'}
                </span>
                <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                  {userEmail || 'No email provided'}
                </span>
              </span>
            </span>
            <ChevronUpIcon className="h-5 w-5" />
          </DropdownButton>
          <DropdownMenu
            className="min-w-64 !bg-sky-800 dark:!bg-gray-900 [&_*]:!text-white"
            anchor="top start"
          >
            {/* <DropdownItem
              href="/my-profile"
              className="data-[focus]:bg-sky-600/70 data-[focus]:text-white"
            >
              <UserIcon className="h-5 w-5" />
              <DropdownLabel>My profile</DropdownLabel>
            </DropdownItem> */}
            <DropdownItem
              href="/settings"
              className="data-[focus]:bg-sky-600/70 data-[focus]:text-white"
            >
              <Cog6ToothIcon className="h-5 w-5" />
              <DropdownLabel>Settings</DropdownLabel>
            </DropdownItem>
            <DropdownDivider />
            {/* <DropdownItem href="/privacy-policy" className="data-[focus]:bg-sky-600/70 data-[focus]:text-white">
              <ShieldCheckIcon className="h-5 w-5" />
              <DropdownLabel>Privacy policy</DropdownLabel>
            </DropdownItem>
            <DropdownItem href="/share-feedback" className="data-[focus]:bg-sky-600/70 data-[focus]:text-white">
              <LightBulbIcon className="h-5 w-5" />
              <DropdownLabel>Share feedback</DropdownLabel>
            </DropdownItem> */}
            <DropdownDivider />
            <DropdownItem
              href="/logout"
              onClick={handleLogout}
              className="data-[focus]:bg-sky-600/70 data-[focus]:text-white"
            >
              <ArrowRightStartOnRectangleIcon className="h-5 w-5" />
              <DropdownLabel>Sign out</DropdownLabel>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </SidebarFooter>
    </Sidebar>
  );
};

export default AppSidebar;
