import React, { useMemo, useState } from 'react';
import { XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Dialog, DialogBody, DialogTitle } from '../common/Catalyst/dialog';
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from '../common/Catalyst/fieldset';
import { Input } from '../common/Catalyst/input';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from '../common/Catalyst/dropdown';
import usStates from '../utils/addressUtils';
import { Button } from '../common/Catalyst/button';
import { HoaSchema, HomeCreateOrUpdateSchema } from '../clients/hoa-api-client';
import { Divider } from '../common/Catalyst/divider';
import SearchableDropdown from '../common/Custom/SearchableDropdown';

interface HomeDialogProps {
  isOpen: boolean;
  hoaId: number;
  hoas: HoaSchema[];
  onClose: () => void;
  onSave: (hoa: HomeCreateOrUpdateSchema) => Promise<void>;
}

interface FormErrors {
  [key: string]: string | undefined;
}

const getInitialHomeState = (hoaId: number): HomeCreateOrUpdateSchema => ({
  hoa_id: hoaId,
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  country: 'USA',
  property_type: 'Select Type',
  status: 'Select Status',
});

export const AddHomeDialog: React.FC<HomeDialogProps> = ({
  isOpen,
  hoaId,
  hoas,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState<HomeCreateOrUpdateSchema>(() =>
    getInitialHomeState(hoaId)
  );
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>(
    {}
  );
  const [hoaOptions, setHoaOptions] = useState<HoaSchema[]>(hoas);

  const validateField = (
    name: keyof HomeCreateOrUpdateSchema,
    value: HomeCreateOrUpdateSchema[keyof HomeCreateOrUpdateSchema]
  ): string | undefined => {
    let error: string | undefined;
    switch (name) {
      case 'street':
      case 'city':
      case 'state':
      case 'zip':
        error = !value
          ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
          : undefined;
        break;
      case 'property_type':
      case 'status':
        if (!value || value === 'Select Type' || value === 'Select Status') {
          error = `${name.charAt(0).toUpperCase() + name.slice(1).replace('_', ' ')} is required`;
        }
        break;
    }
    return error;
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof HomeCreateOrUpdateSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    (Object.keys(formData) as Array<keyof HomeCreateOrUpdateSchema>).forEach(
      (key) => {
        const error = validateField(key, formData[key]);
        if (error && key !== 'hoa_id') {
          errors[key] = error;
        }
      }
    );
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | { target: { name: string; value: string } }
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof HomeCreateOrUpdateSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const isFormValid = () => {
    // Check for any existing errors
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    // Required fields
    const requiredFields = [
      'owners',
      'street',
      'city',
      'state',
      'zip',
      'property_type',
      'status',
    ];

    return requiredFields.every((field) => {
      const value = formData[field as keyof HomeCreateOrUpdateSchema];
      return value !== undefined && value !== '';
    });
  };

  const isValid = useMemo(() => {
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    const requiredFields = [
      'owners',
      'street',
      'city',
      'state',
      'zip',
      'property_type',
      'status',
    ];

    const newErrors: FormErrors = {};

    const hasAllRequiredFields = requiredFields.every((field) => {
      const value = formData[field as keyof HomeCreateOrUpdateSchema];
      const isNonEmpty = value !== undefined && value !== '' && value !== null;

      if (!isNonEmpty) {
        newErrors[field] =
          `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
        return false;
      }

      const stringValue =
        typeof value === 'number' || typeof value === 'boolean'
          ? value.toString()
          : value || '';

      const error = validateField(
        field as keyof HomeCreateOrUpdateSchema,
        stringValue
      );
      if (error) {
        newErrors[field] = error;
        return false;
      }

      return true;
    });

    return {
      isValid: hasAllRequiredFields && Object.keys(newErrors).length === 0,
    };
  }, [formData]);

  const handleSave = async () => {
    if (isValid) {
      await onSave(formData);
      setFormData(getInitialHomeState(hoaId));
      setFormErrors({});
      setTouchedFields({});
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="w-full max-w-2xl mx-auto"
    >
      <div className="relative flex flex-col h-full">
        <div className="p-4 md:p-6">
          <DialogTitle className="text-xl font-semibold">New Home</DialogTitle>
          <button
            onClick={onClose}
            className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
            aria-label="Close dialog"
          >
            <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
          </button>

          <Divider className="my-4" />

          <DialogBody>
            <Fieldset>
              <FieldGroup>
                {/*HOA Selector */}
                {hoaId === 0 && (
                  <div className="space-y-4">
                    <Field>
                      <SearchableDropdown
                        options={hoaOptions}
                        onSelect={(option) =>
                          console.log('Selected:', option?.id)
                        }
                      ></SearchableDropdown>
                    </Field>
                  </div>
                )}
                {/* Address Fields */}
                <div className="space-y-4">
                  <Field>
                    <Label htmlFor="street">Street*</Label>
                    <Input
                      id="street"
                      name="street"
                      value={formData.street}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter street address"
                      required
                      className="w-full"
                      data-invalid={touchedFields.street && formErrors.street}
                    />
                    {touchedFields.street && formErrors.street && (
                      <ErrorMessage>{formErrors.street}</ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label htmlFor="street2">Street 2</Label>
                    <Input
                      id="street2"
                      name="street2"
                      value={formData.street2 || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter additional street information (optional)"
                      className="w-full"
                    />
                  </Field>
                </div>

                {/* City, State */}
                <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                  <Field className="col-span-1 md:col-span-3">
                    <Label htmlFor="city">City*</Label>
                    <Input
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter city"
                      required
                      className="w-full"
                      data-invalid={touchedFields.city && formErrors.city}
                    />
                    {touchedFields.city && formErrors.city && (
                      <ErrorMessage>{formErrors.city}</ErrorMessage>
                    )}
                  </Field>

                  <Field className="col-span-1 md:col-span-2">
                    <Label htmlFor="state">State*</Label>
                    <div className="mt-3">
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {formData?.state || 'State'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full max-h-60 overflow-y-auto">
                          {usStates.map((state) => (
                            <DropdownItem
                              key={state.label}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'state',
                                    value: state.value,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {state.label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    {touchedFields.state && formErrors.state && (
                      <ErrorMessage>{formErrors.state}</ErrorMessage>
                    )}
                  </Field>
                </div>

                {/* Contact Information */}
                <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                  <Field className="col-span-1 md:col-span-2">
                    <Label htmlFor="zip">ZIP Code*</Label>
                    <Input
                      id="zip"
                      name="zip"
                      value={formData.zip}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter ZIP code"
                      required
                      className="w-full"
                      data-invalid={touchedFields.zip && formErrors.zip}
                    />
                    {touchedFields.zip && formErrors.zip && (
                      <ErrorMessage>{formErrors.zip}</ErrorMessage>
                    )}
                  </Field>
                </div>

                {/* Property Type and Status */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <Field>
                    <Label htmlFor="property_type">Property Type*</Label>
                    <Dropdown>
                      <div className="w-full">
                        <DropdownButton
                          outline
                          className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                        >
                          {formData.property_type || 'Select Property Type'}
                          <ChevronDownIcon className="h-5 w-5 ml-2" />
                        </DropdownButton>
                      </div>
                      <DropdownMenu className="w-full">
                        {[
                          'Single Family',
                          'Townhouse',
                          'Condo',
                          'Apartment',
                          'Other',
                        ].map((type) => (
                          <DropdownItem
                            key={type}
                            onClick={() =>
                              handleInputChange({
                                target: {
                                  name: 'property_type',
                                  value: type,
                                  type: 'change',
                                  checked: false,
                                  nodeName: 'INPUT',
                                } as unknown as EventTarget & HTMLInputElement,
                              } as React.ChangeEvent<HTMLInputElement>)
                            }
                          >
                            {type}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    {touchedFields.property_type &&
                      formErrors.property_type && (
                        <ErrorMessage>{formErrors.property_type}</ErrorMessage>
                      )}
                  </Field>

                  <Field>
                    <Label htmlFor="status">Status*</Label>
                    <Dropdown>
                      <div className="w-full">
                        <DropdownButton
                          outline
                          className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                        >
                          {formData.status || 'Select Status'}
                          <ChevronDownIcon className="h-5 w-5 ml-2" />
                        </DropdownButton>
                      </div>
                      <DropdownMenu className="w-full">
                        {[
                          'Owner Occupied',
                          'Renter Occupied',
                          'Vacant',
                          'For Sale',
                          'For Rent',
                          'Unknown',
                        ].map((status) => (
                          <DropdownItem
                            key={status}
                            onClick={() =>
                              handleInputChange({
                                target: {
                                  name: 'status',
                                  value: status,
                                  type: 'change',
                                  checked: false,
                                  nodeName: 'INPUT',
                                } as unknown as EventTarget & HTMLInputElement,
                              } as React.ChangeEvent<HTMLInputElement>)
                            }
                          >
                            {status}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    {touchedFields.status && formErrors.status && (
                      <ErrorMessage>{formErrors.status}</ErrorMessage>
                    )}
                  </Field>
                </div>
              </FieldGroup>
            </Fieldset>
          </DialogBody>
        </div>

        <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
          <div className="flex flex-col space-y-3">
            <Button
              onClick={handleSave}
              color="sky"
              disabled={!isFormValid()}
              className="w-full disabled:opacity-50"
            >
              Save
            </Button>
            <Button onClick={onClose} color="zinc" className="w-full">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddHomeDialog;
