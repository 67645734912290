import React from 'react';
import { Input, InputGroup } from '../Catalyst/input';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface SearchBarProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  value,
  onChange,
  placeholder = 'Search by name or address...',
}) => {
  return (
    <div className="w-full sm:w-64 bg-stone-300 dark:bg-gray-800">
      <InputGroup className="[&>[data-slot=icon]]:text-zinc-500 dark:[&>[data-slot=icon]]:text-white">
        <MagnifyingGlassIcon data-slot="icon" className="h-5 w-5" />
        <Input
          type="search"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="pl-10"
        />
      </InputGroup>
    </div>
  );
};

export default SearchBar;
