import React, { useState, useEffect } from 'react';
import { companyService, userService } from './clients/ApiClients';
import { SidebarLayout } from './common/Catalyst/sidebar-layout';
import { Navbar } from './common/Catalyst/navbar';
import AppSidebar from './AppSidebar';
import { Button } from './common/Catalyst/button';
import { Heading } from './common/Catalyst/heading';
import { Divider } from './common/Catalyst/divider';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from './common/Catalyst/fieldset';
import { Input } from './common/Catalyst/input';
import ShowAlert from './ShowAlert';
import {
  UpdateUserSchema,
  PasswordUpdate,
  UserSchema,
  Company,
} from './clients/auth-api-client';
import { getUserIdFromToken } from './utils/TokenUtils';
import LoadingSpinner from './LoadingSpinner';
import { PencilSquareIcon as PencilSquareIconOutline } from '@heroicons/react/24/outline';
import { PencilSquareIcon as PencilSquareIconSolid } from '@heroicons/react/24/solid';

interface SettingsPageProps {
  currentPage: string;
}

interface ExtendedUpdateUserSchema extends UpdateUserSchema {
  email_verification_password?: string;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ currentPage }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserSchema | null>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isProfileEditing, setIsProfileEditing] = useState(false);
  const [isPasswordEditing, setIsPasswordEditing] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);

  const [formData, setFormData] = useState<ExtendedUpdateUserSchema>({
    email: null,
    full_name: null,
    email_verification_password: '',
  });

  const [touchedFields, setTouchedFields] = useState<{
    email?: boolean;
    full_name?: boolean;
    email_verification_password?: boolean;
    current_password?: boolean;
    new_password?: boolean;
    confirm_password?: boolean;
  }>({});

  const [passwordData, setPasswordData] = useState<
    PasswordUpdate & { confirm_password: string }
  >({
    current_password: '',
    new_password: '',
    confirm_password: '',
  });

  const [errors, setErrors] = useState<{
    email?: string;
    full_name?: string;
    email_verification_password?: string;
    current_password?: string;
    new_password?: string;
    confirm_password?: string;
  }>({});

  useEffect(() => {
    const fetchUserAndCompany = async () => {
      const userId = getUserIdFromToken();

      if (!userId) {
        setShowError(true);
        setErrorMessage(
          'Unable to load user information. Please try logging in again.'
        );
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const userResponse = await userService.getUserById({
          userId: Number(userId),
        });
        setUser(userResponse.data);
        setFormData({
          email: userResponse.data.email,
          full_name: userResponse.data.full_name,
          email_verification_password: '',
        });

        // Fetch company data if company_id exists
        if (userResponse.data.company_id) {
          try {
            const companyResponse = await companyService.getCompanyById({
              companyId: userResponse.data.company_id,
            });
            setCompany(companyResponse.data);
          } catch (err) {
            console.error('Error fetching company:', err);
            setShowError(true);
            setErrorMessage('Failed to load company data');
          }
        }
      } catch (err) {
        console.error('Error fetching user:', err);
        setShowError(true);
        setErrorMessage('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndCompany();
  }, []);

  const validateField = (field: string, value: string) => {
    switch (field) {
      case 'email':
        return value && !value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
          ? 'Invalid email format'
          : '';
      case 'full_name':
        return !value || !value.trim() || value.trim().length < 2
          ? 'Name must be at least 2 characters (not including spaces)'
          : '';
      case 'email_verification_password':
      case 'current_password':
        return !value ? 'Current password is required' : '';
      case 'new_password':
        return value.length < 8 ? 'Password must be at least 8 characters' : '';
      case 'confirm_password':
        return value !== passwordData.new_password
          ? 'Passwords do not match'
          : '';
      default:
        return '';
    }
  };

  const validateForm = () => {
    const newErrors: typeof errors = {};
    let hasErrors = false;

    if (formData.email) {
      const emailError = validateField('email', formData.email);
      if (emailError) {
        newErrors.email = emailError;
        hasErrors = true;
      }

      // Require password verification if email is being changed
      if (formData.email !== user?.email) {
        if (!formData.email_verification_password) {
          newErrors.email_verification_password =
            'Password required to change email';
          hasErrors = true;
        } else {
          const passwordError = validateField(
            'email_verification_password',
            formData.email_verification_password
          );
          if (passwordError) {
            newErrors.email_verification_password = passwordError;
            hasErrors = true;
          }
        }
      }
    }

    if (formData.full_name) {
      const nameError = validateField('full_name', formData.full_name);
      if (nameError) {
        newErrors.full_name = nameError;
        hasErrors = true;
      }
    }

    setErrors(newErrors);
    return !hasErrors;
  };

  const validatePasswordForm = () => {
    const newErrors: typeof errors = {};
    let hasErrors = false;

    const currentPasswordError = validateField(
      'current_password',
      passwordData.current_password
    );
    if (currentPasswordError) {
      newErrors.current_password = currentPasswordError;
      hasErrors = true;
    }

    const newPasswordError = validateField(
      'new_password',
      passwordData.new_password
    );
    if (newPasswordError) {
      newErrors.new_password = newPasswordError;
      hasErrors = true;
    }

    const confirmPasswordError = validateField(
      'confirm_password',
      passwordData.confirm_password
    );
    if (confirmPasswordError) {
      newErrors.confirm_password = confirmPasswordError;
      hasErrors = true;
    }

    setErrors(newErrors);
    return !hasErrors;
  };

  const handleBlur = (field: string) => {
    setTouchedFields((prev) => ({
      ...prev,
      [field]: true,
    }));

    if (
      (isProfileEditing &&
        ['email', 'full_name', 'email_verification_password'].includes(
          field
        )) ||
      (isPasswordEditing &&
        ['current_password', 'new_password', 'confirm_password'].includes(
          field
        ))
    ) {
      const value =
        field === 'email' ||
        field === 'full_name' ||
        field === 'email_verification_password'
          ? (formData[field as keyof ExtendedUpdateUserSchema] as string) || ''
          : passwordData[field as keyof typeof passwordData];
      const error = validateField(field, value);
      setErrors((prev) => ({
        ...prev,
        [field]: error || undefined,
      }));
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    formType: 'profile' | 'password'
  ) => {
    const { name, value } = e.target;

    if (formType === 'profile') {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setPasswordData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (touchedFields[name as keyof typeof touchedFields]) {
      const error = validateField(name, value);
      setErrors((prev) => ({
        ...prev,
        [name]: error || undefined,
      }));
    }
  };

  const handleProfileUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    const userId = getUserIdFromToken();
    try {
      if (!userId) {
        setShowError(true);
        setErrorMessage(
          'Unable to load user information. Please try logging in again.'
        );
        return;
      }

      const updateData: UpdateUserSchema = {
        email: formData.email,
        full_name: formData.full_name,
      };

      // Only include password if email is being changed
      if (formData.email !== user?.email) {
        updateData.current_password = formData.email_verification_password;
      }

      const response = await userService.updateUser({
        userId: Number(userId),
        updateUserSchema: updateData,
      });
      setUser(response.data);
      setFormData({
        email: response.data.email,
        full_name: response.data.full_name,
        email_verification_password: '',
      });

      setSuccessMessage('Profile updated successfully');
      setShowSuccessAlert(true);
      setIsProfileEditing(false);
      setFormData((prev) => ({ ...prev, email_verification_password: '' }));
    } catch (err) {
      console.error('Error updating profile:', err);
      setShowError(true);
      setErrorMessage(
        'Failed to update profile. Please check your data and try again.'
      );
    }
  };

  const handlePasswordUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validatePasswordForm()) return;

    try {
      const userId = getUserIdFromToken();
      if (!userId) {
        setShowError(true);
        setErrorMessage(
          'Unable to load user information. Please try logging in again.'
        );
        return;
      }

      const { confirm_password, ...passwordUpdateData } = passwordData;

      await userService.updateUserPassword({
        userId: Number(userId),
        passwordUpdate: passwordUpdateData,
      });
      setSuccessMessage('Password updated successfully');
      setShowSuccessAlert(true);
      setPasswordData({
        current_password: '',
        new_password: '',
        confirm_password: '',
      });
      setIsPasswordEditing(false);
    } catch (err) {
      console.error('Error updating password:', err);
      setShowError(true);
      setErrorMessage(
        'Failed to update password. Please check your current password and try again.'
      );
    }
  };

  const handleCancelProfileEdit = () => {
    setIsProfileEditing(false);
    setFormData({
      email: user?.email || null,
      full_name: user?.full_name || null,
      email_verification_password: '',
    });
    setErrors({});
    setTouchedFields({});
  };

  const handleCancelPasswordEdit = () => {
    setIsPasswordEditing(false);
    setPasswordData({
      current_password: '',
      new_password: '',
      confirm_password: '',
    });
    setErrors({});
    setTouchedFields({});
  };

  const isProfileFormValid = () => {
    // Check if any field has validation errors when touched
    if (
      (touchedFields.email && errors.email) ||
      (touchedFields.full_name && errors.full_name) ||
      (touchedFields.email_verification_password &&
        errors.email_verification_password)
    ) {
      return false;
    }

    // Check if any fields are empty or invalid length
    if (
      !formData.email?.trim() ||
      !formData.full_name?.trim() ||
      formData.full_name.trim().length < 2
    ) {
      return false;
    }

    // Check if any data has actually changed
    if (
      formData.email === user?.email &&
      formData.full_name === user?.full_name
    ) {
      return false;
    }

    // If email is being changed, verify password is provided
    if (
      formData.email !== user?.email &&
      !formData.email_verification_password
    ) {
      return false;
    }

    return true;
  };

  const isPasswordFormValid = () => {
    // Check if there are any errors
    if (
      errors.current_password ||
      errors.new_password ||
      errors.confirm_password
    ) {
      return false;
    }

    // Check if all fields are filled
    if (
      !passwordData.current_password ||
      !passwordData.new_password ||
      !passwordData.confirm_password
    ) {
      return false;
    }

    // Check password requirements
    if (passwordData.new_password.length < 8) {
      return false;
    }

    // Check if passwords match
    if (passwordData.new_password !== passwordData.confirm_password) {
      return false;
    }

    return true;
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <SidebarLayout
      sidebar={<AppSidebar currentPage={currentPage} />}
      navbar={<Navbar />}
    >
      <div className="max-w-4xl mx-auto">
        <Heading level={2}>Account Settings</Heading>
        <Divider className="mt-4 mb-6" />

        <ShowAlert
          alertType="success"
          alertTitle="Success"
          alertMessage={successMessage}
          isVisible={showSuccessAlert}
          onClose={() => setShowSuccessAlert(false)}
          timeout={5000}
          isInline={true}
        />

        <ShowAlert
          alertType="error"
          alertTitle="Error"
          alertMessage={errorMessage}
          isVisible={showError}
          onClose={() => setShowError(false)}
          timeout={5000}
          isInline={true}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-stone-100 dark:bg-gray-600 p-6 rounded-lg shadow h-full">
            <div className="flex justify-between items-center mb-4">
              <Heading level={3}>Profile Information</Heading>
              <button
                onClick={() => setIsProfileEditing(!isProfileEditing)}
                className="p-1 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-200"
              >
                {isProfileEditing ? (
                  <PencilSquareIconSolid className="h-5 w-5 text-blue-500" />
                ) : (
                  <PencilSquareIconOutline className="h-5 w-5 text-zinc-400 hover:text-blue-500 dark:text-zinc-100 dark:hover:text-blue-500" />
                )}
              </button>
            </div>

            <form onSubmit={handleProfileUpdate} className="space-y-4">
              <Fieldset>
                <FieldGroup>
                  <Field>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email || ''}
                      onChange={(e) => handleInputChange(e, 'profile')}
                      onBlur={() => handleBlur('email')}
                      disabled={!isProfileEditing}
                      data-invalid={
                        touchedFields.email && errors.email ? true : undefined
                      }
                    />
                    {touchedFields.email && errors.email && (
                      <ErrorMessage>{errors.email}</ErrorMessage>
                    )}
                  </Field>
                  <Field>
                    <Label htmlFor="full_name">Full Name</Label>
                    <Input
                      id="full_name"
                      name="full_name"
                      value={formData.full_name || ''}
                      onChange={(e) => handleInputChange(e, 'profile')}
                      onBlur={() => handleBlur('full_name')}
                      disabled={!isProfileEditing}
                      data-invalid={
                        touchedFields.full_name && errors.full_name
                          ? true
                          : undefined
                      }
                    />
                    {touchedFields.full_name && errors.full_name && (
                      <ErrorMessage>{errors.full_name}</ErrorMessage>
                    )}
                  </Field>

                  {isProfileEditing && formData.email !== user?.email && (
                    <Field>
                      <Label htmlFor="verify_password">Current Password</Label>
                      <Description>
                        Password verification required to change email
                      </Description>
                      <Input
                        id="email_verification_password"
                        name="email_verification_password"
                        type="password"
                        autoComplete="password"
                        value={formData.email_verification_password || ''}
                        onChange={(e) => handleInputChange(e, 'profile')}
                        onBlur={() => handleBlur('email_verification_password')}
                        data-invalid={
                          touchedFields.email_verification_password &&
                          errors.email_verification_password
                            ? true
                            : undefined
                        }
                      />
                      {touchedFields.email_verification_password &&
                        errors.email_verification_password && (
                          <ErrorMessage>
                            {errors.email_verification_password}
                          </ErrorMessage>
                        )}
                    </Field>
                  )}
                </FieldGroup>
              </Fieldset>
              {isProfileEditing && (
                <div className="mt-4 space-x-2">
                  <Button
                    type="submit"
                    color="sky"
                    disabled={!isProfileFormValid()}
                  >
                    Save Changes
                  </Button>
                  <Button
                    type="button"
                    color="zinc"
                    onClick={handleCancelProfileEdit}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </form>
          </div>

          <div className="bg-stone-100 dark:bg-gray-600 p-6 rounded-lg shadow h-full">
            <div className="flex justify-between items-center mb-4">
              <Heading level={3}>Change Password</Heading>
              <button
                onClick={() => setIsPasswordEditing(!isPasswordEditing)}
                className="p-1 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-200"
              >
                {isPasswordEditing ? (
                  <PencilSquareIconSolid className="h-5 w-5 text-blue-500" />
                ) : (
                  <PencilSquareIconOutline className="h-5 w-5 text-zinc-500 dark:text-zinc-100 hover:text-blue-500 dark:hover:text-blue-500" />
                )}
              </button>
            </div>

            <form onSubmit={handlePasswordUpdate} className="space-y-4">
              <Fieldset>
                <FieldGroup>
                  <Field>
                    <Label htmlFor="current_password">Current Password</Label>
                    <Input
                      id="current_password"
                      name="current_password"
                      type="password"
                      autoComplete="current password"
                      value={passwordData.current_password}
                      onChange={(e) => handleInputChange(e, 'password')}
                      onBlur={() => handleBlur('current_password')}
                      disabled={!isPasswordEditing}
                      data-invalid={
                        touchedFields.current_password &&
                        errors.current_password
                          ? true
                          : undefined
                      }
                    />
                    {touchedFields.current_password &&
                      errors.current_password && (
                        <ErrorMessage>{errors.current_password}</ErrorMessage>
                      )}
                  </Field>
                  <Field>
                    <Label htmlFor="new_password">New Password</Label>
                    <Description>Must be at least 8 characters</Description>
                    <Input
                      id="new_password"
                      name="new_password"
                      type="password"
                      autoComplete="new password"
                      value={passwordData.new_password}
                      onChange={(e) => handleInputChange(e, 'password')}
                      onBlur={() => handleBlur('new_password')}
                      disabled={!isPasswordEditing}
                      data-invalid={
                        touchedFields.new_password && errors.new_password
                          ? true
                          : undefined
                      }
                    />
                    {touchedFields.new_password && errors.new_password && (
                      <ErrorMessage>{errors.new_password}</ErrorMessage>
                    )}
                  </Field>
                  <Field>
                    <Label htmlFor="confirm_password">
                      Confirm New Password
                    </Label>
                    <Input
                      id="confirm_password"
                      name="confirm_password"
                      type="password"
                      autoComplete="confirm password"
                      value={passwordData.confirm_password}
                      onChange={(e) => handleInputChange(e, 'password')}
                      onBlur={() => handleBlur('confirm_password')}
                      disabled={!isPasswordEditing}
                      data-invalid={
                        touchedFields.confirm_password &&
                        errors.confirm_password
                          ? true
                          : undefined
                      }
                    />
                    {touchedFields.confirm_password &&
                      errors.confirm_password && (
                        <ErrorMessage>{errors.confirm_password}</ErrorMessage>
                      )}
                  </Field>
                </FieldGroup>
              </Fieldset>
              {isPasswordEditing && (
                <div className="mt-4 space-x-2">
                  <Button
                    type="submit"
                    color="sky"
                    disabled={!isPasswordFormValid()}
                  >
                    Update Password
                  </Button>
                  <Button
                    type="button"
                    color="zinc"
                    onClick={handleCancelPasswordEdit}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </form>
          </div>
          {company && (
            <div className="md:col-span-1 h-full">
              <div className="bg-stone-100 dark:bg-gray-600 p-6 rounded-lg shadow">
                <Heading level={3}>Company Information</Heading>
                <Fieldset>
                  <FieldGroup>
                    <Field>
                      <Label>Company Name</Label>
                      <p className="text-gray-700 dark:text-gray-300">
                        {company.name}
                      </p>
                    </Field>
                    <Field>
                      <Label>Email</Label>
                      <p className="text-gray-700 dark:text-gray-300">
                        {company.email}
                      </p>
                    </Field>
                    <Field>
                      <Label>Phone</Label>
                      <p className="text-gray-700 dark:text-gray-300">
                        {company.phone || 'N/A'}
                      </p>
                    </Field>
                    <Field>
                      <Label>Website</Label>
                      <p className="text-gray-700 dark:text-gray-300">
                        {company.website || 'N/A'}
                      </p>
                    </Field>
                    <Field>
                      <Label>Address</Label>
                      <p className="text-gray-700 dark:text-gray-300">
                        {company.street}
                        {company.street2 ? `, ${company.street2}` : ''}
                        <br />
                        {company.city}, {company.state} {company.zip}
                        <br />
                        {company.country}
                      </p>
                    </Field>
                  </FieldGroup>
                </Fieldset>
              </div>
            </div>
          )}
        </div>
      </div>
    </SidebarLayout>
  );
};

export default SettingsPage;
