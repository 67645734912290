import React from 'react';
import { HoaSchema } from '../clients/hoa-api-client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../common/Catalyst/table';
import { formatPhoneNumber } from '../utils/phoneUtils';

interface HoaTableProps {
  hoas: HoaSchema[];
  onRowClick: (hoa: HoaSchema) => void;
}

export const HoaTable: React.FC<HoaTableProps> = ({ hoas, onRowClick }) => {
  const getFullAddress = (hoa: HoaSchema): string => {
    const parts = [
      hoa.street,
      hoa.street2,
      hoa.city,
      hoa.state,
      hoa.zip,
      hoa.country,
    ].filter(Boolean);
    return parts.join(', ');
  };

  return (
    <div className="bg-stone-100 dark:bg-gray-600 rounded-lg shadow transition-colors duration-200 overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div className="max-h-[500px] overflow-y-auto relative">
                <Table>
                  <TableHead className="sticky top-0 z-10 bg-stone-100 dark:bg-gray-600">
                    <TableRow>
                      <TableHeader className="text-base font-semibold">
                        Name
                      </TableHeader>
                      <TableHeader className="text-base font-semibold">
                        Address
                      </TableHeader>
                      <TableHeader className="text-base font-semibold">
                        Contact Email
                      </TableHeader>
                      <TableHeader className="text-base font-semibold">
                        Contact Phone
                      </TableHeader>
                      <TableHeader className="text-base font-semibold">
                        Status
                      </TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hoas.map((hoa) => (
                      <TableRow
                        key={hoa.id}
                        onClick={() => onRowClick(hoa)}
                        className="cursor-pointer hover:bg-stone-200 dark:hover:bg-zinc-700 transition-colors duration-150"
                      >
                        <TableCell>{hoa.name}</TableCell>
                        <TableCell>{getFullAddress(hoa)}</TableCell>
                        <TableCell>{hoa.contact_email}</TableCell>
                        <TableCell>
                          {hoa.contact_phone
                            ? formatPhoneNumber(hoa.contact_phone)
                            : ''}
                        </TableCell>
                        <TableCell>
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              hoa.is_active
                                ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                                : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
                            }`}
                          >
                            {hoa.is_active ? 'Active' : 'Inactive'}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoaTable;
