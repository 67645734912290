import React, { useEffect, useMemo, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, DialogBody, DialogTitle } from '../common/Catalyst/dialog';
import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from '../common/Catalyst/fieldset';
import { Button } from '../common/Catalyst/button';
import { HoaSchema, HomeSchema } from '../clients/hoa-api-client';
import { Divider } from '../common/Catalyst/divider';
import SearchableDropdown from '../common/Custom/SearchableDropdown';
import { hoaService, homeService } from '../clients/ApiClients';
import LoadingSpinner from '../LoadingSpinner';
import { MemberSchema } from '../clients/auth-api-client';

interface AddHomeToMemberDialogProps {
  isOpen: boolean;
  member: MemberSchema;
  onClose: () => void;
  onSave: (addHometoMember: AddHomeToMember) => Promise<void>;
}

interface AddHomeToMember {
  homeId: number;
  memberId: number;
}

interface DropdownHome extends HomeSchema {
  name: string;
}

export const AddHomeToMemberDialog: React.FC<AddHomeToMemberDialogProps> = ({
  isOpen,
  member,
  onClose,
  onSave,
}) => {
  const [hoaOptions, setHoaOptions] = useState<HoaSchema[]>([]);
  const [homeOptions, setHomeOptions] = useState<DropdownHome[]>([]);
  const [selectedHoa, setSelectedHoa] = useState<HoaSchema | null>(null);
  const [selectedHome, setSelectedHome] = useState<HomeSchema | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHoas = async () => {
      try {
        setLoading(true);
        const response = await hoaService.getAllHoas();
        setHoaOptions(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load HOAs. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchHoas();
  }, []);

  const handleHoaSelected = async (hoa: HoaSchema | null) => {
    setSelectedHoa(hoa);
    setSelectedHome(null);
    setHomeOptions([]);

    if (hoa) {
      try {
        setLoading(true);
        const response = await homeService.getHomesByHoa({
          hoaId: hoa.id,
        });
        // Transform the home data to match the BaseOption interface
        const formattedHomes = response.data.map((home) => ({
          ...home,
          id: home.id,
          name: home.street + home.street2 || `Home ${home.id}`,
        }));
        setHomeOptions(formattedHomes);
        setError(null);
      } catch (err) {
        console.error('Error fetching homes:', err);
        setError('Failed to load homes. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSave = async () => {
    if (isValid) {
      try {
        setLoading(true);
        await onSave({ memberId: member.id, homeId: selectedHome!.id });
        onClose();
      } catch (err) {
        console.error('Error saving home to member:', err);
        setError('Failed to save. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
  };

  const isValid = selectedHome && selectedHoa && member;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        className="w-full max-w-2xl mx-auto"
      >
        <div className="relative flex flex-col h-full">
          <div className="p-4 md:p-6">
            <DialogTitle className="text-xl font-semibold">
              Add a Home to Member
            </DialogTitle>
            <button
              onClick={onClose}
              className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
              aria-label="Close dialog"
            >
              <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
            </button>

            <Divider className="my-4" />

            {loading && <LoadingSpinner></LoadingSpinner>}

            <DialogBody>
              <Fieldset>
                <FieldGroup>
                  {/*HOA Selector */}

                  <div className="space-y-4">
                    <Label htmlFor="hoa">Select an HOA*</Label>
                    <Field>
                      <SearchableDropdown
                        options={hoaOptions}
                        onSelect={(option) => handleHoaSelected(option)}
                        placeholder="Search for an HOA..."
                      ></SearchableDropdown>
                    </Field>

                    {/*Honme Selector */}
                    <Field>
                      <Label htmlFor="home">Select a Home*</Label>
                      <SearchableDropdown
                        options={homeOptions}
                        onSelect={setSelectedHome}
                        placeholder="Search for a Home..."
                      ></SearchableDropdown>
                    </Field>
                  </div>
                </FieldGroup>
              </Fieldset>
            </DialogBody>
          </div>
          <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
            <div className="flex flex-col space-y-3">
              <Button
                onClick={handleSave}
                color="sky"
                disabled={!isValid}
                className="w-full disabled:opacity-50"
              >
                Save
              </Button>
              <Button onClick={onClose} color="zinc" className="w-full">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddHomeToMemberDialog;
