import React, { useEffect, useMemo, useState } from 'react';
import { XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  HomeSchema,
  HomeCreateOrUpdateSchema,
} from '../clients/hoa-api-client';
import { Button } from '../common/Catalyst/button';
import { Dialog, DialogBody, DialogTitle } from '../common/Catalyst/dialog';
import { Divider } from '../common/Catalyst/divider';
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from '../common/Catalyst/fieldset';
import { Input } from '../common/Catalyst/input';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from '../common/Catalyst/dropdown';
import usStates from '../utils/addressUtils';

interface EditHomeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (home: HomeCreateOrUpdateSchema) => Promise<void>;
  home: HomeSchema;
}

interface FormErrors {
  [key: string]: string;
}

interface TouchedFields {
  [key: string]: boolean;
}

export const EditHomeDialog: React.FC<EditHomeDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  home,
}) => {
  const [formData, setFormData] = useState<HomeCreateOrUpdateSchema>(() => ({
    hoa_id: home.hoa_id,
    street: home.street,
    street2: home.street2 || '',
    city: home.city,
    state: home.state,
    zip: home.zip,
    country: home.country || '',
    property_type: home.property_type,
    status: home.status,
  }));

  const [errors, setFormErrors] = useState<FormErrors>({});
  const [touched, setTouched] = useState<TouchedFields>({});
  const [isSaving, setIsSaving] = useState(false);

  const propertyTypes = [
    'Single Family',
    'Townhouse',
    'Condo',
    'Apartment',
    'Other',
  ];

  const statusOptions = [
    'Owner Occupied',
    'Renter Occupied',
    'Vacant',
    'For Sale',
    'For Rent',
    'Unknown',
  ];

  useEffect(() => {
    // Reset form data when home changes
    setFormData({
      hoa_id: home.hoa_id,
      street: home.street,
      street2: home.street2 || '',
      city: home.city,
      state: home.state,
      zip: home.zip,
      country: home.country || '',
      property_type: home.property_type,
      status: home.status,
    });
    setFormErrors({});
    setTouched({});
  }, [home]);

  const validateField = (name: string, value: any): string => {
    switch (name) {
      case 'street':
      case 'city':
      case 'state':
      case 'zip':
        return !value
          ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
          : '';
      case 'property_type':
      case 'status':
        return !value || value === 'Select Type' || value === 'Select Status'
          ? `${name.charAt(0).toUpperCase() + name.slice(1).replace('_', ' ')} is required`
          : '';
      default:
        return '';
    }
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string } }
  ) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
    setTouched((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setTouched((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const handleSave = async () => {
    if (!isFormValid()) return;

    setIsSaving(true);
    try {
      await onSave(formData);
      onClose();
    } finally {
      setIsSaving(false);
    }
  };

  const isFormValid = () => {
    // Check for any existing errors
    if (Object.keys(errors).length > 0) {
      return false;
    }

    // Required fields
    const requiredFields = [
      'street',
      'city',
      'state',
      'zip',
      'property_type',
      'status',
    ];

    return requiredFields.every((field) => {
      return (
        formData[field as keyof HomeCreateOrUpdateSchema] !== undefined &&
        formData[field as keyof HomeCreateOrUpdateSchema] !== ''
      );
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="w-full max-w-2xl mx-auto"
    >
      <div className="relative flex flex-col h-full">
        <div className="p-2 md:p-4">
          <DialogTitle className="text-xl font-semibold">Edit Home</DialogTitle>
          <button
            onClick={onClose}
            className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
            aria-label="Close dialog"
          >
            <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
          </button>

          <Divider className="my-4" />

          <DialogBody>
            <Fieldset>
              <FieldGroup>
                <div className="space-y-4">
                  <Field>
                    <Label htmlFor="street">Street*</Label>
                    <Input
                      id="street"
                      name="street"
                      value={formData.street || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter street address"
                      required
                      className="w-full"
                      data-invalid={touched.street && errors.street}
                    />
                    {touched.street && errors.street && (
                      <ErrorMessage>{errors.street}</ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label htmlFor="street2">Street 2</Label>
                    <Input
                      id="street2"
                      name="street2"
                      value={formData.street2 || ''}
                      onChange={handleInputChange}
                      placeholder="Enter additional street information (optional)"
                      className="w-full"
                    />
                  </Field>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                  <Field className="col-span-1 md:col-span-2">
                    <Label htmlFor="city">City*</Label>
                    <Input
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter city"
                      required
                      className="w-full"
                      data-invalid={touched.city && errors.city}
                    />
                    {touched.city && errors.city && (
                      <ErrorMessage>{errors.city}</ErrorMessage>
                    )}
                  </Field>

                  <Field className="col-span-1">
                    <Label htmlFor="state">State*</Label>
                    <Dropdown>
                      <div className="w-full">
                        <DropdownButton
                          outline
                          className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                        >
                          {formData.state || 'State'}
                          <ChevronDownIcon className="h-5 w-5 ml-2" />
                        </DropdownButton>
                      </div>
                      <DropdownMenu className="w-full max-h-60 overflow-y-auto">
                        {usStates.map((state) => (
                          <DropdownItem
                            key={state.label}
                            onClick={() =>
                              handleInputChange({
                                target: {
                                  name: 'state',
                                  value: state.value,
                                  type: 'change',
                                  checked: false,
                                  nodeName: 'INPUT',
                                } as unknown as EventTarget & HTMLInputElement,
                              } as React.ChangeEvent<HTMLInputElement>)
                            }
                          >
                            {state.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    {touched.state && errors.state && (
                      <ErrorMessage>{errors.state}</ErrorMessage>
                    )}
                  </Field>

                  <Field className="col-span-1 md:col-span-2">
                    <Label htmlFor="zip">ZIP Code*</Label>
                    <Input
                      id="zip"
                      name="zip"
                      value={formData.zip}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter ZIP code"
                      required
                      className="w-full"
                      data-invalid={touched.zip && errors.zip}
                    />
                    {touched.zip && errors.zip && (
                      <ErrorMessage>{errors.zip}</ErrorMessage>
                    )}
                  </Field>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <Field>
                    <Label htmlFor="property_type">Property Type*</Label>
                    <Dropdown>
                      <div className="w-full">
                        <DropdownButton
                          outline
                          className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                        >
                          {formData.property_type || 'Select Property Type'}
                          <ChevronDownIcon className="h-5 w-5 ml-2" />
                        </DropdownButton>
                      </div>
                      <DropdownMenu className="w-full">
                        {propertyTypes.map((type) => (
                          <DropdownItem
                            key={type}
                            onClick={() =>
                              handleInputChange({
                                target: {
                                  name: 'property_type',
                                  value: type,
                                  type: 'change',
                                  checked: false,
                                  nodeName: 'INPUT',
                                } as unknown as EventTarget & HTMLInputElement,
                              } as React.ChangeEvent<HTMLInputElement>)
                            }
                          >
                            {type}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    {touched.property_type && errors.property_type && (
                      <ErrorMessage>{errors.property_type}</ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label htmlFor="status">Status*</Label>
                    <Dropdown>
                      <div className="w-full">
                        <DropdownButton
                          outline
                          className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                        >
                          {formData.status || 'Select Status'}
                          <ChevronDownIcon className="h-5 w-5 ml-2" />
                        </DropdownButton>
                      </div>
                      <DropdownMenu className="w-full">
                        {statusOptions.map((status) => (
                          <DropdownItem
                            key={status}
                            onClick={() =>
                              handleInputChange({
                                target: {
                                  name: 'status',
                                  value: status,
                                  type: 'change',
                                  checked: false,
                                  nodeName: 'INPUT',
                                } as unknown as EventTarget & HTMLInputElement,
                              } as React.ChangeEvent<HTMLInputElement>)
                            }
                          >
                            {status}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    {touched.status && errors.status && (
                      <ErrorMessage>{errors.status}</ErrorMessage>
                    )}
                  </Field>
                </div>
              </FieldGroup>
            </Fieldset>
          </DialogBody>
        </div>

        <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
          <div className="flex flex-col space-y-3">
            <Button
              onClick={handleSave}
              color="sky"
              disabled={isSaving || !isFormValid()}
              className="w-full disabled:opacity-50"
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
            <Button onClick={onClose} color="zinc" className="w-full">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditHomeDialog;
