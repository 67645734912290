/* tslint:disable */
/* eslint-disable */
/**
 * Hoa Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HoaCreateOrUpdateSchema
 */
export interface HoaCreateOrUpdateSchema {
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'contact_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'contact_phone'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HoaCreateOrUpdateSchema
     */
    'company_id': number;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'website_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HoaCreateOrUpdateSchema
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface HoaSchema
 */
export interface HoaSchema {
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'contact_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'contact_phone'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HoaSchema
     */
    'company_id': number;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'website_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HoaSchema
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HoaSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'date_updated'?: string | null;
}
/**
 * 
 * @export
 * @interface HomeCreateOrUpdateSchema
 */
export interface HomeCreateOrUpdateSchema {
    /**
     * 
     * @type {number}
     * @memberof HomeCreateOrUpdateSchema
     */
    'hoa_id': number;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'property_type': string;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface HomeSchema
 */
export interface HomeSchema {
    /**
     * 
     * @type {number}
     * @memberof HomeSchema
     */
    'hoa_id': number;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'property_type': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof HomeSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'date_updated'?: string | null;
    /**
     * 
     * @type {Array<MemberSchema>}
     * @memberof HomeSchema
     */
    'members'?: Array<MemberSchema>;
}
/**
 * 
 * @export
 * @interface MemberSchema
 */
export interface MemberSchema {
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'middle_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'preferred_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MemberSchema
     */
    'company_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof MemberSchema
     */
    'is_primary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'user_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberSchema
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof MemberSchema
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'date_updated'?: string | null;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRootInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRootInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getRootInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootLivenessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootLivenessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootLivenessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootReadinessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootReadinessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootReadinessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootInfo(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getRootInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootLivenessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootReadinessGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRootInfo(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRootInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootLivenessGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootLivenessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootReadinessGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootReadinessGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HoaApi - axios parameter creator
 * @export
 */
export const HoaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new HOA.
         * @summary Create Hoa
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoa: async (hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHoa', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoas/hoa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new HOA.
         * @summary Create Hoa For User
         * @param {number} userId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoaForUser: async (userId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createHoaForUser', 'userId', userId)
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHoaForUser', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoas/hoa/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new HOA.
         * @summary Create Hoa For User
         * @param {number} userId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoaForUser_1: async (userId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createHoaForUser_1', 'userId', userId)
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHoaForUser_1', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoas/hoa/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new HOA.
         * @summary Create Hoa
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoa_2: async (hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHoa_2', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoas/hoa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateHoa: async (hoaId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deactivateHoa', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}/deactivate`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateHoa_3: async (hoaId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deactivateHoa_3', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}/deactivate`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoa: async (hoaId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteHoa', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoa_4: async (hoaId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteHoa_4', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all active HOAs with their detailed address information.
         * @summary Get Active Hoas
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveHoas: async (tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hoas/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all active HOAs with their detailed address information.
         * @summary Get Active Hoas
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveHoas_5: async (tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hoas/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHoas: async (tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hoas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHoas_6: async (tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hoas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an HOA by its ID.
         * @summary Get Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoaById: async (hoaId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHoaById', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an HOA by its ID.
         * @summary Get Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoaById_7: async (hoaId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHoaById_7', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Hoa
         * @param {number} hoaId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoa: async (hoaId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('updateHoa', 'hoaId', hoaId)
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('updateHoa', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Hoa
         * @param {number} hoaId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoa_8: async (hoaId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('updateHoa_8', 'hoaId', hoaId)
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('updateHoa_8', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HoaApi - functional programming interface
 * @export
 */
export const HoaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HoaApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new HOA.
         * @summary Create Hoa
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHoa(hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHoa(hoaCreateOrUpdateSchema, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.createHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new HOA.
         * @summary Create Hoa For User
         * @param {number} userId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHoaForUser(userId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHoaForUser(userId, hoaCreateOrUpdateSchema, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.createHoaForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new HOA.
         * @summary Create Hoa For User
         * @param {number} userId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHoaForUser_1(userId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHoaForUser_1(userId, hoaCreateOrUpdateSchema, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.createHoaForUser_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new HOA.
         * @summary Create Hoa
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHoa_2(hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHoa_2(hoaCreateOrUpdateSchema, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.createHoa_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateHoa(hoaId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateHoa(hoaId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.deactivateHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateHoa_3(hoaId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateHoa_3(hoaId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.deactivateHoa_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHoa(hoaId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHoa(hoaId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.deleteHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHoa_4(hoaId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHoa_4(hoaId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.deleteHoa_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all active HOAs with their detailed address information.
         * @summary Get Active Hoas
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllActiveHoas(tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HoaSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllActiveHoas(tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getAllActiveHoas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all active HOAs with their detailed address information.
         * @summary Get Active Hoas
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllActiveHoas_5(tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HoaSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllActiveHoas_5(tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getAllActiveHoas_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllHoas(tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HoaSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllHoas(tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getAllHoas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllHoas_6(tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HoaSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllHoas_6(tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getAllHoas_6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve an HOA by its ID.
         * @summary Get Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHoaById(hoaId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHoaById(hoaId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getHoaById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve an HOA by its ID.
         * @summary Get Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHoaById_7(hoaId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHoaById_7(hoaId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getHoaById_7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Hoa
         * @param {number} hoaId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHoa(hoaId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHoa(hoaId, hoaCreateOrUpdateSchema, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.updateHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Hoa
         * @param {number} hoaId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHoa_8(hoaId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHoa_8(hoaId, hoaCreateOrUpdateSchema, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.updateHoa_8']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HoaApi - factory interface
 * @export
 */
export const HoaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HoaApiFp(configuration)
    return {
        /**
         * Create a new HOA.
         * @summary Create Hoa
         * @param {HoaApiCreateHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoa(requestParameters: HoaApiCreateHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.createHoa(requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new HOA.
         * @summary Create Hoa For User
         * @param {HoaApiCreateHoaForUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoaForUser(requestParameters: HoaApiCreateHoaForUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.createHoaForUser(requestParameters.userId, requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new HOA.
         * @summary Create Hoa For User
         * @param {HoaApiCreateHoaForUser0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoaForUser_1(requestParameters: HoaApiCreateHoaForUser0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.createHoaForUser_1(requestParameters.userId, requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new HOA.
         * @summary Create Hoa
         * @param {HoaApiCreateHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoa_2(requestParameters: HoaApiCreateHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.createHoa_2(requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {HoaApiDeactivateHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateHoa(requestParameters: HoaApiDeactivateHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.deactivateHoa(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {HoaApiDeactivateHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateHoa_3(requestParameters: HoaApiDeactivateHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.deactivateHoa_3(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {HoaApiDeleteHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoa(requestParameters: HoaApiDeleteHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.deleteHoa(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {HoaApiDeleteHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoa_4(requestParameters: HoaApiDeleteHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.deleteHoa_4(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all active HOAs with their detailed address information.
         * @summary Get Active Hoas
         * @param {HoaApiGetAllActiveHoasRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveHoas(requestParameters: HoaApiGetAllActiveHoasRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<HoaSchema>> {
            return localVarFp.getAllActiveHoas(requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all active HOAs with their detailed address information.
         * @summary Get Active Hoas
         * @param {HoaApiGetAllActiveHoas0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveHoas_5(requestParameters: HoaApiGetAllActiveHoas0Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<HoaSchema>> {
            return localVarFp.getAllActiveHoas_5(requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {HoaApiGetAllHoasRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHoas(requestParameters: HoaApiGetAllHoasRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<HoaSchema>> {
            return localVarFp.getAllHoas(requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {HoaApiGetAllHoas0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHoas_6(requestParameters: HoaApiGetAllHoas0Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<HoaSchema>> {
            return localVarFp.getAllHoas_6(requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an HOA by its ID.
         * @summary Get Hoa
         * @param {HoaApiGetHoaByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoaById(requestParameters: HoaApiGetHoaByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.getHoaById(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an HOA by its ID.
         * @summary Get Hoa
         * @param {HoaApiGetHoaById0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoaById_7(requestParameters: HoaApiGetHoaById0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.getHoaById_7(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Hoa
         * @param {HoaApiUpdateHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoa(requestParameters: HoaApiUpdateHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.updateHoa(requestParameters.hoaId, requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Hoa
         * @param {HoaApiUpdateHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoa_8(requestParameters: HoaApiUpdateHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.updateHoa_8(requestParameters.hoaId, requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createHoa operation in HoaApi.
 * @export
 * @interface HoaApiCreateHoaRequest
 */
export interface HoaApiCreateHoaRequest {
    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiCreateHoa
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema

    /**
     * 
     * @type {string}
     * @memberof HoaApiCreateHoa
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for createHoaForUser operation in HoaApi.
 * @export
 * @interface HoaApiCreateHoaForUserRequest
 */
export interface HoaApiCreateHoaForUserRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiCreateHoaForUser
     */
    readonly userId: number

    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiCreateHoaForUser
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema

    /**
     * 
     * @type {string}
     * @memberof HoaApiCreateHoaForUser
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for createHoaForUser_1 operation in HoaApi.
 * @export
 * @interface HoaApiCreateHoaForUser0Request
 */
export interface HoaApiCreateHoaForUser0Request {
    /**
     * 
     * @type {number}
     * @memberof HoaApiCreateHoaForUser0
     */
    readonly userId: number

    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiCreateHoaForUser0
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema

    /**
     * 
     * @type {string}
     * @memberof HoaApiCreateHoaForUser0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for createHoa_2 operation in HoaApi.
 * @export
 * @interface HoaApiCreateHoa0Request
 */
export interface HoaApiCreateHoa0Request {
    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiCreateHoa0
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema

    /**
     * 
     * @type {string}
     * @memberof HoaApiCreateHoa0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for deactivateHoa operation in HoaApi.
 * @export
 * @interface HoaApiDeactivateHoaRequest
 */
export interface HoaApiDeactivateHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiDeactivateHoa
     */
    readonly hoaId: number

    /**
     * 
     * @type {string}
     * @memberof HoaApiDeactivateHoa
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for deactivateHoa_3 operation in HoaApi.
 * @export
 * @interface HoaApiDeactivateHoa0Request
 */
export interface HoaApiDeactivateHoa0Request {
    /**
     * 
     * @type {number}
     * @memberof HoaApiDeactivateHoa0
     */
    readonly hoaId: number

    /**
     * 
     * @type {string}
     * @memberof HoaApiDeactivateHoa0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for deleteHoa operation in HoaApi.
 * @export
 * @interface HoaApiDeleteHoaRequest
 */
export interface HoaApiDeleteHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiDeleteHoa
     */
    readonly hoaId: number

    /**
     * 
     * @type {string}
     * @memberof HoaApiDeleteHoa
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for deleteHoa_4 operation in HoaApi.
 * @export
 * @interface HoaApiDeleteHoa0Request
 */
export interface HoaApiDeleteHoa0Request {
    /**
     * 
     * @type {number}
     * @memberof HoaApiDeleteHoa0
     */
    readonly hoaId: number

    /**
     * 
     * @type {string}
     * @memberof HoaApiDeleteHoa0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getAllActiveHoas operation in HoaApi.
 * @export
 * @interface HoaApiGetAllActiveHoasRequest
 */
export interface HoaApiGetAllActiveHoasRequest {
    /**
     * 
     * @type {string}
     * @memberof HoaApiGetAllActiveHoas
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getAllActiveHoas_5 operation in HoaApi.
 * @export
 * @interface HoaApiGetAllActiveHoas0Request
 */
export interface HoaApiGetAllActiveHoas0Request {
    /**
     * 
     * @type {string}
     * @memberof HoaApiGetAllActiveHoas0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getAllHoas operation in HoaApi.
 * @export
 * @interface HoaApiGetAllHoasRequest
 */
export interface HoaApiGetAllHoasRequest {
    /**
     * 
     * @type {string}
     * @memberof HoaApiGetAllHoas
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getAllHoas_6 operation in HoaApi.
 * @export
 * @interface HoaApiGetAllHoas0Request
 */
export interface HoaApiGetAllHoas0Request {
    /**
     * 
     * @type {string}
     * @memberof HoaApiGetAllHoas0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getHoaById operation in HoaApi.
 * @export
 * @interface HoaApiGetHoaByIdRequest
 */
export interface HoaApiGetHoaByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiGetHoaById
     */
    readonly hoaId: number

    /**
     * 
     * @type {string}
     * @memberof HoaApiGetHoaById
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getHoaById_7 operation in HoaApi.
 * @export
 * @interface HoaApiGetHoaById0Request
 */
export interface HoaApiGetHoaById0Request {
    /**
     * 
     * @type {number}
     * @memberof HoaApiGetHoaById0
     */
    readonly hoaId: number

    /**
     * 
     * @type {string}
     * @memberof HoaApiGetHoaById0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for updateHoa operation in HoaApi.
 * @export
 * @interface HoaApiUpdateHoaRequest
 */
export interface HoaApiUpdateHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiUpdateHoa
     */
    readonly hoaId: number

    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiUpdateHoa
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema

    /**
     * 
     * @type {string}
     * @memberof HoaApiUpdateHoa
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for updateHoa_8 operation in HoaApi.
 * @export
 * @interface HoaApiUpdateHoa0Request
 */
export interface HoaApiUpdateHoa0Request {
    /**
     * 
     * @type {number}
     * @memberof HoaApiUpdateHoa0
     */
    readonly hoaId: number

    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiUpdateHoa0
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema

    /**
     * 
     * @type {string}
     * @memberof HoaApiUpdateHoa0
     */
    readonly tokenPayload?: string | null
}

/**
 * HoaApi - object-oriented interface
 * @export
 * @class HoaApi
 * @extends {BaseAPI}
 */
export class HoaApi extends BaseAPI {
    /**
     * Create a new HOA.
     * @summary Create Hoa
     * @param {HoaApiCreateHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public createHoa(requestParameters: HoaApiCreateHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).createHoa(requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new HOA.
     * @summary Create Hoa For User
     * @param {HoaApiCreateHoaForUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public createHoaForUser(requestParameters: HoaApiCreateHoaForUserRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).createHoaForUser(requestParameters.userId, requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new HOA.
     * @summary Create Hoa For User
     * @param {HoaApiCreateHoaForUser0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public createHoaForUser_1(requestParameters: HoaApiCreateHoaForUser0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).createHoaForUser_1(requestParameters.userId, requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new HOA.
     * @summary Create Hoa
     * @param {HoaApiCreateHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public createHoa_2(requestParameters: HoaApiCreateHoa0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).createHoa_2(requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deactivate an HOA by its ID.
     * @summary Deactivate Hoa
     * @param {HoaApiDeactivateHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public deactivateHoa(requestParameters: HoaApiDeactivateHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).deactivateHoa(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deactivate an HOA by its ID.
     * @summary Deactivate Hoa
     * @param {HoaApiDeactivateHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public deactivateHoa_3(requestParameters: HoaApiDeactivateHoa0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).deactivateHoa_3(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Hoa
     * @param {HoaApiDeleteHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public deleteHoa(requestParameters: HoaApiDeleteHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).deleteHoa(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Hoa
     * @param {HoaApiDeleteHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public deleteHoa_4(requestParameters: HoaApiDeleteHoa0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).deleteHoa_4(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all active HOAs with their detailed address information.
     * @summary Get Active Hoas
     * @param {HoaApiGetAllActiveHoasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getAllActiveHoas(requestParameters: HoaApiGetAllActiveHoasRequest = {}, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getAllActiveHoas(requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all active HOAs with their detailed address information.
     * @summary Get Active Hoas
     * @param {HoaApiGetAllActiveHoas0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getAllActiveHoas_5(requestParameters: HoaApiGetAllActiveHoas0Request = {}, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getAllActiveHoas_5(requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all HOAs, including both active and inactive, with their detailed address information.
     * @summary Get All Hoas
     * @param {HoaApiGetAllHoasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getAllHoas(requestParameters: HoaApiGetAllHoasRequest = {}, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getAllHoas(requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all HOAs, including both active and inactive, with their detailed address information.
     * @summary Get All Hoas
     * @param {HoaApiGetAllHoas0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getAllHoas_6(requestParameters: HoaApiGetAllHoas0Request = {}, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getAllHoas_6(requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an HOA by its ID.
     * @summary Get Hoa
     * @param {HoaApiGetHoaByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getHoaById(requestParameters: HoaApiGetHoaByIdRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getHoaById(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an HOA by its ID.
     * @summary Get Hoa
     * @param {HoaApiGetHoaById0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getHoaById_7(requestParameters: HoaApiGetHoaById0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getHoaById_7(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Hoa
     * @param {HoaApiUpdateHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public updateHoa(requestParameters: HoaApiUpdateHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).updateHoa(requestParameters.hoaId, requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Hoa
     * @param {HoaApiUpdateHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public updateHoa_8(requestParameters: HoaApiUpdateHoa0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).updateHoa_8(requestParameters.hoaId, requestParameters.hoaCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Member To Home
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToHome: async (homeId: number, memberId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('addMemberToHome', 'homeId', homeId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('addMemberToHome', 'memberId', memberId)
            const localVarPath = `/homes/add/{home_id}/member/{member_id}`
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Member To Home
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToHome_1: async (homeId: number, memberId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('addMemberToHome_1', 'homeId', homeId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('addMemberToHome_1', 'memberId', memberId)
            const localVarPath = `/homes/add/{home_id}/member/{member_id}`
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Home
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHome: async (homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHome', 'homeCreateOrUpdateSchema', homeCreateOrUpdateSchema)
            const localVarPath = `/homes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(homeCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Home
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHome_2: async (homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHome_2', 'homeCreateOrUpdateSchema', homeCreateOrUpdateSchema)
            const localVarPath = `/homes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(homeCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHome: async (hoaId: number, homeId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteHome', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('deleteHome', 'homeId', homeId)
            const localVarPath = `/homes/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHome_3: async (hoaId: number, homeId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteHome_3', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('deleteHome_3', 'homeId', homeId)
            const localVarPath = `/homes/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeByHoaAndId: async (hoaId: number, homeId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHomeByHoaAndId', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('getHomeByHoaAndId', 'homeId', homeId)
            const localVarPath = `/homes/hoa/{hoa_id}/home/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeByHoaAndId_4: async (hoaId: number, homeId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHomeByHoaAndId_4', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('getHomeByHoaAndId_4', 'homeId', homeId)
            const localVarPath = `/homes/hoa/{hoa_id}/home/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByHoa: async (hoaId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHomesByHoa', 'hoaId', hoaId)
            const localVarPath = `/homes/hoa/{hoa_id}/homes`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByHoa_5: async (hoaId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHomesByHoa_5', 'hoaId', hoaId)
            const localVarPath = `/homes/hoa/{hoa_id}/homes`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByMember: async (memberId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getHomesByMember', 'memberId', memberId)
            const localVarPath = `/homes/member/{member_id}/homes`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByMember_6: async (memberId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getHomesByMember_6', 'memberId', memberId)
            const localVarPath = `/homes/member/{member_id}/homes`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthRoute: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/homes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthRoute_7: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/homes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Member From Home
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberFromHome: async (homeId: number, memberId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('removeMemberFromHome', 'homeId', homeId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('removeMemberFromHome', 'memberId', memberId)
            const localVarPath = `/homes/remove/{home_id}/member/{member_id}`
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Member From Home
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberFromHome_8: async (homeId: number, memberId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('removeMemberFromHome_8', 'homeId', homeId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('removeMemberFromHome_8', 'memberId', memberId)
            const localVarPath = `/homes/remove/{home_id}/member/{member_id}`
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHome: async (hoaId: number, homeId: number, homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('updateHome', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('updateHome', 'homeId', homeId)
            // verify required parameter 'homeCreateOrUpdateSchema' is not null or undefined
            assertParamExists('updateHome', 'homeCreateOrUpdateSchema', homeCreateOrUpdateSchema)
            const localVarPath = `/homes/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(homeCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHome_9: async (hoaId: number, homeId: number, homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('updateHome_9', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('updateHome_9', 'homeId', homeId)
            // verify required parameter 'homeCreateOrUpdateSchema' is not null or undefined
            assertParamExists('updateHome_9', 'homeCreateOrUpdateSchema', homeCreateOrUpdateSchema)
            const localVarPath = `/homes/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(homeCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HomeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Member To Home
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToHome(homeId: number, memberId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToHome(homeId, memberId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.addMemberToHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add Member To Home
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToHome_1(homeId: number, memberId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToHome_1(homeId, memberId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.addMemberToHome_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Home
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHome(homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHome(homeCreateOrUpdateSchema, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.createHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Home
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHome_2(homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHome_2(homeCreateOrUpdateSchema, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.createHome_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHome(hoaId: number, homeId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHome(hoaId, homeId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.deleteHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHome_3(hoaId: number, homeId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHome_3(hoaId, homeId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.deleteHome_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeByHoaAndId(hoaId: number, homeId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeByHoaAndId(hoaId, homeId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.getHomeByHoaAndId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeByHoaAndId_4(hoaId: number, homeId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeByHoaAndId_4(hoaId, homeId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.getHomeByHoaAndId_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomesByHoa(hoaId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HomeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomesByHoa(hoaId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.getHomesByHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} hoaId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomesByHoa_5(hoaId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HomeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomesByHoa_5(hoaId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.getHomesByHoa_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomesByMember(memberId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HomeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomesByMember(memberId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.getHomesByMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomesByMember_6(memberId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HomeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomesByMember_6(memberId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.getHomesByMember_6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Health Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthRoute(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthRoute(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.healthRoute']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Health Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthRoute_7(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthRoute_7(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.healthRoute_7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove Member From Home
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMemberFromHome(homeId: number, memberId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMemberFromHome(homeId, memberId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.removeMemberFromHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove Member From Home
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMemberFromHome_8(homeId: number, memberId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMemberFromHome_8(homeId, memberId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.removeMemberFromHome_8']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHome(hoaId: number, homeId: number, homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHome(hoaId, homeId, homeCreateOrUpdateSchema, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.updateHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHome_9(hoaId: number, homeId: number, homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHome_9(hoaId, homeId, homeCreateOrUpdateSchema, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.updateHome_9']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HomeApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Member To Home
         * @param {HomeApiAddMemberToHomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToHome(requestParameters: HomeApiAddMemberToHomeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.addMemberToHome(requestParameters.homeId, requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Member To Home
         * @param {HomeApiAddMemberToHome0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToHome_1(requestParameters: HomeApiAddMemberToHome0Request, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.addMemberToHome_1(requestParameters.homeId, requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Home
         * @param {HomeApiCreateHomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHome(requestParameters: HomeApiCreateHomeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.createHome(requestParameters.homeCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Home
         * @param {HomeApiCreateHome0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHome_2(requestParameters: HomeApiCreateHome0Request, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.createHome_2(requestParameters.homeCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Home
         * @param {HomeApiDeleteHomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHome(requestParameters: HomeApiDeleteHomeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.deleteHome(requestParameters.hoaId, requestParameters.homeId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Home
         * @param {HomeApiDeleteHome0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHome_3(requestParameters: HomeApiDeleteHome0Request, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.deleteHome_3(requestParameters.hoaId, requestParameters.homeId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Home
         * @param {HomeApiGetHomeByHoaAndIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeByHoaAndId(requestParameters: HomeApiGetHomeByHoaAndIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.getHomeByHoaAndId(requestParameters.hoaId, requestParameters.homeId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Home
         * @param {HomeApiGetHomeByHoaAndId0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeByHoaAndId_4(requestParameters: HomeApiGetHomeByHoaAndId0Request, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.getHomeByHoaAndId_4(requestParameters.hoaId, requestParameters.homeId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {HomeApiGetHomesByHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByHoa(requestParameters: HomeApiGetHomesByHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<HomeSchema>> {
            return localVarFp.getHomesByHoa(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {HomeApiGetHomesByHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByHoa_5(requestParameters: HomeApiGetHomesByHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<HomeSchema>> {
            return localVarFp.getHomesByHoa_5(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {HomeApiGetHomesByMemberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByMember(requestParameters: HomeApiGetHomesByMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<HomeSchema>> {
            return localVarFp.getHomesByMember(requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {HomeApiGetHomesByMember0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByMember_6(requestParameters: HomeApiGetHomesByMember0Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<HomeSchema>> {
            return localVarFp.getHomesByMember_6(requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthRoute(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.healthRoute(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthRoute_7(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.healthRoute_7(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Member From Home
         * @param {HomeApiRemoveMemberFromHomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberFromHome(requestParameters: HomeApiRemoveMemberFromHomeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.removeMemberFromHome(requestParameters.homeId, requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Member From Home
         * @param {HomeApiRemoveMemberFromHome0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberFromHome_8(requestParameters: HomeApiRemoveMemberFromHome0Request, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.removeMemberFromHome_8(requestParameters.homeId, requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Home
         * @param {HomeApiUpdateHomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHome(requestParameters: HomeApiUpdateHomeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.updateHome(requestParameters.hoaId, requestParameters.homeId, requestParameters.homeCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Home
         * @param {HomeApiUpdateHome0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHome_9(requestParameters: HomeApiUpdateHome0Request, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.updateHome_9(requestParameters.hoaId, requestParameters.homeId, requestParameters.homeCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addMemberToHome operation in HomeApi.
 * @export
 * @interface HomeApiAddMemberToHomeRequest
 */
export interface HomeApiAddMemberToHomeRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiAddMemberToHome
     */
    readonly homeId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiAddMemberToHome
     */
    readonly memberId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiAddMemberToHome
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for addMemberToHome_1 operation in HomeApi.
 * @export
 * @interface HomeApiAddMemberToHome0Request
 */
export interface HomeApiAddMemberToHome0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiAddMemberToHome0
     */
    readonly homeId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiAddMemberToHome0
     */
    readonly memberId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiAddMemberToHome0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for createHome operation in HomeApi.
 * @export
 * @interface HomeApiCreateHomeRequest
 */
export interface HomeApiCreateHomeRequest {
    /**
     * 
     * @type {HomeCreateOrUpdateSchema}
     * @memberof HomeApiCreateHome
     */
    readonly homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema

    /**
     * 
     * @type {string}
     * @memberof HomeApiCreateHome
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for createHome_2 operation in HomeApi.
 * @export
 * @interface HomeApiCreateHome0Request
 */
export interface HomeApiCreateHome0Request {
    /**
     * 
     * @type {HomeCreateOrUpdateSchema}
     * @memberof HomeApiCreateHome0
     */
    readonly homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema

    /**
     * 
     * @type {string}
     * @memberof HomeApiCreateHome0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for deleteHome operation in HomeApi.
 * @export
 * @interface HomeApiDeleteHomeRequest
 */
export interface HomeApiDeleteHomeRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiDeleteHome
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiDeleteHome
     */
    readonly homeId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiDeleteHome
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for deleteHome_3 operation in HomeApi.
 * @export
 * @interface HomeApiDeleteHome0Request
 */
export interface HomeApiDeleteHome0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiDeleteHome0
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiDeleteHome0
     */
    readonly homeId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiDeleteHome0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getHomeByHoaAndId operation in HomeApi.
 * @export
 * @interface HomeApiGetHomeByHoaAndIdRequest
 */
export interface HomeApiGetHomeByHoaAndIdRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomeByHoaAndId
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomeByHoaAndId
     */
    readonly homeId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiGetHomeByHoaAndId
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getHomeByHoaAndId_4 operation in HomeApi.
 * @export
 * @interface HomeApiGetHomeByHoaAndId0Request
 */
export interface HomeApiGetHomeByHoaAndId0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomeByHoaAndId0
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomeByHoaAndId0
     */
    readonly homeId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiGetHomeByHoaAndId0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getHomesByHoa operation in HomeApi.
 * @export
 * @interface HomeApiGetHomesByHoaRequest
 */
export interface HomeApiGetHomesByHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomesByHoa
     */
    readonly hoaId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiGetHomesByHoa
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getHomesByHoa_5 operation in HomeApi.
 * @export
 * @interface HomeApiGetHomesByHoa0Request
 */
export interface HomeApiGetHomesByHoa0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomesByHoa0
     */
    readonly hoaId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiGetHomesByHoa0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getHomesByMember operation in HomeApi.
 * @export
 * @interface HomeApiGetHomesByMemberRequest
 */
export interface HomeApiGetHomesByMemberRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomesByMember
     */
    readonly memberId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiGetHomesByMember
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getHomesByMember_6 operation in HomeApi.
 * @export
 * @interface HomeApiGetHomesByMember0Request
 */
export interface HomeApiGetHomesByMember0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomesByMember0
     */
    readonly memberId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiGetHomesByMember0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for removeMemberFromHome operation in HomeApi.
 * @export
 * @interface HomeApiRemoveMemberFromHomeRequest
 */
export interface HomeApiRemoveMemberFromHomeRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiRemoveMemberFromHome
     */
    readonly homeId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiRemoveMemberFromHome
     */
    readonly memberId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiRemoveMemberFromHome
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for removeMemberFromHome_8 operation in HomeApi.
 * @export
 * @interface HomeApiRemoveMemberFromHome0Request
 */
export interface HomeApiRemoveMemberFromHome0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiRemoveMemberFromHome0
     */
    readonly homeId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiRemoveMemberFromHome0
     */
    readonly memberId: number

    /**
     * 
     * @type {string}
     * @memberof HomeApiRemoveMemberFromHome0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for updateHome operation in HomeApi.
 * @export
 * @interface HomeApiUpdateHomeRequest
 */
export interface HomeApiUpdateHomeRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiUpdateHome
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiUpdateHome
     */
    readonly homeId: number

    /**
     * 
     * @type {HomeCreateOrUpdateSchema}
     * @memberof HomeApiUpdateHome
     */
    readonly homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema

    /**
     * 
     * @type {string}
     * @memberof HomeApiUpdateHome
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for updateHome_9 operation in HomeApi.
 * @export
 * @interface HomeApiUpdateHome0Request
 */
export interface HomeApiUpdateHome0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiUpdateHome0
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiUpdateHome0
     */
    readonly homeId: number

    /**
     * 
     * @type {HomeCreateOrUpdateSchema}
     * @memberof HomeApiUpdateHome0
     */
    readonly homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema

    /**
     * 
     * @type {string}
     * @memberof HomeApiUpdateHome0
     */
    readonly tokenPayload?: string | null
}

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI {
    /**
     * 
     * @summary Add Member To Home
     * @param {HomeApiAddMemberToHomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public addMemberToHome(requestParameters: HomeApiAddMemberToHomeRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).addMemberToHome(requestParameters.homeId, requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Member To Home
     * @param {HomeApiAddMemberToHome0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public addMemberToHome_1(requestParameters: HomeApiAddMemberToHome0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).addMemberToHome_1(requestParameters.homeId, requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Home
     * @param {HomeApiCreateHomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public createHome(requestParameters: HomeApiCreateHomeRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).createHome(requestParameters.homeCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Home
     * @param {HomeApiCreateHome0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public createHome_2(requestParameters: HomeApiCreateHome0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).createHome_2(requestParameters.homeCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Home
     * @param {HomeApiDeleteHomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public deleteHome(requestParameters: HomeApiDeleteHomeRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).deleteHome(requestParameters.hoaId, requestParameters.homeId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Home
     * @param {HomeApiDeleteHome0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public deleteHome_3(requestParameters: HomeApiDeleteHome0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).deleteHome_3(requestParameters.hoaId, requestParameters.homeId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Home
     * @param {HomeApiGetHomeByHoaAndIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomeByHoaAndId(requestParameters: HomeApiGetHomeByHoaAndIdRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomeByHoaAndId(requestParameters.hoaId, requestParameters.homeId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Home
     * @param {HomeApiGetHomeByHoaAndId0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomeByHoaAndId_4(requestParameters: HomeApiGetHomeByHoaAndId0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomeByHoaAndId_4(requestParameters.hoaId, requestParameters.homeId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Homes By Hoa
     * @param {HomeApiGetHomesByHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomesByHoa(requestParameters: HomeApiGetHomesByHoaRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomesByHoa(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Homes By Hoa
     * @param {HomeApiGetHomesByHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomesByHoa_5(requestParameters: HomeApiGetHomesByHoa0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomesByHoa_5(requestParameters.hoaId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Homes By Hoa
     * @param {HomeApiGetHomesByMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomesByMember(requestParameters: HomeApiGetHomesByMemberRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomesByMember(requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Homes By Hoa
     * @param {HomeApiGetHomesByMember0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomesByMember_6(requestParameters: HomeApiGetHomesByMember0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomesByMember_6(requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public healthRoute(options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).healthRoute(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public healthRoute_7(options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).healthRoute_7(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Member From Home
     * @param {HomeApiRemoveMemberFromHomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public removeMemberFromHome(requestParameters: HomeApiRemoveMemberFromHomeRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).removeMemberFromHome(requestParameters.homeId, requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Member From Home
     * @param {HomeApiRemoveMemberFromHome0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public removeMemberFromHome_8(requestParameters: HomeApiRemoveMemberFromHome0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).removeMemberFromHome_8(requestParameters.homeId, requestParameters.memberId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Home
     * @param {HomeApiUpdateHomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public updateHome(requestParameters: HomeApiUpdateHomeRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).updateHome(requestParameters.hoaId, requestParameters.homeId, requestParameters.homeCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Home
     * @param {HomeApiUpdateHome0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public updateHome_9(requestParameters: HomeApiUpdateHome0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).updateHome_9(requestParameters.hoaId, requestParameters.homeId, requestParameters.homeCreateOrUpdateSchema, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



