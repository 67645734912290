import React from 'react';
import { SidebarLayout } from './common/Catalyst/sidebar-layout';
import { Navbar } from './common/Catalyst/navbar';
import AppSidebar from './AppSidebar';
import { Heading, Subheading } from './common/Catalyst/heading';
import { Divider } from './common/Catalyst/divider';
import DashboardMetrics from './DashboardMetrics';
import HOAList from './HOAList';
import HoaListComponent from './hoa/HoaListComponent';

interface DashboardProps {
  currentPage: string;
}

const Dashboard: React.FC<DashboardProps> = ({ currentPage }) => {
  return (
    <SidebarLayout
      sidebar={<AppSidebar currentPage={currentPage} />}
      navbar={<Navbar>{/* Your navbar content */}</Navbar>}
    >
      {/* Your page content */}
      <Heading>Welcome to your Dashboard</Heading>
      <Subheading>More to come soon!</Subheading>

      <Divider className="mt-4 mb-6"></Divider>

      <DashboardMetrics></DashboardMetrics>

      <Divider className="mt-4 mb-6"></Divider>

      <HoaListComponent></HoaListComponent>
    </SidebarLayout>
  );
};

export default Dashboard;
