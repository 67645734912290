import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HoaCreateOrUpdateSchema, HoaSchema } from '../clients/hoa-api-client';
import { hoaService } from '../clients/ApiClients';
import LoadingSpinner from '../LoadingSpinner';
import { Heading } from '../common/Catalyst/heading';
import ShowAlert from '../ShowAlert';
import HoaTable from './HoaTable';
import {
  getCompanyIdFromToken,
  getUserTypeFromToken,
} from '../utils/TokenUtils';
import SearchBar from '../common/Custom/SearchBar';
import { Divider } from '../common/Catalyst/divider';
import { Button } from '../common/Catalyst/button';
import { PlusIcon } from '@heroicons/react/24/outline';
import AddHoaDialog from './AddHoaDialog';
import { UserType } from '../constants/UserTypes';

const HoaListComponent: React.FC = () => {
  const navigate = useNavigate();
  const [hoas, setHoas] = useState<HoaSchema[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const companyId = useMemo(() => getCompanyIdFromToken(), []);
  const userType = useMemo(() => getUserTypeFromToken(), []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await hoaService.getAllHoas();
        setHoas(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load HOAs. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredHoas = useMemo(() => {
    if (!searchTerm) return hoas;

    const searchLower = searchTerm.toLowerCase();
    return hoas.filter((hoa) => {
      const addressString = [
        hoa.street,
        hoa.street2,
        hoa.city,
        hoa.state,
        hoa.zip,
      ]
        .filter(Boolean)
        .join(' ')
        .toLowerCase();

      return (
        hoa.name.toLowerCase().includes(searchLower) ||
        addressString.includes(searchLower) ||
        (hoa.contact_email &&
          hoa.contact_email.toLowerCase().includes(searchLower)) ||
        (hoa.contact_phone && hoa.contact_phone.includes(searchTerm))
      );
    });
  }, [hoas, searchTerm]);

  const handleRowClick = (hoa: HoaSchema) => {
    navigate(`/hoa/${hoa.id}`);
  };

  const handleAddHoa = async (newHoa: HoaCreateOrUpdateSchema) => {
    try {
      const response = await hoaService.createHoa({
        hoaCreateOrUpdateSchema: newHoa,
      });
      setHoas((prevHoas) => [...prevHoas, response.data]);
      setError(null);
      setShowSuccessAlert(true);
    } catch (err) {
      console.error('Error creating HOA:', err);
      setError('Failed to create HOA. Please try again.');
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 mb-4">
        <Heading
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
          level={4}
        >
          HOA List
        </Heading>
        <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
          {userType === UserType.HOA_MANAGER_ADMIN && (
            <Button onClick={() => setIsDialogOpen(true)} color="sky">
              <PlusIcon className="h-5 w-5 mr-2" />
              Add HOA
            </Button>
          )}
          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search HOAs..."
          />
        </div>
      </div>

      {error && (
        <ShowAlert
          alertType="error"
          alertTitle="Error"
          alertMessage={error}
          isVisible={true}
          onClose={() => setError(null)}
          timeout={5000}
          isInline={true}
        />
      )}

      {showSuccessAlert && (
        <ShowAlert
          alertType="success"
          alertTitle="Success"
          alertMessage="HOA created successfully!"
          isVisible={showSuccessAlert}
          onClose={() => setShowSuccessAlert(false)}
          timeout={5000}
          isInline={true}
        />
      )}

      {filteredHoas.length === 0 ? (
        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
          {searchTerm ? 'No HOAs match your search' : 'No HOAs found'}
          <Divider className="mt-4 mb-6"></Divider>
        </div>
      ) : (
        <HoaTable hoas={filteredHoas} onRowClick={handleRowClick} />
      )}

      <AddHoaDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSave={handleAddHoa}
        userType={userType}
        companyId={companyId ?? 0}
      />
    </>
  );
};

export default HoaListComponent;
