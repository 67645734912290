import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateMemberSchema, MemberSchema } from '../clients/auth-api-client';
import { homeService, memberService } from '../clients/ApiClients';
import LoadingSpinner from '../LoadingSpinner';
import {
  getCompanyIdFromToken,
  getUserTypeFromToken,
} from '../utils/TokenUtils';
import { Heading } from '../common/Catalyst/heading';
import { Button } from '../common/Catalyst/button';
import { UserType } from '../constants/UserTypes';
import { PlusIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/outline';
import SearchBar from '../common/Custom/SearchBar';
import ShowAlert from '../ShowAlert';
import { Divider } from '../common/Catalyst/divider';
import MemberTable from './MemberTable';
import AddMemberDialog from './AddMemberDialog';
import { HomeSchema } from '../clients/hoa-api-client';
import AddMemberToHomeDialog from './AddMemberToHomeDialog';

interface MemberListComponentProps {
  hoaId?: number;
  home?: HomeSchema | null;
}

interface AlertState {
  type: 'success' | 'error';
  title: string;
  message: string;
  show: boolean;
}

const MemberListComponent: React.FC<MemberListComponentProps> = ({
  hoaId,
  home,
}) => {
  const navigate = useNavigate();
  const [members, setMembers] = useState<MemberSchema[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAddToHomeDialogOpen, setIsAddToHomeDialogOpen] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<Set<number>>(
    new Set()
  );
  const [alert, setAlert] = useState<AlertState>({
    type: 'success',
    title: '',
    message: '',
    show: false,
  });

  const companyId = useMemo(() => getCompanyIdFromToken(), []);
  const userType = useMemo(() => getUserTypeFromToken(), []);

  const hideAlert = () => {
    setAlert((prev) => ({ ...prev, show: false }));
  };

  const showAlert = (
    type: 'success' | 'error',
    title: string,
    message: string
  ) => {
    setAlert({
      type,
      title,
      message,
      show: true,
    });
  };

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        if (hoaId) {
          setLoading(true);
          const response = await memberService.getAllMembersForHOA({ hoaId });
          setMembers(response.data);
        } else if (home) {
          setLoading(true);
          const response = await memberService.getAllMembersForHome({
            homeId: home.id,
          });
          setMembers(response.data);
        }
      } catch (err) {
        console.error('Error fetching members:', err);
        const errorMessage =
          err instanceof Error
            ? err.message
            : 'An unexpected error occurred while fetching members.';

        showAlert('error', 'Failed to Load Members', errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [hoaId, home]);

  const filteredMembers = useMemo(() => {
    if (!searchTerm) return members;

    const searchLower = searchTerm.toLowerCase();
    return members.filter((member) => {
      return (
        member.first_name.toLowerCase().includes(searchLower) ||
        member.middle_name?.toLowerCase().includes(searchLower) ||
        member.last_name.toLowerCase().includes(searchLower) ||
        (member.phone && member.phone.includes(searchLower)) ||
        (member.email && member.email.includes(searchLower))
      );
    });
  }, [members, searchTerm]);

  const handleRowClick = (member: MemberSchema) => {
    navigate(`/member/${member.id}`);
  };

  const handleAddMember = async (newMember: CreateMemberSchema) => {
    try {
      const response = await memberService.createMember({
        createMemberSchema: newMember,
      });
      setMembers((prevMembers) => [...prevMembers, response.data]);
      showAlert('success', 'Success', 'Member created successfully!');
      setIsDialogOpen(false);
    } catch (err) {
      console.error('Error creating member:', err);
      const errorMessage =
        err instanceof Error
          ? err.message
          : 'An unexpected error occurred. Please try again.';

      showAlert('error', 'Failed to Create Member', errorMessage);
    }
  };

  const handleAddMemberToHome = async (data: {
    homeId: number;
    memberId: number;
  }) => {
    try {
      await memberService.addHomeToMember({
        homeId: data.homeId,
        memberId: data.memberId,
      });
      showAlert('success', 'Success', 'Member added to home successfully!');
      // Refresh the members list
      if (home) {
        const response = await memberService.getAllMembersForHome({
          homeId: home.id,
        });
        setMembers(response.data);
      }
    } catch (err) {
      console.error('Error adding member to home:', err);
      const errorMessage =
        err instanceof Error
          ? err.message
          : 'An unexpected error occurred. Please try again.';
      showAlert('error', 'Failed to Add Member to Home', errorMessage);
    }
  };

  const handleRemoveMembers = async () => {
    if (selectedMembers.size === 0 || !home) return;

    try {
      // Create an array of promises for each member removal
      const removalPromises = Array.from(selectedMembers).map((memberId) =>
        homeService.removeMemberFromHome({
          homeId: home.id,
          memberId: memberId,
        })
      );

      await Promise.all(removalPromises);

      // Refresh the members list
      const response = await memberService.getAllMembersForHome({
        homeId: home.id,
      });
      setMembers(response.data);
      setSelectedMembers(new Set()); // Clear selection
      showAlert(
        'success',
        'Success',
        'Members removed from home successfully!'
      );
    } catch (err) {
      console.error('Error removing members from home:', err);
      const errorMessage =
        err instanceof Error
          ? err.message
          : 'An unexpected error occurred. Please try again.';
      showAlert('error', 'Failed to Remove Members', errorMessage);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 mb-4">
        <Heading
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
          level={4}
        >
          Member List
        </Heading>
        <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
          {userType === UserType.HOA_MANAGER_ADMIN && hoaId && (
            <Button onClick={() => setIsDialogOpen(true)} color="sky">
              <PlusIcon className="h-5 w-5 mr-2" />
              New Member
            </Button>
          )}
          {userType === UserType.HOA_MANAGER_ADMIN && home && (
            <>
              <Button
                onClick={() => setIsAddToHomeDialogOpen(true)}
                color="sky"
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                Add Member
              </Button>

              <Button
                disabled={selectedMembers.size < 1}
                onClick={handleRemoveMembers}
                color="red"
              >
                <TrashIcon className="h-5 w-5 mr-2" />
                Remove Selected
              </Button>
            </>
          )}
          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search Members..."
          />
        </div>
      </div>

      <ShowAlert
        alertType={alert.type}
        alertTitle={alert.title}
        alertMessage={alert.message}
        isVisible={alert.show}
        onClose={hideAlert}
        timeout={5000}
        isInline={true}
      />

      {filteredMembers.length === 0 ? (
        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
          {searchTerm ? 'No members match your search' : 'No members found'}
          <Divider className="mt-4 mb-6" />
        </div>
      ) : (
        <MemberTable
          members={filteredMembers}
          onRowClick={handleRowClick}
          selectionEnabled={!!home}
          selectedMembers={selectedMembers}
          onSelectionChange={setSelectedMembers}
        />
      )}

      {hoaId && (
        <AddMemberDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSave={handleAddMember}
          userType={userType}
          companyId={companyId ?? 0}
        />
      )}

      {home && (
        <AddMemberToHomeDialog
          isOpen={isAddToHomeDialogOpen}
          home={home}
          companyId={companyId ?? 0}
          onSave={handleAddMemberToHome}
          onClose={() => setIsAddToHomeDialogOpen(false)}
        />
      )}
    </>
  );
};

export default MemberListComponent;
