import React from 'react';
import { HomeSchema, MemberSchema } from '../clients/hoa-api-client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../common/Catalyst/table';
import { Checkbox } from '../common/Catalyst/checkbox';

interface HomeTableProps {
  homes: HomeSchema[];
  onRowClick: (home: HomeSchema) => void;
  selectionEnabled?: boolean;
  selectedHomes?: Set<number>;
  onSelectionChange?: (selectedIds: Set<number>) => void;
}

export const HomeTable: React.FC<HomeTableProps> = ({
  homes,
  onRowClick,
  selectionEnabled = false,
  selectedHomes = new Set(),
  onSelectionChange,
}) => {
  const handleCheckboxClick = (checked: boolean, homeId: number) => {
    if (!onSelectionChange) return;

    const newSelected = new Set(selectedHomes);
    if (!checked) {
      newSelected.delete(homeId);
    } else {
      newSelected.add(homeId);
    }
    onSelectionChange(newSelected);
  };

  const handleSelectAll = (checked: boolean) => {
    if (!onSelectionChange) return;

    if (checked) {
      const allIds = new Set(homes.map((home) => home.id));
      onSelectionChange(allIds);
    } else {
      onSelectionChange(new Set());
    }
  };

  const allSelected =
    homes.length > 0 && homes.every((home) => selectedHomes.has(home.id));

  const someSelected = homes.some((home) => selectedHomes.has(home.id));

  const getFullAddress = (home: HomeSchema): string => {
    const parts = [
      home.street,
      home.street2,
      home.city,
      home.state,
      home.zip,
      home.country,
    ].filter(Boolean);
    return parts.join(', ');
  };

  const getMemberDisplayName = (member: MemberSchema): string => {
    const preferredName = member.preferred_name || member.first_name;
    const middleInitial = member.middle_name
      ? ` ${member.middle_name.charAt(0)}.`
      : '';
    return `${preferredName}${middleInitial} ${member.last_name}${member.is_primary ? ' (Primary)' : ''}`;
  };

  const formatMembers = (members?: MemberSchema[]): string => {
    if (!members || members.length === 0) return 'No members';

    // Sort members to show primary member first
    const sortedMembers = [...members].sort((a, b) => {
      if (a.is_primary && !b.is_primary) return -1;
      if (!a.is_primary && b.is_primary) return 1;
      return 0;
    });

    return sortedMembers
      .map((member) => getMemberDisplayName(member))
      .join(', ');
  };

  return (
    <div className="bg-stone-100 dark:bg-gray-600 rounded-lg shadow transition-colors duration-200 overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div className="max-h-[500px] overflow-y-auto">
                <Table>
                  <TableHead className="sticky top-0 z-10 bg-stone-100 dark:bg-gray-600">
                    <TableRow>
                      {selectionEnabled && (
                        <TableHeader className="w-12">
                          <Checkbox
                            checked={allSelected}
                            indeterminate={!allSelected && someSelected}
                            onChange={handleSelectAll}
                            color="sky"
                          />
                        </TableHeader>
                      )}
                      <TableHeader className="text-base font-semibold">
                        Address
                      </TableHeader>
                      <TableHeader className="text-base font-semibold">
                        Property Type
                      </TableHeader>
                      <TableHeader className="text-base font-semibold">
                        Status
                      </TableHeader>
                      <TableHeader className="text-base font-semibold">
                        Members
                      </TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {homes.map((home) => (
                      <TableRow
                        key={home.id}
                        onClick={() => onRowClick(home)}
                        className="cursor-pointer hover:bg-stone-200 dark:hover:bg-zinc-700 transition-colors duration-150"
                      >
                        {selectionEnabled && (
                          <TableCell
                            className="w-12"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              checked={selectedHomes.has(home.id)}
                              onChange={(checked) =>
                                handleCheckboxClick(checked, home.id)
                              }
                              color="sky"
                            />
                          </TableCell>
                        )}
                        <TableCell>{getFullAddress(home)}</TableCell>
                        <TableCell>{home.property_type}</TableCell>
                        <TableCell>{home.status}</TableCell>
                        <TableCell>{formatMembers(home.members)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTable;
