import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';

type AlertType = 'error' | 'success';

interface ShowAlertProps {
  alertType: AlertType;
  alertTitle: string;
  alertMessage: string;
  isVisible: boolean;
  onClose: () => void;
  timeout?: number;
  isInline?: boolean;
}

const ShowAlert: React.FC<ShowAlertProps> = ({
  alertType = 'error',
  alertTitle,
  alertMessage,
  isVisible,
  onClose,
  timeout = 5000,
  isInline = false,
}) => {
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isVisible) {
      timeoutId = setTimeout(() => {
        onClose();
      }, timeout);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isVisible, timeout, onClose]);

  if (!isVisible) return null;

  const containerClasses = isInline
    ? 'w-full animate-in fade-in duration-200'
    : 'fixed top-4 right-4 z-50 animate-in fade-in slide-in-from-top-2';

  const getAlertStyles = () => {
    switch (alertType) {
      case 'success':
        return {
          background: 'bg-green-200',
          icon: (
            <CheckCircleIcon
              aria-hidden="true"
              className="size-5 text-green-600"
            />
          ),
          titleColor: 'text-green-800',
          messageColor: 'text-green-700',
        };
      case 'error':
      default:
        return {
          background: 'bg-red-200',
          icon: (
            <XCircleIcon aria-hidden="true" className="size-5 text-red-600" />
          ),
          titleColor: 'text-red-800',
          messageColor: 'text-red-700',
        };
    }
  };

  const styles = getAlertStyles();

  return (
    <div className={`${containerClasses} mt-2 mb-2`}>
      <div className={`rounded-md ${styles.background} p-4`}>
        <div className="flex">
          <div className="shrink-0">{styles.icon}</div>
          <div className="ml-3">
            <h3 className={`text-sm font-medium ${styles.titleColor}`}>
              {alertTitle}
            </h3>
            <div className={`mt-2 text-sm ${styles.messageColor}`}>
              <p>{alertMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowAlert;
