/* tslint:disable */
/* eslint-disable */
/**
 * Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface BodyGoogleLogin
 */
export interface BodyGoogleLogin {
    /**
     * 
     * @type {string}
     * @memberof BodyGoogleLogin
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface BodyMemberGoogleLogin
 */
export interface BodyMemberGoogleLogin {
    /**
     * 
     * @type {string}
     * @memberof BodyMemberGoogleLogin
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface BodyMemberRefreshToken
 */
export interface BodyMemberRefreshToken {
    /**
     * 
     * @type {string}
     * @memberof BodyMemberRefreshToken
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface BodyRefreshToken
 */
export interface BodyRefreshToken {
    /**
     * 
     * @type {string}
     * @memberof BodyRefreshToken
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface BodyVerifyToken
 */
export interface BodyVerifyToken {
    /**
     * 
     * @type {string}
     * @memberof BodyVerifyToken
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'website'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'is_active': boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'date_updated'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyCreate
 */
export interface CompanyCreate {
    /**
     * 
     * @type {string}
     * @memberof CompanyCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreate
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreate
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreate
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreate
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreate
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreate
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreate
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreate
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCreate
     */
    'website'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyUpdate
 */
export interface CompanyUpdate {
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdate
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdate
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdate
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdate
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdate
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdate
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdate
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdate
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdate
     */
    'website'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateMemberSchema
 */
export interface CreateMemberSchema {
    /**
     * 
     * @type {string}
     * @memberof CreateMemberSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMemberSchema
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMemberSchema
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMemberSchema
     */
    'middle_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMemberSchema
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMemberSchema
     */
    'preferred_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateMemberSchema
     */
    'company_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMemberSchema
     */
    'is_primary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateMemberSchema
     */
    'user_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMemberSchema
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CreateUserSchema
 */
export interface CreateUserSchema {
    /**
     * 
     * @type {string}
     * @memberof CreateUserSchema
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserSchema
     */
    'company_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserSchema
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserSchema
     */
    'user_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserSchema
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface MemberPasswordResetRequestSchema
 */
export interface MemberPasswordResetRequestSchema {
    /**
     * 
     * @type {string}
     * @memberof MemberPasswordResetRequestSchema
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface MemberPasswordResetSchema
 */
export interface MemberPasswordResetSchema {
    /**
     * 
     * @type {string}
     * @memberof MemberPasswordResetSchema
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof MemberPasswordResetSchema
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface MemberPasswordUpdate
 */
export interface MemberPasswordUpdate {
    /**
     * 
     * @type {string}
     * @memberof MemberPasswordUpdate
     */
    'current_password': string;
    /**
     * 
     * @type {string}
     * @memberof MemberPasswordUpdate
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface MemberSchema
 */
export interface MemberSchema {
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'middle_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'preferred_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MemberSchema
     */
    'company_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof MemberSchema
     */
    'is_primary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'user_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberSchema
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof MemberSchema
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof MemberSchema
     */
    'date_updated'?: string | null;
}
/**
 * 
 * @export
 * @interface PasswordResetRequestSchema
 */
export interface PasswordResetRequestSchema {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequestSchema
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PasswordResetSchema
 */
export interface PasswordResetSchema {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetSchema
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetSchema
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface PasswordUpdate
 */
export interface PasswordUpdate {
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdate
     */
    'current_password': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdate
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface UpdateMemberSchema
 */
export interface UpdateMemberSchema {
    /**
     * 
     * @type {string}
     * @memberof UpdateMemberSchema
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMemberSchema
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMemberSchema
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMemberSchema
     */
    'middle_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMemberSchema
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMemberSchema
     */
    'preferred_name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMemberSchema
     */
    'is_primary'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMemberSchema
     */
    'current_password'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserSchema
 */
export interface UpdateUserSchema {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserSchema
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserSchema
     */
    'full_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserSchema
     */
    'current_password'?: string | null;
}
/**
 * 
 * @export
 * @interface UpgradeUserTypeSchema
 */
export interface UpgradeUserTypeSchema {
    /**
     * 
     * @type {string}
     * @memberof UpgradeUserTypeSchema
     */
    'userType': string;
}
/**
 * 
 * @export
 * @interface UserSchema
 */
export interface UserSchema {
    /**
     * 
     * @type {string}
     * @memberof UserSchema
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof UserSchema
     */
    'company_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserSchema
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserSchema
     */
    'user_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserSchema
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserSchema
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * APIApi - axios parameter creator
 * @export
 */
export const APIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * File service upload pass through, verify the token, upload the file, and return the response.  :param file: File to upload :param path: Path to request :param token: JWT token
         * @summary Get File Svc
         * @param {string} path 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filePost: async (path: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('filePost', 'path', path)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('filePost', 'file', file)
            const localVarPath = `/api/file/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyDelete: async (svc: string, path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'svc' is not null or undefined
            assertParamExists('proxyDelete', 'svc', svc)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('proxyDelete', 'path', path)
            const localVarPath = `/api/{svc}/{path}`
                .replace(`{${"svc"}}`, encodeURIComponent(String(svc)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyGet: async (svc: string, path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'svc' is not null or undefined
            assertParamExists('proxyGet', 'svc', svc)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('proxyGet', 'path', path)
            const localVarPath = `/api/{svc}/{path}`
                .replace(`{${"svc"}}`, encodeURIComponent(String(svc)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyOptions: async (svc: string, path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'svc' is not null or undefined
            assertParamExists('proxyOptions', 'svc', svc)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('proxyOptions', 'path', path)
            const localVarPath = `/api/{svc}/{path}`
                .replace(`{${"svc"}}`, encodeURIComponent(String(svc)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyPatch: async (svc: string, path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'svc' is not null or undefined
            assertParamExists('proxyPatch', 'svc', svc)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('proxyPatch', 'path', path)
            const localVarPath = `/api/{svc}/{path}`
                .replace(`{${"svc"}}`, encodeURIComponent(String(svc)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyPost: async (svc: string, path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'svc' is not null or undefined
            assertParamExists('proxyPost', 'svc', svc)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('proxyPost', 'path', path)
            const localVarPath = `/api/{svc}/{path}`
                .replace(`{${"svc"}}`, encodeURIComponent(String(svc)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyPut: async (svc: string, path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'svc' is not null or undefined
            assertParamExists('proxyPut', 'svc', svc)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('proxyPut', 'path', path)
            const localVarPath = `/api/{svc}/{path}`
                .replace(`{${"svc"}}`, encodeURIComponent(String(svc)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * APIApi - functional programming interface
 * @export
 */
export const APIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = APIApiAxiosParamCreator(configuration)
    return {
        /**
         * File service upload pass through, verify the token, upload the file, and return the response.  :param file: File to upload :param path: Path to request :param token: JWT token
         * @summary Get File Svc
         * @param {string} path 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filePost(path: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filePost(path, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.filePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyDelete(svc: string, path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyDelete(svc, path, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.proxyDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyGet(svc: string, path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyGet(svc, path, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.proxyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyOptions(svc: string, path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyOptions(svc, path, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.proxyOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyPatch(svc: string, path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyPatch(svc, path, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.proxyPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyPost(svc: string, path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyPost(svc, path, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.proxyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyPut(svc: string, path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyPut(svc, path, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['APIApi.proxyPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * APIApi - factory interface
 * @export
 */
export const APIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = APIApiFp(configuration)
    return {
        /**
         * File service upload pass through, verify the token, upload the file, and return the response.  :param file: File to upload :param path: Path to request :param token: JWT token
         * @summary Get File Svc
         * @param {APIApiFilePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filePost(requestParameters: APIApiFilePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.filePost(requestParameters.path, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {APIApiProxyDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyDelete(requestParameters: APIApiProxyDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyDelete(requestParameters.svc, requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {APIApiProxyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyGet(requestParameters: APIApiProxyGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyGet(requestParameters.svc, requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {APIApiProxyOptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyOptions(requestParameters: APIApiProxyOptionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyOptions(requestParameters.svc, requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {APIApiProxyPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyPatch(requestParameters: APIApiProxyPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyPatch(requestParameters.svc, requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {APIApiProxyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyPost(requestParameters: APIApiProxyPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyPost(requestParameters.svc, requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {APIApiProxyPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyPut(requestParameters: APIApiProxyPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.proxyPut(requestParameters.svc, requestParameters.path, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for filePost operation in APIApi.
 * @export
 * @interface APIApiFilePostRequest
 */
export interface APIApiFilePostRequest {
    /**
     * 
     * @type {string}
     * @memberof APIApiFilePost
     */
    readonly path: string

    /**
     * 
     * @type {File}
     * @memberof APIApiFilePost
     */
    readonly file: File
}

/**
 * Request parameters for proxyDelete operation in APIApi.
 * @export
 * @interface APIApiProxyDeleteRequest
 */
export interface APIApiProxyDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof APIApiProxyDelete
     */
    readonly svc: string

    /**
     * 
     * @type {string}
     * @memberof APIApiProxyDelete
     */
    readonly path: string
}

/**
 * Request parameters for proxyGet operation in APIApi.
 * @export
 * @interface APIApiProxyGetRequest
 */
export interface APIApiProxyGetRequest {
    /**
     * 
     * @type {string}
     * @memberof APIApiProxyGet
     */
    readonly svc: string

    /**
     * 
     * @type {string}
     * @memberof APIApiProxyGet
     */
    readonly path: string
}

/**
 * Request parameters for proxyOptions operation in APIApi.
 * @export
 * @interface APIApiProxyOptionsRequest
 */
export interface APIApiProxyOptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof APIApiProxyOptions
     */
    readonly svc: string

    /**
     * 
     * @type {string}
     * @memberof APIApiProxyOptions
     */
    readonly path: string
}

/**
 * Request parameters for proxyPatch operation in APIApi.
 * @export
 * @interface APIApiProxyPatchRequest
 */
export interface APIApiProxyPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof APIApiProxyPatch
     */
    readonly svc: string

    /**
     * 
     * @type {string}
     * @memberof APIApiProxyPatch
     */
    readonly path: string
}

/**
 * Request parameters for proxyPost operation in APIApi.
 * @export
 * @interface APIApiProxyPostRequest
 */
export interface APIApiProxyPostRequest {
    /**
     * 
     * @type {string}
     * @memberof APIApiProxyPost
     */
    readonly svc: string

    /**
     * 
     * @type {string}
     * @memberof APIApiProxyPost
     */
    readonly path: string
}

/**
 * Request parameters for proxyPut operation in APIApi.
 * @export
 * @interface APIApiProxyPutRequest
 */
export interface APIApiProxyPutRequest {
    /**
     * 
     * @type {string}
     * @memberof APIApiProxyPut
     */
    readonly svc: string

    /**
     * 
     * @type {string}
     * @memberof APIApiProxyPut
     */
    readonly path: string
}

/**
 * APIApi - object-oriented interface
 * @export
 * @class APIApi
 * @extends {BaseAPI}
 */
export class APIApi extends BaseAPI {
    /**
     * File service upload pass through, verify the token, upload the file, and return the response.  :param file: File to upload :param path: Path to request :param token: JWT token
     * @summary Get File Svc
     * @param {APIApiFilePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public filePost(requestParameters: APIApiFilePostRequest, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).filePost(requestParameters.path, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
     * @summary Get Svc
     * @param {APIApiProxyDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public proxyDelete(requestParameters: APIApiProxyDeleteRequest, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).proxyDelete(requestParameters.svc, requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
     * @summary Get Svc
     * @param {APIApiProxyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public proxyGet(requestParameters: APIApiProxyGetRequest, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).proxyGet(requestParameters.svc, requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
     * @summary Get Svc
     * @param {APIApiProxyOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public proxyOptions(requestParameters: APIApiProxyOptionsRequest, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).proxyOptions(requestParameters.svc, requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
     * @summary Get Svc
     * @param {APIApiProxyPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public proxyPatch(requestParameters: APIApiProxyPatchRequest, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).proxyPatch(requestParameters.svc, requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
     * @summary Get Svc
     * @param {APIApiProxyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public proxyPost(requestParameters: APIApiProxyPostRequest, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).proxyPost(requestParameters.svc, requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
     * @summary Get Svc
     * @param {APIApiProxyPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public proxyPut(requestParameters: APIApiProxyPutRequest, options?: RawAxiosRequestConfig) {
        return APIApiFp(this.configuration).proxyPut(requestParameters.svc, requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handle Google OAuth login.
         * @summary Google Login
         * @param {BodyGoogleLogin} bodyGoogleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogin: async (bodyGoogleLogin: BodyGoogleLogin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyGoogleLogin' is not null or undefined
            assertParamExists('googleLogin', 'bodyGoogleLogin', bodyGoogleLogin)
            const localVarPath = `/google-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyGoogleLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes user\'s authentication and returns a token on successful authentication.  request body:  - username: Unique identifier for a user e.g email,             phone number, name  - password:
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('login', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('login', 'password', password)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Password Reset Request
         * @param {PasswordResetRequestSchema} passwordResetRequestSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetRequest: async (passwordResetRequestSchema: PasswordResetRequestSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetRequestSchema' is not null or undefined
            assertParamExists('passwordResetRequest', 'passwordResetRequestSchema', passwordResetRequestSchema)
            const localVarPath = `/password-reset-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequestSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh Token
         * @param {BodyRefreshToken} bodyRefreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (bodyRefreshToken: BodyRefreshToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyRefreshToken' is not null or undefined
            assertParamExists('refreshToken', 'bodyRefreshToken', bodyRefreshToken)
            const localVarPath = `/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyRefreshToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Password
         * @param {PasswordResetSchema} passwordResetSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (passwordResetSchema: PasswordResetSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetSchema' is not null or undefined
            assertParamExists('resetPassword', 'passwordResetSchema', passwordResetSchema)
            const localVarPath = `/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes request to register user account.
         * @summary Signup
         * @param {CreateUserSchema} createUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (createUserSchema: CreateUserSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserSchema' is not null or undefined
            assertParamExists('signup', 'createUserSchema', createUserSchema)
            const localVarPath = `/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testConnections: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test-connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upgrade user type from ANON to USER
         * @summary Upgrade User
         * @param {number} id 
         * @param {UpgradeUserTypeSchema} upgradeUserTypeSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeUser: async (id: number, upgradeUserTypeSchema: UpgradeUserTypeSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('upgradeUser', 'id', id)
            // verify required parameter 'upgradeUserTypeSchema' is not null or undefined
            assertParamExists('upgradeUser', 'upgradeUserTypeSchema', upgradeUserTypeSchema)
            const localVarPath = `/users/upgrade/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upgradeUserTypeSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifies the validity of a token.  request body: - token: JWT token to verify
         * @summary Verify Token
         * @param {BodyVerifyToken} bodyVerifyToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken: async (bodyVerifyToken: BodyVerifyToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyVerifyToken' is not null or undefined
            assertParamExists('verifyToken', 'bodyVerifyToken', bodyVerifyToken)
            const localVarPath = `/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyVerifyToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRootInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRootInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getRootInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Handle Google OAuth login.
         * @summary Google Login
         * @param {BodyGoogleLogin} bodyGoogleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleLogin(bodyGoogleLogin: BodyGoogleLogin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleLogin(bodyGoogleLogin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.googleLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Processes user\'s authentication and returns a token on successful authentication.  request body:  - username: Unique identifier for a user e.g email,             phone number, name  - password:
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(username, password, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Password Reset Request
         * @param {PasswordResetRequestSchema} passwordResetRequestSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetRequest(passwordResetRequestSchema: PasswordResetRequestSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetRequest(passwordResetRequestSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.passwordResetRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh Token
         * @param {BodyRefreshToken} bodyRefreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(bodyRefreshToken: BodyRefreshToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(bodyRefreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.refreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Password
         * @param {PasswordResetSchema} passwordResetSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(passwordResetSchema: PasswordResetSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(passwordResetSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootLivenessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootLivenessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.rootLivenessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootReadinessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootReadinessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.rootReadinessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Processes request to register user account.
         * @summary Signup
         * @param {CreateUserSchema} createUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(createUserSchema: CreateUserSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(createUserSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.signup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Test Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testConnections(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testConnections(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.testConnections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upgrade user type from ANON to USER
         * @summary Upgrade User
         * @param {number} id 
         * @param {UpgradeUserTypeSchema} upgradeUserTypeSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upgradeUser(id: number, upgradeUserTypeSchema: UpgradeUserTypeSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upgradeUser(id, upgradeUserTypeSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.upgradeUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Verifies the validity of a token.  request body: - token: JWT token to verify
         * @summary Verify Token
         * @param {BodyVerifyToken} bodyVerifyToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyToken(bodyVerifyToken: BodyVerifyToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyToken(bodyVerifyToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.verifyToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootInfo(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getRootInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Handle Google OAuth login.
         * @summary Google Login
         * @param {AuthApiGoogleLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogin(requestParameters: AuthApiGoogleLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.googleLogin(requestParameters.bodyGoogleLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes user\'s authentication and returns a token on successful authentication.  request body:  - username: Unique identifier for a user e.g email,             phone number, name  - password:
         * @summary Login
         * @param {AuthApiLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParameters: AuthApiLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.login(requestParameters.username, requestParameters.password, requestParameters.grantType, requestParameters.scope, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Password Reset Request
         * @param {AuthApiPasswordResetRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetRequest(requestParameters: AuthApiPasswordResetRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.passwordResetRequest(requestParameters.passwordResetRequestSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh Token
         * @param {AuthApiRefreshTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(requestParameters: AuthApiRefreshTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.refreshToken(requestParameters.bodyRefreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Password
         * @param {AuthApiResetPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(requestParameters: AuthApiResetPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.resetPassword(requestParameters.passwordResetSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootLivenessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootReadinessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Processes request to register user account.
         * @summary Signup
         * @param {AuthApiSignupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(requestParameters: AuthApiSignupRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.signup(requestParameters.createUserSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testConnections(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.testConnections(options).then((request) => request(axios, basePath));
        },
        /**
         * Upgrade user type from ANON to USER
         * @summary Upgrade User
         * @param {AuthApiUpgradeUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeUser(requestParameters: AuthApiUpgradeUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.upgradeUser(requestParameters.id, requestParameters.upgradeUserTypeSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifies the validity of a token.  request body: - token: JWT token to verify
         * @summary Verify Token
         * @param {AuthApiVerifyTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken(requestParameters: AuthApiVerifyTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.verifyToken(requestParameters.bodyVerifyToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for googleLogin operation in AuthApi.
 * @export
 * @interface AuthApiGoogleLoginRequest
 */
export interface AuthApiGoogleLoginRequest {
    /**
     * 
     * @type {BodyGoogleLogin}
     * @memberof AuthApiGoogleLogin
     */
    readonly bodyGoogleLogin: BodyGoogleLogin
}

/**
 * Request parameters for login operation in AuthApi.
 * @export
 * @interface AuthApiLoginRequest
 */
export interface AuthApiLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly username: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly password: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly grantType?: string | null

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly scope?: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly clientId?: string | null

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly clientSecret?: string | null
}

/**
 * Request parameters for passwordResetRequest operation in AuthApi.
 * @export
 * @interface AuthApiPasswordResetRequestRequest
 */
export interface AuthApiPasswordResetRequestRequest {
    /**
     * 
     * @type {PasswordResetRequestSchema}
     * @memberof AuthApiPasswordResetRequest
     */
    readonly passwordResetRequestSchema: PasswordResetRequestSchema
}

/**
 * Request parameters for refreshToken operation in AuthApi.
 * @export
 * @interface AuthApiRefreshTokenRequest
 */
export interface AuthApiRefreshTokenRequest {
    /**
     * 
     * @type {BodyRefreshToken}
     * @memberof AuthApiRefreshToken
     */
    readonly bodyRefreshToken: BodyRefreshToken
}

/**
 * Request parameters for resetPassword operation in AuthApi.
 * @export
 * @interface AuthApiResetPasswordRequest
 */
export interface AuthApiResetPasswordRequest {
    /**
     * 
     * @type {PasswordResetSchema}
     * @memberof AuthApiResetPassword
     */
    readonly passwordResetSchema: PasswordResetSchema
}

/**
 * Request parameters for signup operation in AuthApi.
 * @export
 * @interface AuthApiSignupRequest
 */
export interface AuthApiSignupRequest {
    /**
     * 
     * @type {CreateUserSchema}
     * @memberof AuthApiSignup
     */
    readonly createUserSchema: CreateUserSchema
}

/**
 * Request parameters for upgradeUser operation in AuthApi.
 * @export
 * @interface AuthApiUpgradeUserRequest
 */
export interface AuthApiUpgradeUserRequest {
    /**
     * 
     * @type {number}
     * @memberof AuthApiUpgradeUser
     */
    readonly id: number

    /**
     * 
     * @type {UpgradeUserTypeSchema}
     * @memberof AuthApiUpgradeUser
     */
    readonly upgradeUserTypeSchema: UpgradeUserTypeSchema
}

/**
 * Request parameters for verifyToken operation in AuthApi.
 * @export
 * @interface AuthApiVerifyTokenRequest
 */
export interface AuthApiVerifyTokenRequest {
    /**
     * 
     * @type {BodyVerifyToken}
     * @memberof AuthApiVerifyToken
     */
    readonly bodyVerifyToken: BodyVerifyToken
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getRootInfo(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getRootInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handle Google OAuth login.
     * @summary Google Login
     * @param {AuthApiGoogleLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public googleLogin(requestParameters: AuthApiGoogleLoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).googleLogin(requestParameters.bodyGoogleLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes user\'s authentication and returns a token on successful authentication.  request body:  - username: Unique identifier for a user e.g email,             phone number, name  - password:
     * @summary Login
     * @param {AuthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(requestParameters: AuthApiLoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(requestParameters.username, requestParameters.password, requestParameters.grantType, requestParameters.scope, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Password Reset Request
     * @param {AuthApiPasswordResetRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public passwordResetRequest(requestParameters: AuthApiPasswordResetRequestRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).passwordResetRequest(requestParameters.passwordResetRequestSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh Token
     * @param {AuthApiRefreshTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshToken(requestParameters: AuthApiRefreshTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshToken(requestParameters.bodyRefreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Password
     * @param {AuthApiResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPassword(requestParameters: AuthApiResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).resetPassword(requestParameters.passwordResetSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public rootLivenessGet(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).rootLivenessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public rootReadinessGet(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).rootReadinessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes request to register user account.
     * @summary Signup
     * @param {AuthApiSignupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signup(requestParameters: AuthApiSignupRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).signup(requestParameters.createUserSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public testConnections(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).testConnections(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upgrade user type from ANON to USER
     * @summary Upgrade User
     * @param {AuthApiUpgradeUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public upgradeUser(requestParameters: AuthApiUpgradeUserRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).upgradeUser(requestParameters.id, requestParameters.upgradeUserTypeSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifies the validity of a token.  request body: - token: JWT token to verify
     * @summary Verify Token
     * @param {AuthApiVerifyTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyToken(requestParameters: AuthApiVerifyTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).verifyToken(requestParameters.bodyVerifyToken, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Company
         * @param {CompanyCreate} companyCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (companyCreate: CompanyCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyCreate' is not null or undefined
            assertParamExists('createCompany', 'companyCreate', companyCreate)
            const localVarPath = `/company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Company
         * @param {CompanyCreate} companyCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany_1: async (companyCreate: CompanyCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyCreate' is not null or undefined
            assertParamExists('createCompany_1', 'companyCreate', companyCreate)
            const localVarPath = `/company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateCompany: async (companyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deactivateCompany', 'companyId', companyId)
            const localVarPath = `/company/{company_id}/deactivate`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateCompany_2: async (companyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deactivateCompany_2', 'companyId', companyId)
            const localVarPath = `/company/{company_id}/deactivate`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies_3: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById: async (companyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyById', 'companyId', companyId)
            const localVarPath = `/company/{company_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById_4: async (companyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyById_4', 'companyId', companyId)
            const localVarPath = `/company/{company_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Company
         * @param {number} companyId 
         * @param {CompanyUpdate} companyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (companyId: number, companyUpdate: CompanyUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompany', 'companyId', companyId)
            // verify required parameter 'companyUpdate' is not null or undefined
            assertParamExists('updateCompany', 'companyUpdate', companyUpdate)
            const localVarPath = `/company/{company_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Company
         * @param {number} companyId 
         * @param {CompanyUpdate} companyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany_5: async (companyId: number, companyUpdate: CompanyUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompany_5', 'companyId', companyId)
            // verify required parameter 'companyUpdate' is not null or undefined
            assertParamExists('updateCompany_5', 'companyUpdate', companyUpdate)
            const localVarPath = `/company/{company_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Company
         * @param {CompanyCreate} companyCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(companyCreate: CompanyCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(companyCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.createCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Company
         * @param {CompanyCreate} companyCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany_1(companyCreate: CompanyCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany_1(companyCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.createCompany_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deactivate Company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateCompany(companyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateCompany(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.deactivateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deactivate Company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateCompany_2(companyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateCompany_2(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.deactivateCompany_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanies(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanies(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getCompanies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanies_3(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanies_3(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getCompanies_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyById(companyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyById(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getCompanyById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyById_4(companyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyById_4(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getCompanyById_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Company
         * @param {number} companyId 
         * @param {CompanyUpdate} companyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(companyId: number, companyUpdate: CompanyUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(companyId, companyUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.updateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Company
         * @param {number} companyId 
         * @param {CompanyUpdate} companyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany_5(companyId: number, companyUpdate: CompanyUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany_5(companyId, companyUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.updateCompany_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Company
         * @param {CompanyApiCreateCompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(requestParameters: CompanyApiCreateCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.createCompany(requestParameters.companyCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Company
         * @param {CompanyApiCreateCompany0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany_1(requestParameters: CompanyApiCreateCompany0Request, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.createCompany_1(requestParameters.companyCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Company
         * @param {CompanyApiDeactivateCompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateCompany(requestParameters: CompanyApiDeactivateCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.deactivateCompany(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Company
         * @param {CompanyApiDeactivateCompany0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateCompany_2(requestParameters: CompanyApiDeactivateCompany0Request, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.deactivateCompany_2(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies(options?: RawAxiosRequestConfig): AxiosPromise<Array<Company>> {
            return localVarFp.getCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies_3(options?: RawAxiosRequestConfig): AxiosPromise<Array<Company>> {
            return localVarFp.getCompanies_3(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Company
         * @param {CompanyApiGetCompanyByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(requestParameters: CompanyApiGetCompanyByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.getCompanyById(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Company
         * @param {CompanyApiGetCompanyById0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById_4(requestParameters: CompanyApiGetCompanyById0Request, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.getCompanyById_4(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Company
         * @param {CompanyApiUpdateCompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(requestParameters: CompanyApiUpdateCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.updateCompany(requestParameters.companyId, requestParameters.companyUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Company
         * @param {CompanyApiUpdateCompany0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany_5(requestParameters: CompanyApiUpdateCompany0Request, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.updateCompany_5(requestParameters.companyId, requestParameters.companyUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCompany operation in CompanyApi.
 * @export
 * @interface CompanyApiCreateCompanyRequest
 */
export interface CompanyApiCreateCompanyRequest {
    /**
     * 
     * @type {CompanyCreate}
     * @memberof CompanyApiCreateCompany
     */
    readonly companyCreate: CompanyCreate
}

/**
 * Request parameters for createCompany_1 operation in CompanyApi.
 * @export
 * @interface CompanyApiCreateCompany0Request
 */
export interface CompanyApiCreateCompany0Request {
    /**
     * 
     * @type {CompanyCreate}
     * @memberof CompanyApiCreateCompany0
     */
    readonly companyCreate: CompanyCreate
}

/**
 * Request parameters for deactivateCompany operation in CompanyApi.
 * @export
 * @interface CompanyApiDeactivateCompanyRequest
 */
export interface CompanyApiDeactivateCompanyRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiDeactivateCompany
     */
    readonly companyId: number
}

/**
 * Request parameters for deactivateCompany_2 operation in CompanyApi.
 * @export
 * @interface CompanyApiDeactivateCompany0Request
 */
export interface CompanyApiDeactivateCompany0Request {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiDeactivateCompany0
     */
    readonly companyId: number
}

/**
 * Request parameters for getCompanyById operation in CompanyApi.
 * @export
 * @interface CompanyApiGetCompanyByIdRequest
 */
export interface CompanyApiGetCompanyByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiGetCompanyById
     */
    readonly companyId: number
}

/**
 * Request parameters for getCompanyById_4 operation in CompanyApi.
 * @export
 * @interface CompanyApiGetCompanyById0Request
 */
export interface CompanyApiGetCompanyById0Request {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiGetCompanyById0
     */
    readonly companyId: number
}

/**
 * Request parameters for updateCompany operation in CompanyApi.
 * @export
 * @interface CompanyApiUpdateCompanyRequest
 */
export interface CompanyApiUpdateCompanyRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiUpdateCompany
     */
    readonly companyId: number

    /**
     * 
     * @type {CompanyUpdate}
     * @memberof CompanyApiUpdateCompany
     */
    readonly companyUpdate: CompanyUpdate
}

/**
 * Request parameters for updateCompany_5 operation in CompanyApi.
 * @export
 * @interface CompanyApiUpdateCompany0Request
 */
export interface CompanyApiUpdateCompany0Request {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiUpdateCompany0
     */
    readonly companyId: number

    /**
     * 
     * @type {CompanyUpdate}
     * @memberof CompanyApiUpdateCompany0
     */
    readonly companyUpdate: CompanyUpdate
}

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @summary Create Company
     * @param {CompanyApiCreateCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createCompany(requestParameters: CompanyApiCreateCompanyRequest, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).createCompany(requestParameters.companyCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Company
     * @param {CompanyApiCreateCompany0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createCompany_1(requestParameters: CompanyApiCreateCompany0Request, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).createCompany_1(requestParameters.companyCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Company
     * @param {CompanyApiDeactivateCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public deactivateCompany(requestParameters: CompanyApiDeactivateCompanyRequest, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).deactivateCompany(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Company
     * @param {CompanyApiDeactivateCompany0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public deactivateCompany_2(requestParameters: CompanyApiDeactivateCompany0Request, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).deactivateCompany_2(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanies(options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanies_3(options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanies_3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Company
     * @param {CompanyApiGetCompanyByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanyById(requestParameters: CompanyApiGetCompanyByIdRequest, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanyById(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Company
     * @param {CompanyApiGetCompanyById0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanyById_4(requestParameters: CompanyApiGetCompanyById0Request, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanyById_4(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Company
     * @param {CompanyApiUpdateCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompany(requestParameters: CompanyApiUpdateCompanyRequest, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCompany(requestParameters.companyId, requestParameters.companyUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Company
     * @param {CompanyApiUpdateCompany0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompany_5(requestParameters: CompanyApiUpdateCompany0Request, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCompany_5(requestParameters.companyId, requestParameters.companyUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Home To Member
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHomeToMember: async (homeId: number, memberId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('addHomeToMember', 'homeId', homeId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('addHomeToMember', 'memberId', memberId)
            const localVarPath = `/members/home/{home_id}/member/{member_id}`
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HomeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Home To Member
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addHomeToMember(homeId: number, memberId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addHomeToMember(homeId, memberId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.addHomeToMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HomeApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Home To Member
         * @param {HomeApiAddHomeToMemberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHomeToMember(requestParameters: HomeApiAddHomeToMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberSchema> {
            return localVarFp.addHomeToMember(requestParameters.homeId, requestParameters.memberId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addHomeToMember operation in HomeApi.
 * @export
 * @interface HomeApiAddHomeToMemberRequest
 */
export interface HomeApiAddHomeToMemberRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiAddHomeToMember
     */
    readonly homeId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiAddHomeToMember
     */
    readonly memberId: number
}

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI {
    /**
     * 
     * @summary Add Home To Member
     * @param {HomeApiAddHomeToMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public addHomeToMember(requestParameters: HomeApiAddHomeToMemberRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).addHomeToMember(requestParameters.homeId, requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MemberApi - axios parameter creator
 * @export
 */
export const MemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Home To Member
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHomeToMember: async (homeId: number, memberId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('addHomeToMember', 'homeId', homeId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('addHomeToMember', 'memberId', memberId)
            const localVarPath = `/members/home/{home_id}/member/{member_id}`
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes request to register member account.
         * @summary Create Member
         * @param {CreateMemberSchema} createMemberSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMember: async (createMemberSchema: CreateMemberSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMemberSchema' is not null or undefined
            assertParamExists('createMember', 'createMemberSchema', createMemberSchema)
            const localVarPath = `/members/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMemberSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes request to register member account.
         * @summary Create Member
         * @param {CreateMemberSchema} createMemberSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMember_1: async (createMemberSchema: CreateMemberSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMemberSchema' is not null or undefined
            assertParamExists('createMember_1', 'createMemberSchema', createMemberSchema)
            const localVarPath = `/members/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMemberSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Company Members
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForCompany: async (companyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllMembersForCompany', 'companyId', companyId)
            const localVarPath = `/members/company/{company_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Company Members
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForCompany_2: async (companyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllMembersForCompany_2', 'companyId', companyId)
            const localVarPath = `/members/company/{company_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Hoa Members
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForHOA: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getAllMembersForHOA', 'hoaId', hoaId)
            const localVarPath = `/members/hoa/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Hoa Members
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForHOA_3: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getAllMembersForHOA_3', 'hoaId', hoaId)
            const localVarPath = `/members/hoa/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Home Members
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForHome: async (homeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('getAllMembersForHome', 'homeId', homeId)
            const localVarPath = `/members/home/{home_id}`
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Home Members
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForHome_4: async (homeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('getAllMembersForHome_4', 'homeId', homeId)
            const localVarPath = `/members/home/{home_id}`
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Member
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberById: async (memberId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getMemberById', 'memberId', memberId)
            const localVarPath = `/members/{member_id}`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Member
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberById_5: async (memberId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getMemberById_5', 'memberId', memberId)
            const localVarPath = `/members/{member_id}`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Member
         * @param {number} memberId 
         * @param {UpdateMemberSchema} updateMemberSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember: async (memberId: number, updateMemberSchema: UpdateMemberSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('updateMember', 'memberId', memberId)
            // verify required parameter 'updateMemberSchema' is not null or undefined
            assertParamExists('updateMember', 'updateMemberSchema', updateMemberSchema)
            const localVarPath = `/members/{member_id}`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMemberSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Member Password
         * @param {number} memberId 
         * @param {MemberPasswordUpdate} memberPasswordUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberPassword: async (memberId: number, memberPasswordUpdate: MemberPasswordUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('updateMemberPassword', 'memberId', memberId)
            // verify required parameter 'memberPasswordUpdate' is not null or undefined
            assertParamExists('updateMemberPassword', 'memberPasswordUpdate', memberPasswordUpdate)
            const localVarPath = `/members/{member_id}/password`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberPasswordUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Member Password
         * @param {number} memberId 
         * @param {MemberPasswordUpdate} memberPasswordUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberPassword_6: async (memberId: number, memberPasswordUpdate: MemberPasswordUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('updateMemberPassword_6', 'memberId', memberId)
            // verify required parameter 'memberPasswordUpdate' is not null or undefined
            assertParamExists('updateMemberPassword_6', 'memberPasswordUpdate', memberPasswordUpdate)
            const localVarPath = `/members/{member_id}/password`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberPasswordUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Member
         * @param {number} memberId 
         * @param {UpdateMemberSchema} updateMemberSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember_7: async (memberId: number, updateMemberSchema: UpdateMemberSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('updateMember_7', 'memberId', memberId)
            // verify required parameter 'updateMemberSchema' is not null or undefined
            assertParamExists('updateMember_7', 'updateMemberSchema', updateMemberSchema)
            const localVarPath = `/members/{member_id}`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMemberSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberApi - functional programming interface
 * @export
 */
export const MemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Home To Member
         * @param {number} homeId 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addHomeToMember(homeId: number, memberId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addHomeToMember(homeId, memberId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.addHomeToMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Processes request to register member account.
         * @summary Create Member
         * @param {CreateMemberSchema} createMemberSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMember(createMemberSchema: CreateMemberSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMember(createMemberSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.createMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Processes request to register member account.
         * @summary Create Member
         * @param {CreateMemberSchema} createMemberSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMember_1(createMemberSchema: CreateMemberSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMember_1(createMemberSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.createMember_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Company Members
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMembersForCompany(companyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMembersForCompany(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.getAllMembersForCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Company Members
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMembersForCompany_2(companyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMembersForCompany_2(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.getAllMembersForCompany_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Hoa Members
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMembersForHOA(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMembersForHOA(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.getAllMembersForHOA']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Hoa Members
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMembersForHOA_3(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMembersForHOA_3(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.getAllMembersForHOA_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Home Members
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMembersForHome(homeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMembersForHome(homeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.getAllMembersForHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Home Members
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMembersForHome_4(homeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMembersForHome_4(homeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.getAllMembersForHome_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Member
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberById(memberId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberById(memberId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.getMemberById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Member
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberById_5(memberId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberById_5(memberId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.getMemberById_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Member
         * @param {number} memberId 
         * @param {UpdateMemberSchema} updateMemberSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMember(memberId: number, updateMemberSchema: UpdateMemberSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMember(memberId, updateMemberSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.updateMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Member Password
         * @param {number} memberId 
         * @param {MemberPasswordUpdate} memberPasswordUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberPassword(memberId: number, memberPasswordUpdate: MemberPasswordUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberPassword(memberId, memberPasswordUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.updateMemberPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Member Password
         * @param {number} memberId 
         * @param {MemberPasswordUpdate} memberPasswordUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberPassword_6(memberId: number, memberPasswordUpdate: MemberPasswordUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberPassword_6(memberId, memberPasswordUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.updateMemberPassword_6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Member
         * @param {number} memberId 
         * @param {UpdateMemberSchema} updateMemberSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMember_7(memberId: number, updateMemberSchema: UpdateMemberSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMember_7(memberId, updateMemberSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberApi.updateMember_7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MemberApi - factory interface
 * @export
 */
export const MemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Home To Member
         * @param {MemberApiAddHomeToMemberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHomeToMember(requestParameters: MemberApiAddHomeToMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberSchema> {
            return localVarFp.addHomeToMember(requestParameters.homeId, requestParameters.memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes request to register member account.
         * @summary Create Member
         * @param {MemberApiCreateMemberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMember(requestParameters: MemberApiCreateMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberSchema> {
            return localVarFp.createMember(requestParameters.createMemberSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes request to register member account.
         * @summary Create Member
         * @param {MemberApiCreateMember0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMember_1(requestParameters: MemberApiCreateMember0Request, options?: RawAxiosRequestConfig): AxiosPromise<MemberSchema> {
            return localVarFp.createMember_1(requestParameters.createMemberSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Company Members
         * @param {MemberApiGetAllMembersForCompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForCompany(requestParameters: MemberApiGetAllMembersForCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<MemberSchema>> {
            return localVarFp.getAllMembersForCompany(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Company Members
         * @param {MemberApiGetAllMembersForCompany0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForCompany_2(requestParameters: MemberApiGetAllMembersForCompany0Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<MemberSchema>> {
            return localVarFp.getAllMembersForCompany_2(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Hoa Members
         * @param {MemberApiGetAllMembersForHOARequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForHOA(requestParameters: MemberApiGetAllMembersForHOARequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<MemberSchema>> {
            return localVarFp.getAllMembersForHOA(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Hoa Members
         * @param {MemberApiGetAllMembersForHOA0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForHOA_3(requestParameters: MemberApiGetAllMembersForHOA0Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<MemberSchema>> {
            return localVarFp.getAllMembersForHOA_3(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Home Members
         * @param {MemberApiGetAllMembersForHomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForHome(requestParameters: MemberApiGetAllMembersForHomeRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<MemberSchema>> {
            return localVarFp.getAllMembersForHome(requestParameters.homeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Home Members
         * @param {MemberApiGetAllMembersForHome0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForHome_4(requestParameters: MemberApiGetAllMembersForHome0Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<MemberSchema>> {
            return localVarFp.getAllMembersForHome_4(requestParameters.homeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Member
         * @param {MemberApiGetMemberByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberById(requestParameters: MemberApiGetMemberByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberSchema> {
            return localVarFp.getMemberById(requestParameters.memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Member
         * @param {MemberApiGetMemberById0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberById_5(requestParameters: MemberApiGetMemberById0Request, options?: RawAxiosRequestConfig): AxiosPromise<MemberSchema> {
            return localVarFp.getMemberById_5(requestParameters.memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Member
         * @param {MemberApiUpdateMemberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember(requestParameters: MemberApiUpdateMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberSchema> {
            return localVarFp.updateMember(requestParameters.memberId, requestParameters.updateMemberSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Member Password
         * @param {MemberApiUpdateMemberPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberPassword(requestParameters: MemberApiUpdateMemberPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberSchema> {
            return localVarFp.updateMemberPassword(requestParameters.memberId, requestParameters.memberPasswordUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Member Password
         * @param {MemberApiUpdateMemberPassword0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberPassword_6(requestParameters: MemberApiUpdateMemberPassword0Request, options?: RawAxiosRequestConfig): AxiosPromise<MemberSchema> {
            return localVarFp.updateMemberPassword_6(requestParameters.memberId, requestParameters.memberPasswordUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Member
         * @param {MemberApiUpdateMember0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember_7(requestParameters: MemberApiUpdateMember0Request, options?: RawAxiosRequestConfig): AxiosPromise<MemberSchema> {
            return localVarFp.updateMember_7(requestParameters.memberId, requestParameters.updateMemberSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addHomeToMember operation in MemberApi.
 * @export
 * @interface MemberApiAddHomeToMemberRequest
 */
export interface MemberApiAddHomeToMemberRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberApiAddHomeToMember
     */
    readonly homeId: number

    /**
     * 
     * @type {number}
     * @memberof MemberApiAddHomeToMember
     */
    readonly memberId: number
}

/**
 * Request parameters for createMember operation in MemberApi.
 * @export
 * @interface MemberApiCreateMemberRequest
 */
export interface MemberApiCreateMemberRequest {
    /**
     * 
     * @type {CreateMemberSchema}
     * @memberof MemberApiCreateMember
     */
    readonly createMemberSchema: CreateMemberSchema
}

/**
 * Request parameters for createMember_1 operation in MemberApi.
 * @export
 * @interface MemberApiCreateMember0Request
 */
export interface MemberApiCreateMember0Request {
    /**
     * 
     * @type {CreateMemberSchema}
     * @memberof MemberApiCreateMember0
     */
    readonly createMemberSchema: CreateMemberSchema
}

/**
 * Request parameters for getAllMembersForCompany operation in MemberApi.
 * @export
 * @interface MemberApiGetAllMembersForCompanyRequest
 */
export interface MemberApiGetAllMembersForCompanyRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberApiGetAllMembersForCompany
     */
    readonly companyId: number
}

/**
 * Request parameters for getAllMembersForCompany_2 operation in MemberApi.
 * @export
 * @interface MemberApiGetAllMembersForCompany0Request
 */
export interface MemberApiGetAllMembersForCompany0Request {
    /**
     * 
     * @type {number}
     * @memberof MemberApiGetAllMembersForCompany0
     */
    readonly companyId: number
}

/**
 * Request parameters for getAllMembersForHOA operation in MemberApi.
 * @export
 * @interface MemberApiGetAllMembersForHOARequest
 */
export interface MemberApiGetAllMembersForHOARequest {
    /**
     * 
     * @type {number}
     * @memberof MemberApiGetAllMembersForHOA
     */
    readonly hoaId: number
}

/**
 * Request parameters for getAllMembersForHOA_3 operation in MemberApi.
 * @export
 * @interface MemberApiGetAllMembersForHOA0Request
 */
export interface MemberApiGetAllMembersForHOA0Request {
    /**
     * 
     * @type {number}
     * @memberof MemberApiGetAllMembersForHOA0
     */
    readonly hoaId: number
}

/**
 * Request parameters for getAllMembersForHome operation in MemberApi.
 * @export
 * @interface MemberApiGetAllMembersForHomeRequest
 */
export interface MemberApiGetAllMembersForHomeRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberApiGetAllMembersForHome
     */
    readonly homeId: number
}

/**
 * Request parameters for getAllMembersForHome_4 operation in MemberApi.
 * @export
 * @interface MemberApiGetAllMembersForHome0Request
 */
export interface MemberApiGetAllMembersForHome0Request {
    /**
     * 
     * @type {number}
     * @memberof MemberApiGetAllMembersForHome0
     */
    readonly homeId: number
}

/**
 * Request parameters for getMemberById operation in MemberApi.
 * @export
 * @interface MemberApiGetMemberByIdRequest
 */
export interface MemberApiGetMemberByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberApiGetMemberById
     */
    readonly memberId: number
}

/**
 * Request parameters for getMemberById_5 operation in MemberApi.
 * @export
 * @interface MemberApiGetMemberById0Request
 */
export interface MemberApiGetMemberById0Request {
    /**
     * 
     * @type {number}
     * @memberof MemberApiGetMemberById0
     */
    readonly memberId: number
}

/**
 * Request parameters for updateMember operation in MemberApi.
 * @export
 * @interface MemberApiUpdateMemberRequest
 */
export interface MemberApiUpdateMemberRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberApiUpdateMember
     */
    readonly memberId: number

    /**
     * 
     * @type {UpdateMemberSchema}
     * @memberof MemberApiUpdateMember
     */
    readonly updateMemberSchema: UpdateMemberSchema
}

/**
 * Request parameters for updateMemberPassword operation in MemberApi.
 * @export
 * @interface MemberApiUpdateMemberPasswordRequest
 */
export interface MemberApiUpdateMemberPasswordRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberApiUpdateMemberPassword
     */
    readonly memberId: number

    /**
     * 
     * @type {MemberPasswordUpdate}
     * @memberof MemberApiUpdateMemberPassword
     */
    readonly memberPasswordUpdate: MemberPasswordUpdate
}

/**
 * Request parameters for updateMemberPassword_6 operation in MemberApi.
 * @export
 * @interface MemberApiUpdateMemberPassword0Request
 */
export interface MemberApiUpdateMemberPassword0Request {
    /**
     * 
     * @type {number}
     * @memberof MemberApiUpdateMemberPassword0
     */
    readonly memberId: number

    /**
     * 
     * @type {MemberPasswordUpdate}
     * @memberof MemberApiUpdateMemberPassword0
     */
    readonly memberPasswordUpdate: MemberPasswordUpdate
}

/**
 * Request parameters for updateMember_7 operation in MemberApi.
 * @export
 * @interface MemberApiUpdateMember0Request
 */
export interface MemberApiUpdateMember0Request {
    /**
     * 
     * @type {number}
     * @memberof MemberApiUpdateMember0
     */
    readonly memberId: number

    /**
     * 
     * @type {UpdateMemberSchema}
     * @memberof MemberApiUpdateMember0
     */
    readonly updateMemberSchema: UpdateMemberSchema
}

/**
 * MemberApi - object-oriented interface
 * @export
 * @class MemberApi
 * @extends {BaseAPI}
 */
export class MemberApi extends BaseAPI {
    /**
     * 
     * @summary Add Home To Member
     * @param {MemberApiAddHomeToMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public addHomeToMember(requestParameters: MemberApiAddHomeToMemberRequest, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).addHomeToMember(requestParameters.homeId, requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes request to register member account.
     * @summary Create Member
     * @param {MemberApiCreateMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public createMember(requestParameters: MemberApiCreateMemberRequest, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).createMember(requestParameters.createMemberSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes request to register member account.
     * @summary Create Member
     * @param {MemberApiCreateMember0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public createMember_1(requestParameters: MemberApiCreateMember0Request, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).createMember_1(requestParameters.createMemberSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Company Members
     * @param {MemberApiGetAllMembersForCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getAllMembersForCompany(requestParameters: MemberApiGetAllMembersForCompanyRequest, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).getAllMembersForCompany(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Company Members
     * @param {MemberApiGetAllMembersForCompany0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getAllMembersForCompany_2(requestParameters: MemberApiGetAllMembersForCompany0Request, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).getAllMembersForCompany_2(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Hoa Members
     * @param {MemberApiGetAllMembersForHOARequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getAllMembersForHOA(requestParameters: MemberApiGetAllMembersForHOARequest, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).getAllMembersForHOA(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Hoa Members
     * @param {MemberApiGetAllMembersForHOA0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getAllMembersForHOA_3(requestParameters: MemberApiGetAllMembersForHOA0Request, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).getAllMembersForHOA_3(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Home Members
     * @param {MemberApiGetAllMembersForHomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getAllMembersForHome(requestParameters: MemberApiGetAllMembersForHomeRequest, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).getAllMembersForHome(requestParameters.homeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Home Members
     * @param {MemberApiGetAllMembersForHome0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getAllMembersForHome_4(requestParameters: MemberApiGetAllMembersForHome0Request, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).getAllMembersForHome_4(requestParameters.homeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Member
     * @param {MemberApiGetMemberByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getMemberById(requestParameters: MemberApiGetMemberByIdRequest, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).getMemberById(requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Member
     * @param {MemberApiGetMemberById0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getMemberById_5(requestParameters: MemberApiGetMemberById0Request, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).getMemberById_5(requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Member
     * @param {MemberApiUpdateMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public updateMember(requestParameters: MemberApiUpdateMemberRequest, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).updateMember(requestParameters.memberId, requestParameters.updateMemberSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Member Password
     * @param {MemberApiUpdateMemberPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public updateMemberPassword(requestParameters: MemberApiUpdateMemberPasswordRequest, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).updateMemberPassword(requestParameters.memberId, requestParameters.memberPasswordUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Member Password
     * @param {MemberApiUpdateMemberPassword0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public updateMemberPassword_6(requestParameters: MemberApiUpdateMemberPassword0Request, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).updateMemberPassword_6(requestParameters.memberId, requestParameters.memberPasswordUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Member
     * @param {MemberApiUpdateMember0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public updateMember_7(requestParameters: MemberApiUpdateMember0Request, options?: RawAxiosRequestConfig) {
        return MemberApiFp(this.configuration).updateMember_7(requestParameters.memberId, requestParameters.updateMemberSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MemberAuthApi - axios parameter creator
 * @export
 */
export const MemberAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Handle Google OAuth login.
         * @summary Google Login Member
         * @param {BodyMemberGoogleLogin} bodyMemberGoogleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberGoogleLogin: async (bodyMemberGoogleLogin: BodyMemberGoogleLogin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyMemberGoogleLogin' is not null or undefined
            assertParamExists('memberGoogleLogin', 'bodyMemberGoogleLogin', bodyMemberGoogleLogin)
            const localVarPath = `/member/auth/google-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyMemberGoogleLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handle Google OAuth login.
         * @summary Google Login Member
         * @param {BodyMemberGoogleLogin} bodyMemberGoogleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberGoogleLogin_1: async (bodyMemberGoogleLogin: BodyMemberGoogleLogin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyMemberGoogleLogin' is not null or undefined
            assertParamExists('memberGoogleLogin_1', 'bodyMemberGoogleLogin', bodyMemberGoogleLogin)
            const localVarPath = `/member/auth/google-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyMemberGoogleLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login Member
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberLogin: async (username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('memberLogin', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('memberLogin', 'password', password)
            const localVarPath = `/member/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login Member
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberLogin_2: async (username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('memberLogin_2', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('memberLogin_2', 'password', password)
            const localVarPath = `/member/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Password Reset Request Member
         * @param {MemberPasswordResetRequestSchema} memberPasswordResetRequestSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPasswordResetRequest: async (memberPasswordResetRequestSchema: MemberPasswordResetRequestSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberPasswordResetRequestSchema' is not null or undefined
            assertParamExists('memberPasswordResetRequest', 'memberPasswordResetRequestSchema', memberPasswordResetRequestSchema)
            const localVarPath = `/member/auth/password-reset-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberPasswordResetRequestSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Password Reset Request Member
         * @param {MemberPasswordResetRequestSchema} memberPasswordResetRequestSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPasswordResetRequest_3: async (memberPasswordResetRequestSchema: MemberPasswordResetRequestSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberPasswordResetRequestSchema' is not null or undefined
            assertParamExists('memberPasswordResetRequest_3', 'memberPasswordResetRequestSchema', memberPasswordResetRequestSchema)
            const localVarPath = `/member/auth/password-reset-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberPasswordResetRequestSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh Token Member
         * @param {BodyMemberRefreshToken} bodyMemberRefreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRefreshToken: async (bodyMemberRefreshToken: BodyMemberRefreshToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyMemberRefreshToken' is not null or undefined
            assertParamExists('memberRefreshToken', 'bodyMemberRefreshToken', bodyMemberRefreshToken)
            const localVarPath = `/member/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyMemberRefreshToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh Token Member
         * @param {BodyMemberRefreshToken} bodyMemberRefreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRefreshToken_4: async (bodyMemberRefreshToken: BodyMemberRefreshToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyMemberRefreshToken' is not null or undefined
            assertParamExists('memberRefreshToken_4', 'bodyMemberRefreshToken', bodyMemberRefreshToken)
            const localVarPath = `/member/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyMemberRefreshToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Password Member
         * @param {MemberPasswordResetSchema} memberPasswordResetSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberResetPassword: async (memberPasswordResetSchema: MemberPasswordResetSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberPasswordResetSchema' is not null or undefined
            assertParamExists('memberResetPassword', 'memberPasswordResetSchema', memberPasswordResetSchema)
            const localVarPath = `/member/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberPasswordResetSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Password Member
         * @param {MemberPasswordResetSchema} memberPasswordResetSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberResetPassword_5: async (memberPasswordResetSchema: MemberPasswordResetSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberPasswordResetSchema' is not null or undefined
            assertParamExists('memberResetPassword_5', 'memberPasswordResetSchema', memberPasswordResetSchema)
            const localVarPath = `/member/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberPasswordResetSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberAuthApi - functional programming interface
 * @export
 */
export const MemberAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Handle Google OAuth login.
         * @summary Google Login Member
         * @param {BodyMemberGoogleLogin} bodyMemberGoogleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberGoogleLogin(bodyMemberGoogleLogin: BodyMemberGoogleLogin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberGoogleLogin(bodyMemberGoogleLogin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberAuthApi.memberGoogleLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Handle Google OAuth login.
         * @summary Google Login Member
         * @param {BodyMemberGoogleLogin} bodyMemberGoogleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberGoogleLogin_1(bodyMemberGoogleLogin: BodyMemberGoogleLogin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberGoogleLogin_1(bodyMemberGoogleLogin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberAuthApi.memberGoogleLogin_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Login Member
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberLogin(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberLogin(username, password, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberAuthApi.memberLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Login Member
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberLogin_2(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberLogin_2(username, password, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberAuthApi.memberLogin_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Password Reset Request Member
         * @param {MemberPasswordResetRequestSchema} memberPasswordResetRequestSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPasswordResetRequest(memberPasswordResetRequestSchema: MemberPasswordResetRequestSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPasswordResetRequest(memberPasswordResetRequestSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberAuthApi.memberPasswordResetRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Password Reset Request Member
         * @param {MemberPasswordResetRequestSchema} memberPasswordResetRequestSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPasswordResetRequest_3(memberPasswordResetRequestSchema: MemberPasswordResetRequestSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPasswordResetRequest_3(memberPasswordResetRequestSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberAuthApi.memberPasswordResetRequest_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh Token Member
         * @param {BodyMemberRefreshToken} bodyMemberRefreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberRefreshToken(bodyMemberRefreshToken: BodyMemberRefreshToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberRefreshToken(bodyMemberRefreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberAuthApi.memberRefreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh Token Member
         * @param {BodyMemberRefreshToken} bodyMemberRefreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberRefreshToken_4(bodyMemberRefreshToken: BodyMemberRefreshToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberRefreshToken_4(bodyMemberRefreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberAuthApi.memberRefreshToken_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Password Member
         * @param {MemberPasswordResetSchema} memberPasswordResetSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberResetPassword(memberPasswordResetSchema: MemberPasswordResetSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberResetPassword(memberPasswordResetSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberAuthApi.memberResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Password Member
         * @param {MemberPasswordResetSchema} memberPasswordResetSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberResetPassword_5(memberPasswordResetSchema: MemberPasswordResetSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberResetPassword_5(memberPasswordResetSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberAuthApi.memberResetPassword_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MemberAuthApi - factory interface
 * @export
 */
export const MemberAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberAuthApiFp(configuration)
    return {
        /**
         * Handle Google OAuth login.
         * @summary Google Login Member
         * @param {MemberAuthApiMemberGoogleLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberGoogleLogin(requestParameters: MemberAuthApiMemberGoogleLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.memberGoogleLogin(requestParameters.bodyMemberGoogleLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * Handle Google OAuth login.
         * @summary Google Login Member
         * @param {MemberAuthApiMemberGoogleLogin0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberGoogleLogin_1(requestParameters: MemberAuthApiMemberGoogleLogin0Request, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.memberGoogleLogin_1(requestParameters.bodyMemberGoogleLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login Member
         * @param {MemberAuthApiMemberLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberLogin(requestParameters: MemberAuthApiMemberLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.memberLogin(requestParameters.username, requestParameters.password, requestParameters.grantType, requestParameters.scope, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login Member
         * @param {MemberAuthApiMemberLogin0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberLogin_2(requestParameters: MemberAuthApiMemberLogin0Request, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.memberLogin_2(requestParameters.username, requestParameters.password, requestParameters.grantType, requestParameters.scope, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Password Reset Request Member
         * @param {MemberAuthApiMemberPasswordResetRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPasswordResetRequest(requestParameters: MemberAuthApiMemberPasswordResetRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.memberPasswordResetRequest(requestParameters.memberPasswordResetRequestSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Password Reset Request Member
         * @param {MemberAuthApiMemberPasswordResetRequest0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPasswordResetRequest_3(requestParameters: MemberAuthApiMemberPasswordResetRequest0Request, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.memberPasswordResetRequest_3(requestParameters.memberPasswordResetRequestSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh Token Member
         * @param {MemberAuthApiMemberRefreshTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRefreshToken(requestParameters: MemberAuthApiMemberRefreshTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.memberRefreshToken(requestParameters.bodyMemberRefreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh Token Member
         * @param {MemberAuthApiMemberRefreshToken0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRefreshToken_4(requestParameters: MemberAuthApiMemberRefreshToken0Request, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.memberRefreshToken_4(requestParameters.bodyMemberRefreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Password Member
         * @param {MemberAuthApiMemberResetPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberResetPassword(requestParameters: MemberAuthApiMemberResetPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.memberResetPassword(requestParameters.memberPasswordResetSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Password Member
         * @param {MemberAuthApiMemberResetPassword0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberResetPassword_5(requestParameters: MemberAuthApiMemberResetPassword0Request, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.memberResetPassword_5(requestParameters.memberPasswordResetSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for memberGoogleLogin operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiMemberGoogleLoginRequest
 */
export interface MemberAuthApiMemberGoogleLoginRequest {
    /**
     * 
     * @type {BodyMemberGoogleLogin}
     * @memberof MemberAuthApiMemberGoogleLogin
     */
    readonly bodyMemberGoogleLogin: BodyMemberGoogleLogin
}

/**
 * Request parameters for memberGoogleLogin_1 operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiMemberGoogleLogin0Request
 */
export interface MemberAuthApiMemberGoogleLogin0Request {
    /**
     * 
     * @type {BodyMemberGoogleLogin}
     * @memberof MemberAuthApiMemberGoogleLogin0
     */
    readonly bodyMemberGoogleLogin: BodyMemberGoogleLogin
}

/**
 * Request parameters for memberLogin operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiMemberLoginRequest
 */
export interface MemberAuthApiMemberLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin
     */
    readonly username: string

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin
     */
    readonly password: string

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin
     */
    readonly grantType?: string | null

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin
     */
    readonly scope?: string

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin
     */
    readonly clientId?: string | null

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin
     */
    readonly clientSecret?: string | null
}

/**
 * Request parameters for memberLogin_2 operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiMemberLogin0Request
 */
export interface MemberAuthApiMemberLogin0Request {
    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin0
     */
    readonly username: string

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin0
     */
    readonly password: string

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin0
     */
    readonly grantType?: string | null

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin0
     */
    readonly scope?: string

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin0
     */
    readonly clientId?: string | null

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiMemberLogin0
     */
    readonly clientSecret?: string | null
}

/**
 * Request parameters for memberPasswordResetRequest operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiMemberPasswordResetRequestRequest
 */
export interface MemberAuthApiMemberPasswordResetRequestRequest {
    /**
     * 
     * @type {MemberPasswordResetRequestSchema}
     * @memberof MemberAuthApiMemberPasswordResetRequest
     */
    readonly memberPasswordResetRequestSchema: MemberPasswordResetRequestSchema
}

/**
 * Request parameters for memberPasswordResetRequest_3 operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiMemberPasswordResetRequest0Request
 */
export interface MemberAuthApiMemberPasswordResetRequest0Request {
    /**
     * 
     * @type {MemberPasswordResetRequestSchema}
     * @memberof MemberAuthApiMemberPasswordResetRequest0
     */
    readonly memberPasswordResetRequestSchema: MemberPasswordResetRequestSchema
}

/**
 * Request parameters for memberRefreshToken operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiMemberRefreshTokenRequest
 */
export interface MemberAuthApiMemberRefreshTokenRequest {
    /**
     * 
     * @type {BodyMemberRefreshToken}
     * @memberof MemberAuthApiMemberRefreshToken
     */
    readonly bodyMemberRefreshToken: BodyMemberRefreshToken
}

/**
 * Request parameters for memberRefreshToken_4 operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiMemberRefreshToken0Request
 */
export interface MemberAuthApiMemberRefreshToken0Request {
    /**
     * 
     * @type {BodyMemberRefreshToken}
     * @memberof MemberAuthApiMemberRefreshToken0
     */
    readonly bodyMemberRefreshToken: BodyMemberRefreshToken
}

/**
 * Request parameters for memberResetPassword operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiMemberResetPasswordRequest
 */
export interface MemberAuthApiMemberResetPasswordRequest {
    /**
     * 
     * @type {MemberPasswordResetSchema}
     * @memberof MemberAuthApiMemberResetPassword
     */
    readonly memberPasswordResetSchema: MemberPasswordResetSchema
}

/**
 * Request parameters for memberResetPassword_5 operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiMemberResetPassword0Request
 */
export interface MemberAuthApiMemberResetPassword0Request {
    /**
     * 
     * @type {MemberPasswordResetSchema}
     * @memberof MemberAuthApiMemberResetPassword0
     */
    readonly memberPasswordResetSchema: MemberPasswordResetSchema
}

/**
 * MemberAuthApi - object-oriented interface
 * @export
 * @class MemberAuthApi
 * @extends {BaseAPI}
 */
export class MemberAuthApi extends BaseAPI {
    /**
     * Handle Google OAuth login.
     * @summary Google Login Member
     * @param {MemberAuthApiMemberGoogleLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberGoogleLogin(requestParameters: MemberAuthApiMemberGoogleLoginRequest, options?: RawAxiosRequestConfig) {
        return MemberAuthApiFp(this.configuration).memberGoogleLogin(requestParameters.bodyMemberGoogleLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handle Google OAuth login.
     * @summary Google Login Member
     * @param {MemberAuthApiMemberGoogleLogin0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberGoogleLogin_1(requestParameters: MemberAuthApiMemberGoogleLogin0Request, options?: RawAxiosRequestConfig) {
        return MemberAuthApiFp(this.configuration).memberGoogleLogin_1(requestParameters.bodyMemberGoogleLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login Member
     * @param {MemberAuthApiMemberLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberLogin(requestParameters: MemberAuthApiMemberLoginRequest, options?: RawAxiosRequestConfig) {
        return MemberAuthApiFp(this.configuration).memberLogin(requestParameters.username, requestParameters.password, requestParameters.grantType, requestParameters.scope, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login Member
     * @param {MemberAuthApiMemberLogin0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberLogin_2(requestParameters: MemberAuthApiMemberLogin0Request, options?: RawAxiosRequestConfig) {
        return MemberAuthApiFp(this.configuration).memberLogin_2(requestParameters.username, requestParameters.password, requestParameters.grantType, requestParameters.scope, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Password Reset Request Member
     * @param {MemberAuthApiMemberPasswordResetRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberPasswordResetRequest(requestParameters: MemberAuthApiMemberPasswordResetRequestRequest, options?: RawAxiosRequestConfig) {
        return MemberAuthApiFp(this.configuration).memberPasswordResetRequest(requestParameters.memberPasswordResetRequestSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Password Reset Request Member
     * @param {MemberAuthApiMemberPasswordResetRequest0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberPasswordResetRequest_3(requestParameters: MemberAuthApiMemberPasswordResetRequest0Request, options?: RawAxiosRequestConfig) {
        return MemberAuthApiFp(this.configuration).memberPasswordResetRequest_3(requestParameters.memberPasswordResetRequestSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh Token Member
     * @param {MemberAuthApiMemberRefreshTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberRefreshToken(requestParameters: MemberAuthApiMemberRefreshTokenRequest, options?: RawAxiosRequestConfig) {
        return MemberAuthApiFp(this.configuration).memberRefreshToken(requestParameters.bodyMemberRefreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh Token Member
     * @param {MemberAuthApiMemberRefreshToken0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberRefreshToken_4(requestParameters: MemberAuthApiMemberRefreshToken0Request, options?: RawAxiosRequestConfig) {
        return MemberAuthApiFp(this.configuration).memberRefreshToken_4(requestParameters.bodyMemberRefreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Password Member
     * @param {MemberAuthApiMemberResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberResetPassword(requestParameters: MemberAuthApiMemberResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return MemberAuthApiFp(this.configuration).memberResetPassword(requestParameters.memberPasswordResetSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Password Member
     * @param {MemberAuthApiMemberResetPassword0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberResetPassword_5(requestParameters: MemberAuthApiMemberResetPassword0Request, options?: RawAxiosRequestConfig) {
        return MemberAuthApiFp(this.configuration).memberResetPassword_5(requestParameters.memberPasswordResetSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get User
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById_1: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById_1', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {number} userId 
         * @param {UpdateUserSchema} updateUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: number, updateUserSchema: UpdateUserSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'updateUserSchema' is not null or undefined
            assertParamExists('updateUser', 'updateUserSchema', updateUserSchema)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Password
         * @param {number} userId 
         * @param {PasswordUpdate} passwordUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword: async (userId: number, passwordUpdate: PasswordUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserPassword', 'userId', userId)
            // verify required parameter 'passwordUpdate' is not null or undefined
            assertParamExists('updateUserPassword', 'passwordUpdate', passwordUpdate)
            const localVarPath = `/users/{user_id}/password`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Password
         * @param {number} userId 
         * @param {PasswordUpdate} passwordUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword_2: async (userId: number, passwordUpdate: PasswordUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserPassword_2', 'userId', userId)
            // verify required parameter 'passwordUpdate' is not null or undefined
            assertParamExists('updateUserPassword_2', 'passwordUpdate', passwordUpdate)
            const localVarPath = `/users/{user_id}/password`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {number} userId 
         * @param {UpdateUserSchema} updateUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser_3: async (userId: number, updateUserSchema: UpdateUserSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser_3', 'userId', userId)
            // verify required parameter 'updateUserSchema' is not null or undefined
            assertParamExists('updateUser_3', 'updateUserSchema', updateUserSchema)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upgrade user type from ANON to USER
         * @summary Upgrade User
         * @param {number} id 
         * @param {UpgradeUserTypeSchema} upgradeUserTypeSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeUser: async (id: number, upgradeUserTypeSchema: UpgradeUserTypeSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('upgradeUser', 'id', id)
            // verify required parameter 'upgradeUserTypeSchema' is not null or undefined
            assertParamExists('upgradeUser', 'upgradeUserTypeSchema', upgradeUserTypeSchema)
            const localVarPath = `/users/upgrade/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upgradeUserTypeSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get User
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get User
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById_1(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById_1(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserById_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update User
         * @param {number} userId 
         * @param {UpdateUserSchema} updateUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: number, updateUserSchema: UpdateUserSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, updateUserSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Password
         * @param {number} userId 
         * @param {PasswordUpdate} passwordUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPassword(userId: number, passwordUpdate: PasswordUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPassword(userId, passwordUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUserPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Password
         * @param {number} userId 
         * @param {PasswordUpdate} passwordUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPassword_2(userId: number, passwordUpdate: PasswordUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPassword_2(userId, passwordUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUserPassword_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update User
         * @param {number} userId 
         * @param {UpdateUserSchema} updateUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser_3(userId: number, updateUserSchema: UpdateUserSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser_3(userId, updateUserSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUser_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upgrade user type from ANON to USER
         * @summary Upgrade User
         * @param {number} id 
         * @param {UpgradeUserTypeSchema} upgradeUserTypeSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upgradeUser(id: number, upgradeUserTypeSchema: UpgradeUserTypeSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upgradeUser(id, upgradeUserTypeSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.upgradeUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get User
         * @param {UserApiGetUserByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(requestParameters: UserApiGetUserByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.getUserById(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User
         * @param {UserApiGetUserById0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById_1(requestParameters: UserApiGetUserById0Request, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.getUserById_1(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(requestParameters: UserApiUpdateUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.updateUser(requestParameters.userId, requestParameters.updateUserSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Password
         * @param {UserApiUpdateUserPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword(requestParameters: UserApiUpdateUserPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.updateUserPassword(requestParameters.userId, requestParameters.passwordUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Password
         * @param {UserApiUpdateUserPassword0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword_2(requestParameters: UserApiUpdateUserPassword0Request, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.updateUserPassword_2(requestParameters.userId, requestParameters.passwordUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {UserApiUpdateUser0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser_3(requestParameters: UserApiUpdateUser0Request, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.updateUser_3(requestParameters.userId, requestParameters.updateUserSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Upgrade user type from ANON to USER
         * @summary Upgrade User
         * @param {UserApiUpgradeUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeUser(requestParameters: UserApiUpgradeUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.upgradeUser(requestParameters.id, requestParameters.upgradeUserTypeSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getUserById operation in UserApi.
 * @export
 * @interface UserApiGetUserByIdRequest
 */
export interface UserApiGetUserByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiGetUserById
     */
    readonly userId: number
}

/**
 * Request parameters for getUserById_1 operation in UserApi.
 * @export
 * @interface UserApiGetUserById0Request
 */
export interface UserApiGetUserById0Request {
    /**
     * 
     * @type {number}
     * @memberof UserApiGetUserById0
     */
    readonly userId: number
}

/**
 * Request parameters for updateUser operation in UserApi.
 * @export
 * @interface UserApiUpdateUserRequest
 */
export interface UserApiUpdateUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUpdateUser
     */
    readonly userId: number

    /**
     * 
     * @type {UpdateUserSchema}
     * @memberof UserApiUpdateUser
     */
    readonly updateUserSchema: UpdateUserSchema
}

/**
 * Request parameters for updateUserPassword operation in UserApi.
 * @export
 * @interface UserApiUpdateUserPasswordRequest
 */
export interface UserApiUpdateUserPasswordRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUpdateUserPassword
     */
    readonly userId: number

    /**
     * 
     * @type {PasswordUpdate}
     * @memberof UserApiUpdateUserPassword
     */
    readonly passwordUpdate: PasswordUpdate
}

/**
 * Request parameters for updateUserPassword_2 operation in UserApi.
 * @export
 * @interface UserApiUpdateUserPassword0Request
 */
export interface UserApiUpdateUserPassword0Request {
    /**
     * 
     * @type {number}
     * @memberof UserApiUpdateUserPassword0
     */
    readonly userId: number

    /**
     * 
     * @type {PasswordUpdate}
     * @memberof UserApiUpdateUserPassword0
     */
    readonly passwordUpdate: PasswordUpdate
}

/**
 * Request parameters for updateUser_3 operation in UserApi.
 * @export
 * @interface UserApiUpdateUser0Request
 */
export interface UserApiUpdateUser0Request {
    /**
     * 
     * @type {number}
     * @memberof UserApiUpdateUser0
     */
    readonly userId: number

    /**
     * 
     * @type {UpdateUserSchema}
     * @memberof UserApiUpdateUser0
     */
    readonly updateUserSchema: UpdateUserSchema
}

/**
 * Request parameters for upgradeUser operation in UserApi.
 * @export
 * @interface UserApiUpgradeUserRequest
 */
export interface UserApiUpgradeUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUpgradeUser
     */
    readonly id: number

    /**
     * 
     * @type {UpgradeUserTypeSchema}
     * @memberof UserApiUpgradeUser
     */
    readonly upgradeUserTypeSchema: UpgradeUserTypeSchema
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get User
     * @param {UserApiGetUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById(requestParameters: UserApiGetUserByIdRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserById(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User
     * @param {UserApiGetUserById0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById_1(requestParameters: UserApiGetUserById0Request, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserById_1(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(requestParameters: UserApiUpdateUserRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(requestParameters.userId, requestParameters.updateUserSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Password
     * @param {UserApiUpdateUserPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserPassword(requestParameters: UserApiUpdateUserPasswordRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserPassword(requestParameters.userId, requestParameters.passwordUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Password
     * @param {UserApiUpdateUserPassword0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserPassword_2(requestParameters: UserApiUpdateUserPassword0Request, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserPassword_2(requestParameters.userId, requestParameters.passwordUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {UserApiUpdateUser0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser_3(requestParameters: UserApiUpdateUser0Request, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser_3(requestParameters.userId, requestParameters.updateUserSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upgrade user type from ANON to USER
     * @summary Upgrade User
     * @param {UserApiUpgradeUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public upgradeUser(requestParameters: UserApiUpgradeUserRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).upgradeUser(requestParameters.id, requestParameters.upgradeUserTypeSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



