import React from 'react';
import { Switch, SwitchField } from '../Catalyst/switch';
import { MoonIcon } from '@heroicons/react/24/outline';
import { useTheme } from './ThemeProvider';

export const DarkModeToggle: React.FC = () => {
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <SwitchField>
      <div data-slot="label" className="flex items-center gap-3">
        <MoonIcon className="h-5 w-5 text-white" />
        <span className="text-sm text-white">Dark mode</span>
      </div>
      <Switch checked={isDarkMode} onChange={toggleDarkMode} color="sky" />
    </SwitchField>
  );
};
