import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, DialogBody, DialogTitle } from '../common/Catalyst/dialog';
import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from '../common/Catalyst/fieldset';
import { Button } from '../common/Catalyst/button';
import { Divider } from '../common/Catalyst/divider';
import SearchableDropdown from '../common/Custom/SearchableDropdown';
import { memberService } from '../clients/ApiClients';
import LoadingSpinner from '../LoadingSpinner';
import { MemberSchema } from '../clients/auth-api-client';
import { HomeSchema } from '../clients/hoa-api-client';

interface AddMemberToHomeDialogProps {
  isOpen: boolean;
  home: HomeSchema;
  companyId: number;
  onClose: () => void;
  onSave: (addMemberToHome: AddMemberToHome) => Promise<void>;
}

interface AddMemberToHome {
  homeId: number;
  memberId: number;
}

export const AddMemberToHomeDialog: React.FC<AddMemberToHomeDialogProps> = ({
  isOpen,
  home,
  onClose,
  onSave,
}) => {
  const [memberOptions, setMemberOptions] = useState<MemberSchema[]>([]);
  const [selectedMember, setSelectedMember] = useState<MemberSchema | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setLoading(true);
        const response = await memberService.getAllMembersForHOA({
          hoaId: home.hoa_id,
        });
        setMemberOptions(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching members:', err);
        setError('Failed to load members. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchMembers();
  }, []);

  const handleSave = async () => {
    if (isValid) {
      try {
        setLoading(true);
        await onSave({ memberId: selectedMember!.id, homeId: home.id });
        onClose();
      } catch (err) {
        console.error('Error adding member to home:', err);
        setError('Failed to save. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
  };

  const formatMemberName = (member: MemberSchema) => {
    return `${member.first_name} ${member.last_name}`;
  };

  const isValid = selectedMember && home;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="w-full max-w-2xl mx-auto"
    >
      <div className="relative flex flex-col h-full">
        <div className="p-4 md:p-6">
          <DialogTitle className="text-xl font-semibold">
            Add a Member to {home.street}
            {home.street2 ? `, ${home.street2}` : ''}
          </DialogTitle>
          <button
            onClick={onClose}
            className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
            aria-label="Close dialog"
          >
            <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
          </button>

          <Divider className="my-4" />

          {loading && <LoadingSpinner />}

          <DialogBody>
            <Fieldset>
              <FieldGroup>
                <div className="space-y-4">
                  <Field>
                    <Label htmlFor="member">Select a Member*</Label>
                    <SearchableDropdown
                      options={memberOptions.map((member) => ({
                        ...member,
                        name: formatMemberName(member),
                      }))}
                      onSelect={setSelectedMember}
                      placeholder="Search for a Member..."
                    />
                  </Field>
                </div>
              </FieldGroup>
            </Fieldset>
          </DialogBody>
        </div>
        <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
          <div className="flex flex-col space-y-3">
            <Button
              onClick={handleSave}
              color="sky"
              disabled={!isValid}
              className="w-full disabled:opacity-50"
            >
              Save
            </Button>
            <Button onClick={onClose} color="zinc" className="w-full">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddMemberToHomeDialog;
