import React, { useEffect, useMemo, useState } from 'react';
import { XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { HoaCreateOrUpdateSchema } from '../clients/hoa-api-client';
import { formatPhoneNumber, stripPhoneFormatting } from '../utils/phoneUtils';
import { Dialog, DialogBody, DialogTitle } from '../common/Catalyst/dialog';
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from '../common/Catalyst/fieldset';
import { Input } from '../common/Catalyst/input';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from '../common/Catalyst/dropdown';
import usStates from '../utils/addressUtils';
import { Textarea } from '../common/Catalyst/textarea';
import { Button } from '../common/Catalyst/button';

interface HoaDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (hoa: HoaCreateOrUpdateSchema) => Promise<void>;
  userType: string | null;
  companyId: number;
}

const getInitialHoaState = (companyId: number): HoaCreateOrUpdateSchema => ({
  name: '',
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  country: 'USA',
  contact_email: '',
  contact_phone: '',
  website_url: '',
  description: '',
  is_active: true,
  company_id: companyId,
});

interface FormErrors {
  [key: string]: string | undefined;
}

export const AddHoaDialog: React.FC<HoaDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  userType,
  companyId,
}) => {
  const [formData, setFormData] = useState<HoaCreateOrUpdateSchema>(() =>
    getInitialHoaState(companyId)
  );
  const [errors, setErrors] = useState<FormErrors>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});

  const validateField = (
    name: keyof HoaCreateOrUpdateSchema,
    value: string
  ): string | undefined => {
    switch (name) {
      case 'name':
        return !value ? 'Name is required' : undefined;
      case 'street':
        return !value ? 'Street address is required' : undefined;
      case 'city':
        return !value ? 'City is required' : undefined;
      case 'state':
        return !value ? 'State is required' : undefined;
      case 'zip':
        return !value
          ? 'ZIP code is required'
          : !/^\d{5}(-\d{4})?$/.test(value)
            ? 'Invalid ZIP code format'
            : undefined;
      case 'contact_phone':
        const cleanedPhone = stripPhoneFormatting(value);
        return !cleanedPhone
          ? 'Phone number is required'
          : !/^\d{10,11}$/.test(cleanedPhone)
            ? 'Phone number must be 10 or 11 digits'
            : undefined;
      case 'contact_email':
        return !value
          ? 'Email is required'
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ? 'Invalid email format'
            : undefined;
      case 'website_url':
        if (!value) return undefined;
        try {
          new URL(value);
          return undefined;
        } catch {
          return 'Invalid website URL';
        }
      default:
        return undefined;
    }
  };

  const { isValid, validationErrors } = useMemo(() => {
    const requiredFields = [
      'name',
      'street',
      'city',
      'state',
      'zip',
      'contact_phone',
      'contact_email',
    ];

    const newErrors: FormErrors = {};

    const hasAllRequiredFields = requiredFields.every((field) => {
      const value = formData[field as keyof HoaCreateOrUpdateSchema];
      const isNonEmpty = value !== undefined && value !== '' && value !== null;

      if (!isNonEmpty) {
        newErrors[field] =
          `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
        return false;
      }

      const stringValue =
        typeof value === 'number' || typeof value === 'boolean'
          ? value.toString()
          : value || '';

      const error = validateField(
        field as keyof HoaCreateOrUpdateSchema,
        stringValue
      );
      if (error) {
        newErrors[field] = error;
        return false;
      }

      return true;
    });

    if (formData.website_url) {
      const error = validateField('website_url', formData.website_url);
      if (error) {
        newErrors.website_url = error;
      }
    }

    return {
      isValid: hasAllRequiredFields && Object.keys(newErrors).length === 0,
      validationErrors: newErrors,
    };
  }, [formData]);

  useEffect(() => {
    setErrors(validationErrors);
  }, [validationErrors]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'contact_phone') {
      const unformattedValue = stripPhoneFormatting(value);
      setFormData((prev) => ({ ...prev, [name]: unformattedValue }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  const handleStateSelect = (stateValue: string) => {
    setFormData((prev) => ({ ...prev, state: stateValue }));
    setTouched((prev) => ({ ...prev, state: true }));
  };

  const handleSave = async () => {
    if (isValid) {
      await onSave(formData);
      setFormData(getInitialHoaState(companyId));
      setErrors({});
      setTouched({});
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="relative flex flex-col h-full">
        <div className="p-2 md:p-4">
          <DialogTitle className="text-xl font-semibold">New HOA</DialogTitle>
          <button
            onClick={onClose}
            className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
            aria-label="Close dialog"
          >
            <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
          </button>

          <DialogBody>
            <Fieldset>
              <FieldGroup>
                {/* HOA Name */}
                <Field>
                  <Label htmlFor="name">HOA Name*</Label>
                  <Input
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    data-invalid={touched.name && errors.name}
                  />
                  {touched.name && errors.name && (
                    <ErrorMessage>{errors.name}</ErrorMessage>
                  )}
                </Field>

                {/* Street Address */}
                <Field>
                  <Label htmlFor="street">Street Address*</Label>
                  <Input
                    id="street"
                    name="street"
                    value={formData.street}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    data-invalid={touched.street && errors.street}
                  />
                  {touched.street && errors.street && (
                    <ErrorMessage>{errors.street}</ErrorMessage>
                  )}
                </Field>

                {/* Street Address 2 */}
                <Field>
                  <Label htmlFor="street2">Street Address 2</Label>
                  <Input
                    id="street2"
                    name="street2"
                    value={formData.street2 || ''}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  />
                </Field>

                {/* City and State */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <Field>
                    <Label htmlFor="city">City*</Label>
                    <Input
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      data-invalid={touched.city && errors.city}
                    />
                    {touched.city && errors.city && (
                      <ErrorMessage>{errors.city}</ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label htmlFor="state">State*</Label>
                    <Dropdown>
                      <DropdownButton
                        outline
                        className="w-full flex justify-between items-center"
                      >
                        <span>{formData.state || 'Select State'}</span>
                        <ChevronDownIcon className="h-5 w-5 ml-2" />
                      </DropdownButton>
                      <DropdownMenu>
                        {usStates.map((state) => (
                          <DropdownItem
                            key={state.value}
                            onClick={() => handleStateSelect(state.value)}
                          >
                            {state.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    {touched.state && errors.state && (
                      <ErrorMessage>{errors.state}</ErrorMessage>
                    )}
                  </Field>
                </div>

                {/* ZIP and Phone */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <Field>
                    <Label htmlFor="zip">ZIP Code*</Label>
                    <Input
                      id="zip"
                      name="zip"
                      value={formData.zip}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      data-invalid={touched.zip && errors.zip}
                    />
                    {touched.zip && errors.zip && (
                      <ErrorMessage>{errors.zip}</ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label htmlFor="contact_phone">Contact Phone*</Label>
                    <Input
                      id="contact_phone"
                      name="contact_phone"
                      value={formatPhoneNumber(formData.contact_phone || '')}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      data-invalid={
                        touched.contact_phone && errors.contact_phone
                      }
                    />
                    {touched.contact_phone && errors.contact_phone && (
                      <ErrorMessage>{errors.contact_phone}</ErrorMessage>
                    )}
                  </Field>
                </div>

                {/* Email */}
                <Field>
                  <Label htmlFor="contact_email">Contact Email*</Label>
                  <Input
                    id="contact_email"
                    name="contact_email"
                    type="email"
                    value={formData.contact_email || ''}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    data-invalid={touched.contact_email && errors.contact_email}
                  />
                  {touched.contact_email && errors.contact_email && (
                    <ErrorMessage>{errors.contact_email}</ErrorMessage>
                  )}
                </Field>

                {/* Website */}
                <Field>
                  <Label htmlFor="website_url">Website URL</Label>
                  <Input
                    id="website_url"
                    name="website_url"
                    type="url"
                    value={formData.website_url || ''}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    data-invalid={touched.website_url && errors.website_url}
                  />
                  {touched.website_url && errors.website_url && (
                    <ErrorMessage>{errors.website_url}</ErrorMessage>
                  )}
                </Field>

                {/* Description */}
                <Field>
                  <Label htmlFor="description">Description</Label>
                  <Textarea
                    id="description"
                    name="description"
                    value={formData.description || ''}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    rows={3}
                  />
                </Field>
              </FieldGroup>
            </Fieldset>
          </DialogBody>
        </div>

        <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
          <div className="flex flex-col space-y-3">
            <Button
              onClick={handleSave}
              color="sky"
              disabled={!isValid}
              className="w-full disabled:opacity-50"
            >
              Save
            </Button>
            <Button onClick={onClose} color="zinc" className="w-full">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddHoaDialog;
