import { useParams } from 'react-router-dom';
import AppSidebar from './AppSidebar';
import { Navbar } from './common/Catalyst/navbar';
import { SidebarLayout } from './common/Catalyst/sidebar-layout';
import { useEffect, useState } from 'react';
import { homeService } from './clients/ApiClients';
import { HomeCreateOrUpdateSchema, HomeSchema } from './clients/hoa-api-client';
import { Heading, Subheading } from './common/Catalyst/heading';
import { Button } from './common/Catalyst/button';
import { Divider } from './common/Catalyst/divider';
import ViolationList from './ViolationList';
import LoadingSpinner from './LoadingSpinner';
import ShowAlert from './ShowAlert';
import EditHomeDialog from './home/EditHomeDialog';
import HomeListComponent from './home/HomeListComponent';
import MemberListComponent from './member/MemberListComponent';

interface AlertState {
  type: 'success' | 'error';
  title: string;
  message: string;
  show: boolean;
}

const HomeDetailPage: React.FC = () => {
  const { hoaId, homeId } = useParams<{ hoaId: string; homeId: string }>();
  const [loading, setLoading] = useState(true);
  const [home, setHome] = useState<HomeSchema | null>(null);
  const [alert, setAlert] = useState<AlertState>({
    type: 'success',
    title: '',
    message: '',
    show: false,
  });
  const [isEditing, setIsEditing] = useState(false);

  const showAlert = (
    type: 'success' | 'error',
    title: string,
    message: string
  ) => {
    setAlert({
      type,
      title,
      message,
      show: true,
    });
  };

  useEffect(() => {
    const fetchHoa = async () => {
      try {
        setLoading(true);
        const response = await homeService.getHomeByHoaAndId({
          hoaId: Number(hoaId),
          homeId: Number(homeId),
        });
        setHome(response.data);
      } catch (err) {
        console.error('Error fetching home:', err);
        let errorMessage = 'An unexpected error occurred while fetching home.';
        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof err === 'string') {
          errorMessage = err;
        }

        showAlert('error', 'Failed to Load Home Details', errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchHoa();
  }, [hoaId, homeId]);

  const handleUpdateHome = async (updateData: HomeCreateOrUpdateSchema) => {
    try {
      setLoading(true);
      await homeService.updateHome({
        hoaId: Number(hoaId),
        homeId: Number(homeId!),
        homeCreateOrUpdateSchema: updateData,
      });
      // Refresh home data
      const response = await homeService.getHomeByHoaAndId({
        hoaId: Number(hoaId),
        homeId: Number(homeId),
      });
      setHome(response.data);
      showAlert('success', 'Success', 'Home updated successfully!');
    } catch (err) {
      console.error('Error updating home:', err);
      const errorMessage =
        err instanceof Error
          ? err.message
          : 'An unexpected error occurred while updating home.';
      showAlert('error', 'Failed to Update Home', errorMessage);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const fullAddress = home?.street2
    ? `${home.street}, ${home.street2}`
    : home?.street;
  const cityStateZip = `${home?.city}, ${home?.state} ${home?.zip}`;

  return (
    <SidebarLayout
      sidebar={<AppSidebar currentPage="hoa_detail" />}
      navbar={<Navbar>{/* Your navbar content */}</Navbar>}
    >
      <div className="flex justify-between items-center">
        <Heading level={2}>{fullAddress ?? 'Unable to load address'}</Heading>
        <div>
          <Button
            color="sky"
            onClick={() => setIsEditing(true)}
            className="mr-2"
            disabled={!home}
          >
            Edit Home Details
          </Button>
        </div>
      </div>
      <Subheading>{cityStateZip}</Subheading>

      <Divider className="mt-4 mb-6" />

      <ShowAlert
        alertType={alert.type}
        alertTitle={alert.title}
        alertMessage={alert.message}
        isVisible={alert.show}
        onClose={() => setAlert((prev) => ({ ...prev, show: false }))}
        timeout={5000}
        isInline={true}
      />

      <div>
        <Heading
          level={4}
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
        >
          Home Details
        </Heading>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
          {[
            { name: 'Property Type', value: home?.property_type || 'N/A' },
            { name: 'Status', value: home?.status || 'N/A' },
          ].map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-stone-100 dark:bg-gray-600 px-2 py-5 shadow sm:p-4 transition-colors duration-200"
            >
              <dt className="truncate text-sm font-medium text-gray-900 dark:text-white">
                {item.name}
              </dt>
              <dd className="mt-1 text-medium font-semibold tracking-tight text-gray-900 dark:text-white break-all">
                {item.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <Divider className="mt-4 mb-6" />
      <MemberListComponent home={home}></MemberListComponent>

      <Divider className="mt-4 mb-6" />

      <ViolationList
        hoaId={parseInt(hoaId ?? '0', 10)}
        homeId={homeId ? parseInt(homeId, 10) : undefined}
        title="Violations"
      />

      {home && (
        <EditHomeDialog
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSave={handleUpdateHome}
          home={home}
        />
      )}
    </SidebarLayout>
  );
};

export default HomeDetailPage;
