import React, { useEffect, useMemo, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CreateMemberSchema } from '../clients/auth-api-client';
import { formatPhoneNumber, stripPhoneFormatting } from '../utils/phoneUtils';
import { Button } from '../common/Catalyst/button';
import { Dialog, DialogBody, DialogTitle } from '../common/Catalyst/dialog';
import { Divider } from '../common/Catalyst/divider';
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from '../common/Catalyst/fieldset';
import { Input } from '../common/Catalyst/input';
import { UserType } from '../constants/UserTypes';

interface AddMemberDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (member: CreateMemberSchema) => Promise<void>;
  userType: string | null;
  companyId: number;
}

const getInitialMemberState = (companyId: number): CreateMemberSchema => ({
  email: '',
  phone: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  preferred_name: '',
  company_id: companyId,
  is_primary: false,
  user_type: UserType.HOA_HOME_OWNER,
  password: '',
});

interface FormErrors {
  [key: string]: string | undefined;
}

export const AddMemberDialog: React.FC<AddMemberDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  userType,
  companyId,
}) => {
  const [formData, setFormData] = useState<CreateMemberSchema>(() =>
    getInitialMemberState(companyId)
  );
  const [errors, setErrors] = useState<FormErrors>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});

  const validateField = (
    name: keyof CreateMemberSchema,
    value: string
  ): string | undefined => {
    switch (name) {
      case 'email':
        return !value
          ? 'Email is required'
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ? 'Invalid email format'
            : undefined;
      case 'phone':
        const cleanedPhone = stripPhoneFormatting(value);
        return !cleanedPhone
          ? 'Phone number is required'
          : !/^\d{10,11}$/.test(cleanedPhone)
            ? 'Phone number must be 10 or 11 digits'
            : undefined;
      case 'first_name':
        return !value ? 'First name is required' : undefined;
      case 'last_name':
        return !value ? 'Last name is required' : undefined;
      default:
        return undefined;
    }
  };

  const { isValid, validationErrors } = useMemo(() => {
    const requiredFields = ['email', 'phone', 'first_name', 'last_name'];
    const newErrors: FormErrors = {};

    const hasAllRequiredFields = requiredFields.every((field) => {
      const value = formData[field as keyof CreateMemberSchema];
      const isNonEmpty = value !== undefined && value !== '' && value !== null;

      if (!isNonEmpty) {
        newErrors[field] =
          `${field.replace('_', ' ').charAt(0).toUpperCase() + field.slice(1)} is required`;
        return false;
      }

      const stringValue = value?.toString() || '';
      const error = validateField(
        field as keyof CreateMemberSchema,
        stringValue
      );
      if (error) {
        newErrors[field] = error;
        return false;
      }

      return true;
    });

    return {
      isValid: hasAllRequiredFields && Object.keys(newErrors).length === 0,
      validationErrors: newErrors,
    };
  }, [formData]);

  useEffect(() => {
    setErrors(validationErrors);
  }, [validationErrors]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prev) => ({ ...prev, [name]: checked }));
    } else if (name === 'phone') {
      const unformattedValue = stripPhoneFormatting(value);
      setFormData((prev) => ({ ...prev, [name]: unformattedValue }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  const handleSave = async () => {
    if (isValid) {
      await onSave(formData);
      setFormData(getInitialMemberState(companyId));
      setErrors({});
      setTouched({});
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="w-full max-w-2xl mx-auto"
    >
      <div className="relative flex flex-col h-full">
        <div className="p-2 md:p-4">
          <DialogTitle className="text-xl font-semibold">
            New Member
          </DialogTitle>
          <button
            onClick={onClose}
            className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
            aria-label="Close dialog"
          >
            <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
          </button>

          <Divider className="my-4" />

          <DialogBody>
            <Fieldset>
              <FieldGroup>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <Field>
                    <Label htmlFor="first_name">First Name*</Label>
                    <Input
                      id="first_name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter first name"
                      required
                      data-invalid={touched.first_name && errors.first_name}
                    />
                    {touched.first_name && errors.first_name && (
                      <ErrorMessage>{errors.first_name}</ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label htmlFor="last_name">Last Name*</Label>
                    <Input
                      id="last_name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter last name"
                      required
                      data-invalid={touched.last_name && errors.last_name}
                    />
                    {touched.last_name && errors.last_name && (
                      <ErrorMessage>{errors.last_name}</ErrorMessage>
                    )}
                  </Field>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <Field>
                    <Label htmlFor="middle_name">Middle Name</Label>
                    <Input
                      id="middle_name"
                      name="middle_name"
                      value={formData.middle_name || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter middle name"
                    />
                  </Field>

                  <Field>
                    <Label htmlFor="preferred_name">Preferred Name</Label>
                    <Input
                      id="preferred_name"
                      name="preferred_name"
                      value={formData.preferred_name || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter preferred name"
                    />
                  </Field>
                </div>

                <Field>
                  <Label htmlFor="email">Email*</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder="Enter email address"
                    required
                    data-invalid={touched.email && errors.email}
                  />
                  {touched.email && errors.email && (
                    <ErrorMessage>{errors.email}</ErrorMessage>
                  )}
                </Field>

                <Field>
                  <Label htmlFor="phone">Phone*</Label>
                  <Input
                    id="phone"
                    name="phone"
                    type="tel"
                    value={
                      formData.phone ? formatPhoneNumber(formData.phone) : ''
                    }
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder="Enter phone number"
                    required
                    data-invalid={touched.phone && errors.phone}
                  />
                  {touched.phone && errors.phone && (
                    <ErrorMessage>{errors.phone}</ErrorMessage>
                  )}
                </Field>

                <Field>
                  <div className="flex items-center mt-2">
                    <input
                      id="is_primary"
                      type="checkbox"
                      name="is_primary"
                      checked={formData.is_primary}
                      onChange={handleInputChange}
                      className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                    />
                    <Label htmlFor="is_primary" className="ml-2">
                      Primary Contact
                    </Label>
                  </div>
                </Field>
              </FieldGroup>
            </Fieldset>
          </DialogBody>
        </div>

        <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
          <div className="flex flex-col space-y-3">
            <Button
              onClick={handleSave}
              color="sky"
              disabled={!isValid}
              className="w-full disabled:opacity-50"
            >
              Save
            </Button>
            <Button onClick={onClose} color="zinc" className="w-full">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddMemberDialog;
