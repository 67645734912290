import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Heading, Subheading } from './common/Catalyst/heading';
import { Button } from './common/Catalyst/button';
import { memberService } from './clients/ApiClients';
import { MemberSchema, UpdateMemberSchema } from './clients/auth-api-client';
import ShowAlert from './ShowAlert';
import LoadingSpinner from './LoadingSpinner';
import { formatPhoneNumber } from './utils/phoneUtils';
import { Divider } from './common/Catalyst/divider';
import { SidebarLayout } from './common/Catalyst/sidebar-layout';
import { Navbar } from './common/Catalyst/navbar';
import AppSidebar from './AppSidebar';
import ViolationList from './ViolationList';
import HomeListComponent from './home/HomeListComponent';
import EditMemberDialog from './member/EditMemberDialog';

interface AlertState {
  type: 'success' | 'error';
  title: string;
  message: string;
  show: boolean;
}

const MemberDetailPage: React.FC = () => {
  const { memberId } = useParams();
  const [member, setMember] = useState<MemberSchema | null>(null);
  const [loading, setLoading] = useState(true);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [alert, setAlert] = useState<AlertState>({
    type: 'success',
    title: '',
    message: '',
    show: false,
  });

  const showAlert = (
    type: 'success' | 'error',
    title: string,
    message: string
  ) => {
    setAlert({
      type,
      title,
      message,
      show: true,
    });
  };

  useEffect(() => {
    const fetchMember = async () => {
      if (!memberId) return;
      try {
        setLoading(true);
        const response = await memberService.getMemberById({
          memberId: parseInt(memberId),
        });
        setMember(response.data);
      } catch (err) {
        console.error('Error fetching member:', err);
        showAlert(
          'error',
          'Error',
          'Failed to fetch member details. Please try again.'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchMember();
  }, [memberId]);

  const handleUpdateMember = async (updateData: UpdateMemberSchema) => {
    try {
      setLoading(true);
      await memberService.updateMember({
        memberId: parseInt(memberId!),
        updateMemberSchema: updateData,
      });
      // Refresh member data
      const response = await memberService.getMemberById({
        memberId: parseInt(memberId!),
      });
      setMember(response.data);
      showAlert('success', 'Success', 'Member updated successfully!');
    } catch (err) {
      console.error('Error updating member:', err);
      showAlert(
        'error',
        'Failed to Update Member',
        err instanceof Error
          ? err.message
          : 'An unexpected error occurred. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!member) {
    return (
      <div className="p-4">
        <ShowAlert
          alertType="error"
          alertTitle="Error"
          alertMessage="Member not found"
          isVisible={true}
          onClose={() => {}}
          isInline={true}
        />
      </div>
    );
  }

  return (
    <SidebarLayout
      sidebar={<AppSidebar currentPage="member_detail" />}
      navbar={<Navbar>{/* Your navbar content */}</Navbar>}
    >
      <div className="flex justify-between items-center">
        <Heading level={2}>
          {member.first_name}{' '}
          {member.middle_name ? `${member.middle_name} ` : ''}
          {member.last_name}
        </Heading>
        <div>
          <Button
            color="sky"
            onClick={() => setIsEditDialogOpen(true)}
            className="mr-2"
          >
            Edit Member
          </Button>
        </div>
      </div>

      {member.preferred_name && (
        <Subheading className="mt-2">
          Preferred name: {member.preferred_name}
        </Subheading>
      )}

      <Divider className="mt-4 mb-6" />

      <ShowAlert
        alertType={alert.type}
        alertTitle={alert.title}
        alertMessage={alert.message}
        isVisible={alert.show}
        onClose={() => setAlert((prev) => ({ ...prev, show: false }))}
        timeout={5000}
        isInline={true}
      />

      <div>
        <Heading
          level={4}
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
        >
          Contact Information
        </Heading>

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {[
            { name: 'Email', value: member.email },
            {
              name: 'Phone',
              value: member.phone ? formatPhoneNumber(member.phone) : '',
            },
          ].map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-stone-100 dark:bg-gray-600 px-2 py-5 shadow sm:p-4 transition-colors duration-200"
            >
              <dt className="truncate text-sm font-medium text-gray-900 dark:text-white">
                {item.name}
              </dt>
              <dd className="mt-1 text-medium font-semibold tracking-tight text-gray-900 dark:text-white break-all">
                {item.value || 'N/A'}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <Divider className="mt-4 mb-6" />

      <div>
        <Heading
          level={4}
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
        >
          Member Details
        </Heading>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {[
            { name: 'Status', value: member.is_active ? 'Active' : 'Inactive' },
            {
              name: 'Member Since',
              value: new Date(member.date_created).toLocaleDateString(),
            },
          ].map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-stone-100 dark:bg-gray-600 px-2 py-5 shadow sm:p-4 transition-colors duration-200"
            >
              <dt className="truncate text-sm font-medium text-gray-900 dark:text-white">
                {item.name}
              </dt>
              <dd className="mt-1 text-medium font-semibold tracking-tight text-gray-900 dark:text-white break-all">
                {item.value || 'N/A'}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <Divider className="mt-4 mb-6" />
      <HomeListComponent member={member}></HomeListComponent>

      <Divider className="mt-4 mb-6" />
      <ViolationList
        memberId={member.id}
        title={`${member.first_name}'s Violations`}
      ></ViolationList>

      <EditMemberDialog
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        onSave={handleUpdateMember}
        member={member}
      />
    </SidebarLayout>
  );
};

export default MemberDetailPage;
