import React, { useState, useRef, useEffect } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface BaseOption {
  id: string | number;
  name: string;
}

interface SearchableDropdownProps<T extends BaseOption> {
  options: T[];
  onSelect?: (option: T | null) => void;
  placeholder?: string;
  renderOption?: (option: T) => React.ReactNode;
  filterOption?: (option: T, filter: string) => boolean;
  getOptionLabel?: (option: T) => string;
  className?: string;
}

const defaultFilterOption = <T extends BaseOption>(
  option: T,
  filter: string
): boolean => {
  return option.name.toLowerCase().includes(filter.toLowerCase());
};

const defaultGetOptionLabel = <T extends BaseOption>(option: T): string => {
  return option.name;
};

const defaultRenderOption = <T extends BaseOption>(
  option: T
): React.ReactNode => (
  <div className="flex items-center">
    <span>{option.name}</span>
  </div>
);

const SearchableDropdown = <T extends BaseOption>({
  options,
  onSelect,
  placeholder = 'Search...',
  renderOption = defaultRenderOption,
  filterOption = defaultFilterOption,
  getOptionLabel = defaultGetOptionLabel,
  className = 'w-full',
}: SearchableDropdownProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [selected, setSelected] = useState<T | null>(null);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const containerRef = useRef<HTMLDivElement>(null);

  const filteredOptions = options.filter((option) =>
    filterOption(option, filter)
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelected(null);
    setFilter('');
    onSelect?.(null);
    setIsOpen(false);
  };

  const handleSelect = (option: T) => {
    setSelected(option);
    setFilter(getOptionLabel(option));
    onSelect?.(option);
    setIsOpen(false);
  };

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleInputClick = () => {
    setIsOpen(true);
  };

  const handleOptionMouseEnter = (index: number) => {
    setHighlightedIndex(index);
  };

  return (
    <div ref={containerRef} className={clsx('relative', className)}>
      <div className="w-full">
        <div className="relative w-full">
          <input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onClick={handleInputClick}
            className={clsx(
              'w-full px-3 py-2 text-left rounded-lg',
              'text-base/6 sm:text-sm/6',
              'bg-stone-100 dark:bg-gray-800',
              'border border-stone-300 dark:border-gray-600',
              'text-gray-900 dark:text-white',
              'placeholder-gray-500 dark:placeholder-gray-400',
              'focus:outline-none focus:ring-2 focus:ring-sky-500'
            )}
            placeholder={placeholder}
          />
          <div className="absolute inset-y-0 right-0 flex items-center">
            {selected && (
              <button
                onClick={handleClear}
                className="p-1 hover:text-sky-500"
                type="button"
              >
                <XMarkIcon className="h-5 w-5 text-stone-300 dark:text-gray-600 hover:text-sky-500" />
              </button>
            )}
            <button
              onClick={toggleDropdown}
              className="flex items-center px-2"
              type="button"
            >
              {isOpen ? (
                <ChevronUpIcon className="h-5 w-5 text-stone-300 dark:text-gray-600" />
              ) : (
                <ChevronDownIcon className="h-5 w-5 text-stone-300 dark:text-gray-600" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          className={clsx(
            'absolute z-50 mt-2 w-full overflow-y-auto rounded-lg',
            'bg-white dark:bg-zinc-800',
            'shadow-lg ring-1 ring-sky-800/10 dark:ring-sky-700/10',
            'max-h-60'
          )}
        >
          <div className="py-1">
            {filteredOptions.map((option, index) => (
              <button
                key={option.id}
                onClick={() => handleSelect(option)}
                onMouseEnter={() => handleOptionMouseEnter(index)}
                className={clsx(
                  'group w-full text-left px-3 py-2',
                  'text-base/6 sm:text-sm/6',
                  'cursor-default focus:outline-none',
                  'hover:bg-sky-800 hover:text-white dark:hover:bg-sky-700',
                  highlightedIndex === index &&
                    'bg-sky-800 dark:bg-sky-700 text-white',
                  highlightedIndex !== index && 'text-gray-900 dark:text-white',
                  selected?.id === option.id &&
                    highlightedIndex !== index &&
                    'bg-sky-100 dark:bg-sky-900/50'
                )}
                type="button"
              >
                {renderOption(option)}
              </button>
            ))}
            {filteredOptions.length === 0 && (
              <div className="px-3 py-2 text-sm text-gray-500 dark:text-gray-400">
                No results found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
